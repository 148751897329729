import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AsyncAutocomplete,
  TextField,
  Checkbox,
  Row,
  Col,
  Form,
  SaveButton,
  CancelButton,
  Box,
  TextArea,
} from "../../common";

import useCredentialsApi from "../../../api/credentialsApi";
import { ICredentialTemplate, ILink } from "../../../types";
import {
  ICreateCredentialParamDefinition,
  IParameterDefinition,
} from "../../../types/ParameterDefinitions";
import useSubmitting from "../../../hooks/useSubmitting";

interface ICredentialParamDefFields {
  label: string;
  prefix: string;
  required: boolean;
  credentialTemplateId: string;
  description: string | null;
}

/**
 * Re-usable component for updating credential parameter definition form fields.
 */
export default function CredentialParamDefFields({
  paramDef,
  setParamDef,
  credentialTemplate,
}: {
  paramDef: ICredentialParamDefFields;
  setParamDef: (p: ICredentialParamDefFields) => void;
  credentialTemplate?: ICredentialTemplate;
}) {
  const [selectedTemplate, setSelectedTemplate] = useState<ILink | null>(
    credentialTemplate
      ? { id: credentialTemplate.id, name: credentialTemplate.name }
      : null
  );
  const { fetchCredentialTemplates } = useCredentialsApi();
  const { t } = useTranslation("App Page");

  return (
    <>
      <Row>
        <TextField
          id="label-input"
          label={t("Label")}
          fullWidth
          helperText={t(
            "Add a helpful label for this parameter to help your team know what it's for."
          )}
          value={paramDef.label}
          required
          onChange={(label) => setParamDef({ ...paramDef, label })}
        />
      </Row>

      <Row>
        <TextArea
          rows={4}
          fullWidth
          id="description-input"
          label={t("Description")}
          value={paramDef.description}
          onChange={(description) => {
            setParamDef({
              ...paramDef,
              description,
            });
          }}
          helperText={t(
            "Add a description for this parameter to help your team know how to use it."
          )}
        />
      </Row>

      <Row mt={3} mb={3}>
        <AsyncAutocomplete
          style={{ width: "100%" }}
          id="credential-template-selectbox"
          label={t("Credential Type")}
          onChange={(_, option) => {
            setParamDef({
              ...paramDef,
              credentialTemplateId: option.id,
            });
            setSelectedTemplate(option);
          }}
          fetch={async () => {
            const templates = await fetchCredentialTemplates();
            const templateListItems = templates.map((template) => {
              return { label: template.name, data: template };
            });
            return templateListItems;
          }}
          value={{
            label: selectedTemplate?.name || "",
            data: selectedTemplate,
          }}
        />
      </Row>

      <Row>
        <TextField
          id="prefix-input"
          label={t("Prefix")}
          fullWidth
          helperText={
            "Set an optional prefix that will be prepended to each of the key names of the selected credential."
          }
          value={paramDef.prefix}
          onChange={(prefix) => setParamDef({ ...paramDef, prefix })}
        />
      </Row>

      <Row>
        <Checkbox
          checked={paramDef.required}
          label={t("common:required")}
          onChange={(required) => setParamDef({ ...paramDef, required })}
        />
      </Row>
    </>
  );
}

/**
 * Component for adding new credential parameter definitions.
 */
export function AddCredentialParamDef({
  onSave,
  onClose,
}: {
  onSave: (paramDef: ICreateCredentialParamDefinition) => Promise<void>;
  onClose: () => void;
}) {
  const [formState, setFormState] = useState<ICredentialParamDefFields>({
    label: "",
    prefix: "",
    required: false,
    credentialTemplateId: "",
    description: null,
  });
  const { t } = useTranslation("App Page");

  const [addParameterDefinition, isSubmitting] = useSubmitting(async () => {
    await onSave({ ...formState, type: "credential" });
  });

  return (
    <Form onSubmit={addParameterDefinition}>
      <Row>
        <Col data-i18n-key="credentialParameterDefinitionHelperText">
          {t("credentialParameterDefinitionHelperText")}
        </Col>
      </Row>

      <CredentialParamDefFields
        paramDef={formState}
        setParamDef={setFormState}
      />

      <Row direction="row-reverse">
        <CancelButton onClick={onClose} />

        <SaveButton
          isLoading={isSubmitting}
          onClick={addParameterDefinition}
          label={t("common:add")}
          disabled={!formState.label || !formState.credentialTemplateId}
        />
      </Row>
    </Form>
  );
}

/**
 * Component for editing credential parameter definitions.
 */
export function EditCredentialParamDef({
  paramDef,
  onUpdate,
  onCancel,
}: {
  paramDef: IParameterDefinition;
  onUpdate: (p: IParameterDefinition) => Promise<void>;
  onCancel: () => void;
}) {
  //   blow up immediately if we don't have the correct param def type
  if (paramDef.type !== "credential")
    throw new Error("Incorrect param def type for component");

  const [formState, setFormState] = useState({
    label: paramDef.label,
    prefix: paramDef.prefix || "",
    required: paramDef.required,
    credentialTemplateId: paramDef.credentialTemplateId,
    description: paramDef.description,
  });

  const { t } = useTranslation("App Page");

  const [submitUpdate, isSubmittingUpdate] = useSubmitting(async () => {
    await onUpdate({ ...paramDef, ...formState });
  });

  return (
    <Box ml={2} mr={2} mb={4}>
      <Form onSubmit={submitUpdate}>
        <CredentialParamDefFields
          paramDef={formState}
          credentialTemplate={paramDef.credentialTemplate}
          setParamDef={setFormState}
        />

        <Row direction="row-reverse">
          <CancelButton onClick={onCancel} />

          <SaveButton
            isLoading={isSubmittingUpdate}
            submitForm
            onClick={submitUpdate}
            label={t("common:save")}
          />
        </Row>
      </Form>
    </Box>
  );
}
