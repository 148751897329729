import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useDashboardApi from "../../api/dashboardApi";
import useAlert from "../../hooks/useAlert";
import { useInterval } from "../../hooks/useInterval";
import { formatDateTime } from "../../time";
import { IDashboardList, IResource, ITag } from "../../types";
import { Chip, Link, Table } from "../common";
import LastRunStatus from "./LastRunStatus";

export default function DashboardList({ tags }: { tags: ITag[] }) {
  const [tagged, setTagged] = useState<IResource[] | undefined>(undefined);
  const { fetchTagged } = useDashboardApi();
  const { t } = useTranslation("Dashboard");
  const { showError } = useAlert();

  useInterval<IDashboardList>({
    fetch: () => fetchTagged({ tag_filters: tags.map((tag) => tag.id) }),
    handleResponse: (res) => {
      setTagged(res.resources);
    },
    handleError: () => showError(t("Error updating dashboard!")),
    dependencies: [tags],
  });

  return tags.length ? (
    <Table>
      <thead>
        <tr>
          <th>{t("common:name")}</th>
          <th>{t("common:type")}</th>
          <th>{t("common:tags")}</th>
          <th>{t("Last Run")}</th>
          <th>{t("Next Run")}</th>
        </tr>
      </thead>
      <tbody>
        {tagged?.map((resource) => (
          <tr key={resource.id}>
            <td>
              <Link
                color="link"
                to={`/app/${resource.resourceType}s/${resource.id}`}
              >
                {resource.name}
              </Link>
            </td>
            <td>{resource.resourceType}</td>
            <td>
              {resource.tags.map((tag) => (
                <Chip key={tag.id} label={tag.name} />
              ))}
            </td>
            <td>
              <LastRunStatus resource={resource} />
            </td>
            <td>
              {resource.nextRuntime
                ? formatDateTime(resource.nextRuntime)
                : t("common:unavailable")}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <div>{t("Please select a tag.")}</div>
  );
}
