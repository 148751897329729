import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateReportProps } from "../../api/reportsApi";
import { ILink, IReport, ISlackConfig } from "../../types";
import { Box, CancelButton, Form, SaveButton, TextField } from "../common";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import ReportSchedulerField from "../scheduler/ReportSchedulerField";
import ReportSlackConfig from "./ReportSlackConfig";
import ReportsTagForm from "./ReportsTagForm";

export default function NewReportModal({
  report,
  show,
  onHide,
  onSave,
}: {
  report: IReport;
  show: boolean;
  onHide: () => void;
  onSave: (v: UpdateReportProps) => void;
}) {
  const [name, setName] = useState(report.name);
  const [cronSchedule, setCronSchedule] = useState(report.cronSchedule);
  const [tags, setTags] = useState<ILink[]>(report.tags);
  const [removedTags, setRemovedTags] = useState<string[]>([]);
  const [addedTags, setAddedTags] = useState<string[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<ISlackConfig[]>(
    report.notifications
  );
  const [removedNotifications, setRemovedNotifications] = useState<string[]>(
    []
  );
  const [addedNotifications, setAddedNotifications] = useState<ISlackConfig[]>(
    []
  );
  const { t } = useTranslation("Reports Page");

  useEffect(() => {
    if (!show) {
      setAddedTags([]);
      setRemovedTags([]);
      setAddedNotifications([]);
      setRemovedNotifications([]);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader onClose={onHide}>
        <ModalTitle>{t("Edit report")}</ModalTitle>
      </ModalHeader>
      <Form
        onSubmit={() => {
          onSave({
            name,
            cronSchedule,
            removedTags,
            addedTags,
            removedNotifications,
            addedNotifications,
          });
        }}
      >
        <ModalBody>
          <Box mb={3}>
            <TextField
              id="credential-name-input"
              placeholder={t("Add a helpful name for your report")}
              label={t("common:name")}
              value={name}
              required
              style={{ width: "65%" }}
              onChange={setName}
            />
          </Box>
          <ReportSchedulerField
            cronLine={cronSchedule}
            onChange={setCronSchedule}
          />
          <ReportSlackConfig
            selectedChannels={selectedChannels}
            setSelectedChannels={setSelectedChannels}
            onSave={setAddedNotifications}
            onDelete={(actionId) =>
              setRemovedNotifications([...removedNotifications, actionId])
            }
          />
          <ReportsTagForm
            tags={tags}
            setTags={setTags}
            onSave={(tagIds) => setAddedTags(tagIds)}
            onDelete={(tagId) => setRemovedTags([...removedTags, tagId])}
          />
        </ModalBody>

        <ModalFooter>
          <CancelButton onClick={onHide} />
          <SaveButton disabled={!name || !cronSchedule} submitForm />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
