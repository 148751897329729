import useRequest from "../hooks/useRequest";
import { IApp } from "../types";
import {
  IParameterDefinition,
  TCreateParameterDefinition,
} from "../types/ParameterDefinitions";

export default function useAppsApi() {
  const { get, post, remove } = useRequest();

  return {
    createApp: async (app: IApp) => {
      const { data } = await post<IApp>("/apps", app);
      return data;
    },

    createAppFromJob: async (jobId: string) => {
      const { data } = await post<IApp>(`/jobs/${jobId}/app`);
      return data;
    },

    createJobFromApp: async (app: IApp) => {
      const { data } = await post<IApp>(`/apps/${app.id}/job`);
      return data;
    },

    fetchApps: async () => {
      const { data } = await get<IApp[]>("/apps");
      return data;
    },

    fetchApp: async (appId: string) => {
      const { data } = await get<IApp>(`/apps/${appId}`);
      return data;
    },

    updateApp: async (appId: string, { name, description }: IApp) => {
      const { data } = await post<IApp>(`/apps/${appId}`, {
        name,
        description,
      });
      return data;
    },

    addParameterDefinition: async (
      appId: string,
      parameterDefinition: TCreateParameterDefinition
    ) => {
      const { data } = await post<IParameterDefinition>(
        `/apps/${appId}/parameter_definitions`,
        parameterDefinition
      );
      return data;
    },

    removeParameterDefinition: async (
      appId: string,
      parameterDefinitionId: string
    ) => {
      return await remove(
        `/apps/${appId}/parameter_definitions/${parameterDefinitionId}`
      );
    },

    updateParameterDefinition: async (
      appId: string,
      parameterDefinition: IParameterDefinition
    ) => {
      const { data } = await post<IParameterDefinition>(
        `/apps/${appId}/parameter_definitions/${parameterDefinition.id}`,
        parameterDefinition
      );
      return data;
    },
  };
}
