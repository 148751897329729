import React, { useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { ClearButton, List, ListItem, EditButton, Tooltip } from "../common";
import { IEmailNotification, ISlackNotification } from "../../types";
import { statusCodeMap } from "../../constants";
import NotificationModalForm from "./NotificationModalForm";
import useAlert from "../../hooks/useAlert";
import { AddButton } from "../common/Button";
import styled from "styled-components";
import { UpsertNotificationProps } from "../../api/notificationsApi";

const StyledListItem = styled(ListItem)`
  &.MuiListItem-secondaryAction {
    padding-right: 96px;
  }
`;

function getNotificationLabel(
  reaction: ISlackNotification | IEmailNotification,
  t: TFunction
) {
  const { criteria, notificationType } = reaction;
  const { status } = criteria;
  const statusLabels = status.map((stat) => t(statusCodeMap[stat].message));
  return (
    <>
      {t("On")} <b>{statusLabels.join(" / ")}</b>, {t(notificationType)}{" "}
      <b>
        {reaction.notificationType === "email"
          ? reaction.config.recipients.join(", ")
          : reaction.config.channelName}
      </b>
    </>
  );
}

function NotificationListItem({
  notification,
  onRemove,
  onEdit,
}: {
  notification: ISlackNotification | IEmailNotification;
  onRemove: (id: string) => Promise<void>;
  onEdit: (notification: ISlackNotification | IEmailNotification) => void;
}) {
  const { t } = useTranslation("Notification Form");
  const [isRemoving, setIsRemoving] = useState(false);
  return (
    <StyledListItem
      label={getNotificationLabel(notification, t)}
      actions={
        <>
          <Tooltip title="Edit Notification">
            <EditButton
              disabled={isRemoving}
              onClick={() => onEdit(notification)}
            />
          </Tooltip>
          <Tooltip title="Remove Notification">
            <ClearButton
              disabled={isRemoving}
              onClick={async () => {
                setIsRemoving(true);
                await onRemove(notification.id);
              }}
            />
          </Tooltip>
        </>
      }
    />
  );
}

export default function NotificationForm({
  notifications,
  onSave,
  onRemove,
}: {
  notifications: IEmailNotification[] | ISlackNotification[];
  onSave: (v: UpsertNotificationProps) => Promise<null>;
  onRemove: (id: string) => Promise<null>;
}) {
  const [currentNotification, setCurrentNotification] = useState<
    ISlackNotification | IEmailNotification | null | undefined
  >(undefined);

  const { t } = useTranslation("Notification Form");
  const { showError, showSuccess, showWarning } = useAlert();

  const hideModal = () => setCurrentNotification(undefined);
  const add = () => setCurrentNotification(null);
  const edit = (notification: ISlackNotification | IEmailNotification) =>
    setCurrentNotification(notification);

  const handleRemove = async (notificationId: string) => {
    try {
      await onRemove(notificationId);
      showWarning(t("Removed notification"));
    } catch {
      showError(t("Error removing notification"));
    }
  };

  const handleSave = async (v: UpsertNotificationProps) => {
    try {
      await onSave(v);
      setCurrentNotification(undefined);
      showSuccess(t("Saved notification"));
    } catch {
      showError(t("Error saving notification"));
    }
  };

  return (
    <>
      <h3>
        {t("Notifications")}
        <Tooltip title="Add Notification">
          <AddButton onClick={add} />
        </Tooltip>
      </h3>
      <List>
        {(notifications as Array<ISlackNotification | IEmailNotification>).map(
          (notification: ISlackNotification | IEmailNotification) => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
              onEdit={edit}
              onRemove={handleRemove}
            />
          )
        )}
      </List>
      {currentNotification !== undefined ? (
        <NotificationModalForm
          notification={currentNotification}
          show
          onHide={hideModal}
          onSave={handleSave}
        />
      ) : null}
    </>
  );
}
