import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../common/Modal";
import { Button, Row, Form, Checkbox, Box, Col, TextField } from "../common";

import useAlert from "../../hooks/useAlert";
import useApi from "../../api/userApi";
import { IUser } from "../../types";
import UserTable from "./UserTable";

interface IProps {
  fetchUsers: () => void;
  users: IUser[];
}

export default function UserTableContainer({ fetchUsers, users }: IProps) {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { createUser } = useApi();
  const { showError } = useAlert();
  const { t } = useTranslation("User Management");

  const handleShowModal = () => {
    setCurrentUser(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentUser(null);
    setShowModal(false);
  };

  const updateField = (fieldName: keyof IUser, value: string | boolean) => {
    setCurrentUser({
      ...(currentUser as IUser),
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (currentUser === null) {
      return;
    }
    createUser(currentUser)
      .then(fetchUsers)
      .catch((error) => showError(error.message))
      .finally(handleCloseModal);
  };

  return (
    <Box>
      <Row centerVertically>
        <Col>
          <h1>{t("Users")}</h1>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button size="lg" onClick={handleShowModal}>
            {t("Add User")}
          </Button>
        </Col>
      </Row>

      <UserTable users={users} />

      <Modal show={showModal} onHide={handleCloseModal} centered size="sm">
        <ModalHeader onClose={handleCloseModal}>
          <ModalTitle>{t("Add a new user")}</ModalTitle>
        </ModalHeader>
        <Form onSubmit={handleSave}>
          <ModalBody>
            <Row>
              <Col>
                <TextField
                  id="formBasicName"
                  label={t("common:name")}
                  placeholder={t("Enter Name")}
                  required
                  fullWidth
                  onChange={(value) => updateField("name", value)}
                />
              </Col>
              <Col>
                <TextField
                  id="formBasicEmail"
                  label={t("common:emailAddress")}
                  placeholder={t("common:emailAddress")}
                  required
                  fullWidth
                  onChange={(value) => updateField("email", value)}
                />
              </Col>
            </Row>
            <Checkbox
              label={t("Admin?")}
              checked={currentUser ? currentUser.isAdmin : false}
              onChange={(checked) => updateField("isAdmin", checked)}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={handleCloseModal}>
              {t("common:cancel")}
            </Button>
            <Button type="submit">{t("common:save")}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Box>
  );
}
