import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useAlert from "../../hooks/useAlert";
import useApi from "../../api/userApi";
import { IUser } from "../../types";

import LoadingScreen from "../common/LoadingScreen";
import UserTableContainer from "./UserTableContainer";

const Page = styled.div`
  font-size: 1rem;
`;

export default function UserManagementPageContainer() {
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const { fetchUsers } = useApi();
  const { showError } = useAlert();
  const { t } = useTranslation("User Management");

  const fetch = () => {
    fetchUsers()
      .then((response) => setUsers(response))
      .catch(() => showError(t("Error fetching users")));
  };

  useEffect(fetch, []);

  if (users === undefined) {
    return <LoadingScreen />;
  }

  return (
    <Page>
      <UserTableContainer fetchUsers={fetch} users={users} />
    </Page>
  );
}
