import useRequest from "../hooks/useRequest";

import {
  INotification,
  INotificationCriteria,
  INotificationConfig,
  ISlackConfig,
} from "../types";

export interface UpsertNotificationProps {
  id?: string;
  jobId?: string;
  workflowId?: string;
  criteria: INotificationCriteria;
  config: INotificationConfig | ISlackConfig;
  notificationType: string;
}

export default function useNotificationsApi() {
  const { post, remove } = useRequest();
  return {
    createNotification: async (newReaction: UpsertNotificationProps) => {
      const { data } = await post<INotification>("/notifications", newReaction);
      return data;
    },

    updateNotification: async (update: UpsertNotificationProps) => {
      const { data } = await post<INotification>(
        `/notifications/${update.id}`,
        update
      );
      return data;
    },

    removeNotification: (reactionId: string) => {
      return remove(`/notifications/${reactionId}`);
    },
  };
}
