import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AddParameterForm from "../parameters/AddParameterForm";
import {
  IParameter,
  IPasswordParameter,
  ITextParameter,
  TUpsertCredParameter,
} from "../../types";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import { Box, Button, SaveButton } from "../common";
import {
  EditPasswordParam,
  EditTextParam,
} from "../parameters/EditableParameter";

export default function NewCredentialParameterForm({
  navigate,
  onFinish,
  onHide,
}: {
  navigate: (step: string) => void;
  onFinish: (vars: TUpsertCredParameter[]) => void;
  onHide: () => void;
}) {
  const [parameterMap, setParameterMap] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("Credentials Page");

  const onUpdate = (parameter: IParameter) => {
    parameterMap.set(parameter.id, parameter);
    setParameterMap(new Map(parameterMap));
  };

  const onRemove = (varId: string) => {
    parameterMap.delete(varId);
    setParameterMap(new Map(parameterMap));
  };

  return (
    <>
      <ModalHeader onClose={onHide}>
        <ModalTitle>{t("Set parameters")}</ModalTitle>
        <Box mb={3}>
          {t("Add your own custom parameters, API keys, passwords, etc.")}
        </Box>
      </ModalHeader>
      <ModalBody>
        <Box pl={3} pr={3}>
          {[...parameterMap.values()].map((parameter) => {
            switch (parameter.type) {
              case "text":
                return (
                  <EditTextParam
                    key={parameter.id}
                    parameter={parameter}
                    canEditKey={true}
                    onUpdate={onUpdate}
                    postUpdate={false}
                    onRemove={onRemove}
                  />
                );
              case "password":
                return (
                  <EditPasswordParam
                    key={parameter.id}
                    parameter={parameter}
                    canEditKey={true}
                    onUpdate={onUpdate}
                    postUpdate={false}
                    onRemove={onRemove}
                  />
                );
              default:
                return null;
            }
          })}

          <AddParameterForm
            isAddingVar
            submitForm
            onSave={(newVar: ITextParameter | IPasswordParameter) => {
              onUpdate(newVar);
            }}
          />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline" onClick={() => navigate("select-type")}>
          {t("Use a template instead")}
        </Button>
        <SaveButton
          label={t("Create Credential")}
          isLoading={isSubmitting}
          disabled={!parameterMap.size}
          onClick={async () => {
            setIsSubmitting(true);
            await onFinish([...parameterMap.values()]);
            setIsSubmitting(false);
          }}
        />
      </ModalFooter>
    </>
  );
}
