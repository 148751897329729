import styled from "styled-components";

interface TableProps {
  dense?: boolean;
}

const Table = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  td,
  th {
    padding: ${(props: TableProps) =>
      props.dense ? "0.35rem 0.5em" : "0.75rem"};
    ${(props) => props.dense && `white-space: nowrap;`}
    border-top: 1px solid #dee2e6;
    text-align: inherit;
  }
`;

export default Table;

export const TableIconCell = styled.td`
  width: 8em;
`;
