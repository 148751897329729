import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAppsApi from "../../api/appsApi";
import useAlert from "../../hooks/useAlert";
import useSubmitting from "../../hooks/useSubmitting";
import { IApp } from "../../types";
import {
  Box,
  CancelButton,
  EditButton,
  Form,
  SaveButton,
  Span,
  TextArea,
  Tooltip,
} from "../common";

export default function ({
  app,
  refresh,
  canEdit,
}: {
  app: IApp;
  refresh: () => Promise<void>;
  canEdit: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(app.description);
  const { updateApp } = useAppsApi();
  const { showError, showSuccess } = useAlert();

  const { t } = useTranslation("App Page");

  useEffect(() => {
    setUpdatedDescription(app.description);
  }, [app.description]);

  const [saveDescription, isSubmittingUpdate] = useSubmitting(async () => {
    try {
      await updateApp(app.id, { ...app, description: updatedDescription });
      await refresh();
      showSuccess(t("App updated"));
    } catch {
      showError(t("Error updating app"));
    } finally {
      setIsEditing(false);
    }
  });

  return (
    <>
      <h3>
        {t("Description")}
        {canEdit && (
          <Tooltip title="Edit Description">
            <EditButton onClick={() => setIsEditing(true)} />
          </Tooltip>
        )}
      </h3>
      {canEdit && isEditing ? (
        <Form onSubmit={saveDescription}>
          <TextArea
            rows={4}
            fullWidth
            id="app-description-input"
            label={t("App Description")}
            value={updatedDescription}
            onChange={setUpdatedDescription}
          />
          <Box>
            <SaveButton isLoading={isSubmittingUpdate} submitForm />
            <CancelButton onClick={() => setIsEditing(false)} />
          </Box>
        </Form>
      ) : (
        <Box style={{ maxWidth: "800px", whiteSpace: "pre-wrap" }}>
          {app.description || (
            <Span color="outline" fontStyle="italic">
              {t("None provided")}
            </Span>
          )}
        </Box>
      )}
    </>
  );
}
