import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IOptionParameterDefinition,
  IParameterDefinition,
} from "../../types/ParameterDefinitions";
import {
  TUpsertParameter,
  IParameter,
  IUpsertTextParam,
  IUpsertPasswordParam,
  IUpsertCredParam,
  IUpsertDbQueryParam,
  IUpsertJSONParam,
  IUpsertOptionParam,
} from "../../types/Parameters";

import {
  Box,
  CancelButton,
  EditButton,
  Form,
  SaveButton,
  Tooltip,
} from "../common";
import AppJobCredParamEditor from "./AppJobCredParamEditor";

import useJobsApi from "../../api/jobApi";
import useAlert from "../../hooks/useAlert";
import useJob from "../../hooks/useJob";
import useSubmitting from "../../hooks/useSubmitting";
import AppJobParameterRow, {
  AppJobTextParamEditor,
} from "./AppJobParameterRow";
import AppJobDbQueryParamEditor from "./AppJobDbQueryParamEditor";
import AppJobJSONParamEditor from "./AppJobJSONParamEditor";
import AppJobOptionParamEditor from "./AppJobOptionParamEditor";

/* Utility for generating empty parameter form objects */
function generateParameter(
  paramDef: IParameterDefinition
): TUpsertParameter | undefined {
  const paramBase = {
    id: "",
    parameterDefinitionId: paramDef.id,
    isValid: false,
  };
  switch (paramDef.type) {
    case "text":
      return {
        ...paramBase,
        type: "text",
        name: paramDef.defaultKeyName,
        value: paramDef.defaultValue || "",
        isValid: Boolean(paramDef.defaultValue?.length),
      };
    case "password":
      return {
        ...paramBase,
        type: "password",
        name: paramDef.defaultKeyName,
        value: "",
      };
    case "credential":
      return {
        ...paramBase,
        type: "credential",
        credentialId: "",
        credential: null,
        prefix: paramDef.prefix,
      };
    case "db_query":
      return {
        ...paramBase,
        type: "db_query",
        queryString: paramDef.defaultValue || "",
        isValid: Boolean(paramDef.defaultValue?.length),
      };
    case "json":
      return {
        ...paramBase,
        type: "json",
        name: paramDef.defaultKeyName,
        value: paramDef.defaultValue || "",
        isValid: Boolean(paramDef.defaultValue?.length),
      };
    case "option":
      return {
        ...paramBase,
        type: "option",
        name: paramDef.defaultKeyName,
        value: "",
      };
    default:
      return undefined;
  }
}

/* Utility for converting parameter data from the server to form objects */
function convertParameter(param: IParameter): TUpsertParameter | undefined {
  const paramBase = {
    id: param.id,
    parameterDefinitionId: param.parameterDefinitionId,
  };
  switch (param.type) {
    case "text":
      return {
        ...paramBase,
        type: "text",
        name: param.name,
        value: param.value,
        isValid: Boolean(param.value.length),
      };
    case "password":
      return {
        ...paramBase,
        type: "password",
        name: param.name,
        value: "",
        isValid: Boolean(param.secretId),
      };
    case "credential":
      return {
        ...paramBase,
        type: "credential",
        credentialId: param.credentialId,
        credential: param.credential,
        prefix: param.prefix,
        isValid: Boolean(param.credentialId),
      };
    case "db_query":
      return {
        ...paramBase,
        type: "db_query",
        queryString: param.queryString,
        isValid: Boolean(param.queryString.length),
      };
    case "json":
      return {
        ...paramBase,
        type: "json",
        name: param.name,
        value: param.value,
        isValid: Boolean(param.value.length),
      };
    case "option":
      return {
        ...paramBase,
        type: "option",
        name: param.name,
        value: param.value,
        isValid: Boolean(param.value.length),
      };
    default:
      return undefined;
  }
}

/* Creates a map of parameters by parameter definition */
export function mapParamsByParamDef(
  params: IParameter[],
  paramDefs: IParameterDefinition[]
) {
  return new Map(
    paramDefs.map((paramDef) => {
      const param = params.find(
        (param) => param.parameterDefinitionId === paramDef.id
      );
      return [
        paramDef.id,
        param ? convertParameter(param) : generateParameter(paramDef),
      ];
    })
  );
}

/* Creates a map of empty parameters by parameter definition */
function createNewParamMap(paramDefs: IParameterDefinition[]) {
  return new Map(
    paramDefs.map((paramDef) => [paramDef.id, generateParameter(paramDef)])
  );
}

/* Validates parameters against parameter definition requirements*/
export function isValidAppJob(
  parameterDefinitions: IParameterDefinition[],
  paramMap: Map<string, TUpsertParameter | undefined>
) {
  const requiredParamDefs = parameterDefinitions
    .filter((pd) => pd.required)
    .map((pd) => pd.id);
  const requiredParams = [...paramMap].filter(
    ([paramDefId, param]) =>
      requiredParamDefs.includes(paramDefId) && param?.isValid
  );
  const requirementsMet = requiredParamDefs.length === requiredParams.length;
  return requirementsMet;
}

/**
 * Form for viewing/editing parameters for a job that's built on an app.
 */
export default function AppJobParameterForm() {
  const { job } = useJob();
  if (!job || !job.app) throw new Error();

  /* COMPONENT STATE */
  const [updatedParameters, setUpdatedParameters] = useState<
    Map<string, TUpsertParameter | undefined>
  >(
    job.parameters.length > 0
      ? mapParamsByParamDef(job.parameters, job.app.parameterDefinitions)
      : createNewParamMap(job.app.parameterDefinitions)
  );
  const [isEditing, setIsEditing] = useState(!job.parameters.length);
  const requirementsMet = isValidAppJob(
    job.app.parameterDefinitions,
    updatedParameters
  );

  /* HOOKS */
  const { showError, showSuccess } = useAlert();
  const { t } = useTranslation("Job Page");

  /* API */
  const { setJobAppParameters } = useJobsApi();
  const [saveParameters, isSavingParameters] = useSubmitting(async () => {
    const paramDefs = job.app?.parameterDefinitions;

    // filter out any undefined parameters from our state
    const parameters = [...updatedParameters.values()].filter(
      (param) => param
    ) as TUpsertParameter[];

    if (paramDefs && parameters) {
      try {
        const newParams = await setJobAppParameters(job.id, parameters);
        showSuccess(t("Saved parameters"));
        setUpdatedParameters(mapParamsByParamDef(newParams, paramDefs));
        setIsEditing(false);
      } catch {
        showError(t("Error saving parameters"));
      }
    }
  });

  /* RENDER */
  return (
    <Box>
      <h3>
        {t("Parameters")}
        <Tooltip title={t("Set Job Parameters") || ""}>
          <EditButton onClick={() => setIsEditing(true)} />
        </Tooltip>
      </h3>
      {isEditing ? (
        <Form onSubmit={saveParameters}>
          {job.app.parameterDefinitions.map((paramDef) => {
            const definedParam = updatedParameters.get(paramDef.id);
            if (definedParam) {
              switch (paramDef.type) {
                case "text":
                  return definedParam.type === "text" ? (
                    <AppJobTextParamEditor
                      key={paramDef.id}
                      parameter={definedParam as IUpsertTextParam}
                      paramDef={paramDef}
                      onChange={(value) => {
                        const update = new Map(
                          updatedParameters.set(paramDef.id, {
                            ...definedParam,
                            value,
                            isValid: Boolean(value.length),
                          } as IUpsertTextParam)
                        );
                        setUpdatedParameters(update);
                      }}
                    />
                  ) : null;
                case "password":
                  return definedParam.type === "password" ? (
                    <AppJobTextParamEditor
                      key={paramDef.id}
                      parameter={definedParam as IUpsertPasswordParam}
                      paramDef={paramDef}
                      onChange={(value) => {
                        const update = new Map(
                          updatedParameters.set(paramDef.id, {
                            ...definedParam,
                            value,
                            isValid: Boolean(value.length),
                          } as IUpsertPasswordParam)
                        );
                        setUpdatedParameters(update);
                      }}
                    />
                  ) : null;
                case "credential":
                  return definedParam.type === "credential" ? (
                    <AppJobCredParamEditor
                      key={paramDef.id}
                      credential={definedParam.credential}
                      credentialTemplate={paramDef.credentialTemplate}
                      paramDef={paramDef}
                      required={paramDef.required}
                      onChange={(credentialId) => {
                        const update = new Map(
                          updatedParameters.set(paramDef.id, {
                            ...definedParam,
                            credentialId,
                            isValid: Boolean(credentialId),
                          } as IUpsertCredParam)
                        );
                        setUpdatedParameters(update);
                      }}
                    />
                  ) : null;
                case "db_query":
                  return (
                    definedParam.type === "db_query" && (
                      <AppJobDbQueryParamEditor
                        key={paramDef.id}
                        paramDef={paramDef}
                        parameter={definedParam as IUpsertDbQueryParam}
                        onChange={(queryString) => {
                          const update = new Map(
                            updatedParameters.set(paramDef.id, {
                              ...definedParam,
                              queryString,
                              isValid: Boolean(queryString.length),
                            } as IUpsertDbQueryParam)
                          );
                          setUpdatedParameters(update);
                        }}
                      />
                    )
                  );
                case "json":
                  return (
                    definedParam.type === "json" && (
                      <AppJobJSONParamEditor
                        key={paramDef.id}
                        paramDef={paramDef}
                        parameter={definedParam as IUpsertJSONParam}
                        onChange={(value) => {
                          const update = new Map(
                            updatedParameters.set(paramDef.id, {
                              ...definedParam,
                              value,
                              isValid: Boolean(value.length),
                            } as IUpsertJSONParam)
                          );
                          setUpdatedParameters(update);
                        }}
                      />
                    )
                  );
                case "option":
                  return (
                    definedParam.type === "option" && (
                      <AppJobOptionParamEditor
                        key={paramDef.id}
                        paramDef={paramDef as IOptionParameterDefinition}
                        parameter={definedParam as IUpsertOptionParam}
                        onChange={(value) => {
                          const update = new Map(
                            updatedParameters.set(paramDef.id, {
                              ...definedParam,
                              value,
                              isValid: Boolean(value.length),
                            } as IUpsertOptionParam)
                          );
                          setUpdatedParameters(update);
                        }}
                      />
                    )
                  );
                default:
                  return null;
              }
            }
            return null;
          })}
          <Box>
            <SaveButton
              isLoading={isSavingParameters}
              disabled={!requirementsMet}
              submitForm
              label={t("common:save")}
            />
            <CancelButton onClick={() => setIsEditing(false)} />
          </Box>
        </Form>
      ) : (
        <Box>
          {job.app.parameterDefinitions.map((paramDef) => {
            const definedParam = updatedParameters.get(paramDef.id);
            if (definedParam)
              return (
                <AppJobParameterRow
                  key={paramDef.id}
                  parameter={definedParam}
                  paramDef={paramDef}
                />
              );
            return null;
          })}
        </Box>
      )}
    </Box>
  );
}
