import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import styled from "styled-components";
import {
  space,
  color,
  typography,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from "styled-system";

export const StyledRow = styled(Grid)<SpaceProps>(space);
StyledRow.defaultProps = {
  mt: 2,
  mb: 2,
  container: true,
  spacing: 2,
};

interface RowProps extends SpaceProps, GridProps {
  centerVertically?: boolean;
  children: React.ReactNode | React.ReactNodeArray;
}
export function Row({ centerVertically, children, ...rest }: RowProps) {
  return (
    <StyledRow alignItems={centerVertically ? "center" : "stretch"} {...rest}>
      {children}
    </StyledRow>
  );
}

const defaultColProps = {
  xs: true,
  item: true,
};
interface ColProps extends SpaceProps, ColorProps, TypographyProps {
  truncate?: boolean;
}
export const Col = styled(Grid)<ColProps>`
  ${color}
  ${space}
  ${typography}
  white-space: ${(props) => (props.truncate ? "nowrap" : "inherit")};
  overflow: ${(props) => (props.truncate ? "hidden" : "inherit")};
  text-overflow: ${(props) => (props.truncate ? "ellipsis" : "inherit")};
`;
Col.defaultProps = defaultColProps;

interface DenseProps extends SpaceProps, TypographyProps, GridProps {}
export function DenseCol({ children, style, ...rest }: DenseProps) {
  return (
    <Col style={{ paddingTop: 0, paddingBottom: 0, ...style }} {...rest}>
      {children}
    </Col>
  );
}

DenseCol.defaultProps = defaultColProps;
