// @ts-nocheck
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledTextField as TextField } from "./Form";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledPaper = styled(Paper)`
  font-family: ${({ theme }) => theme.fonts.main};
  .MuiAutocomplete-groupLabel {
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.navText};
    font-weight: 600;
  }
`;

const EndAdornment = styled.span`
  .MuiButtonBase-root {
    padding: 3px;
  }
`;

export interface OptionType {
  label: string;
  inputValue?: string;
  data?: any;
  groupBy?: string;
}

interface IProps {
  id: string;
  label: string;
  canCreateOptions?: boolean;
  onChange?: (inputValue: string | null, optionData: any) => void;
  options?: OptionType[];
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  style?: object;
  multiselect?: true;
  value?: OptionType | OptionType[] | null;
  filterSelectedOptions?: boolean;
  defaultValue?: OptionType | OptionType[];
}

const filter = createFilterOptions<OptionType>();

export default function TMCAutocomplete({
  id,
  label,
  canCreateOptions,
  onChange,
  options,
  loading,
  multiselect,
  value,
  required,
  filterSelectedOptions,
  defaultValue,
  ...rest
}: IProps) {
  return (
    <Autocomplete
      id={id}
      PaperComponent={StyledPaper}
      size="small"
      freeSolo={canCreateOptions}
      selectOnFocus
      clearOnBlur
      clearOnEscape
      value={value}
      defaultValue={defaultValue}
      multiple={multiselect}
      filterSelectedOptions={
        filterSelectedOptions !== undefined ? filterSelectedOptions : true
      }
      handleHomeEndKeys={canCreateOptions}
      forcePopupIcon={true}
      {...rest}
      onChange={(
        _: React.ChangeEvent<{}>,
        selection: OptionType | OptionType[] | null
      ) => {
        if (onChange) {
          if (selection) {
            let inputValue = null;
            if (canCreateOptions && !Array.isArray(selection)) {
              inputValue = selection.inputValue || null;
            }
            const optionData = Array.isArray(selection)
              ? selection.map((val) => val.data)
              : selection.data;
            onChange(inputValue, optionData);
          } else {
            onChange(null, null);
          }
        }
      }}
      options={options || []}
      groupBy={(option) => (option.groupBy ? option.groupBy : "")}
      filterOptions={
        canCreateOptions
          ? (options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }
          : undefined
      }
      getOptionSelected={(option, value) => {
        return option.label === value.label;
      }}
      getOptionLabel={
        canCreateOptions
          ? (option: OptionType | string) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.label;
            }
          : (option) => {
              return option.label;
            }
      }
      renderOption={(option: OptionType) => option.label}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            id={`${id}-input-group`}
            label={label}
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <EndAdornment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </EndAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
}

interface AsyncProps extends IProps {
  id: string;
  label: string;
  fetch: () => Promise<OptionType[] | undefined>;
}
export function AsyncAutocomplete({ id, label, fetch, ...rest }: AsyncProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionType[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const options = await fetch();
      if (active && options) setOptions(options);
    })();

    return () => {
      active = false;
    };
  }, [loading, fetch]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <TMCAutocomplete
      id={id}
      label={label}
      options={options}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      {...rest}
    />
  );
}
