import useRequest from "../hooks/useRequest";
import { ITag } from "../types";

export interface UpsertTagProps {
  name: string;
  job_id?: string;
  workflow_id?: string;
}

export default function useTagsApi() {
  const { get, post, remove } = useRequest();
  return {
    fetchTags: async (options?: { exclude?: string[] }) => {
      const { data } = await get<ITag[]>("/tags", { params: { ...options } });
      return data;
    },

    createTag: async (newTag: UpsertTagProps) => {
      const { data } = await post<ITag>("/tags", newTag);
      return data;
    },

    updateTag: async (tagId: string, update: UpsertTagProps) => {
      const { data } = await post<ITag>(`/tags/${tagId}`, update);
      return data;
    },

    removeTag: (tagId: string) => {
      return remove(`/tags/${tagId}`);
    },
  };
}
