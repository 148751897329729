import format from "date-fns/format";
import { enUS } from "date-fns/locale";
import formatDistance from "date-fns/formatDistance";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import add from "date-fns/add";
import parse from "date-fns/parse";

const locales = { en: enUS };
const selectedLocale = "en";

export const DEFAULT_DATE_FORMAT = "MMM do, yyyy";
export const DEFAULT_DT_FORMAT = `${DEFAULT_DATE_FORMAT}, h:mm a`;

export function formatDateTime(d: string | Date, customTemplate?: string) {
  const template = customTemplate || DEFAULT_DT_FORMAT;
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, template, { locale: locales[selectedLocale] });
}

export function getDuration(baseDate: string, targetDate: string) {
  return formatDistanceStrict(new Date(baseDate), new Date(targetDate), {
    locale: locales[selectedLocale],
  });
}

export function formatDuration(seconds: number) {
  return formatDistanceStrict(new Date(0), add(new Date(0), { seconds }), {
    unit: seconds < 60 ? "second" : undefined,
  });
}

export function timeAgo(baseDate: string) {
  return formatDistance(new Date(baseDate), new Date(), {
    addSuffix: true,
    locale: locales[selectedLocale],
  });
}

export function getDayOfWeek(d: Date) {
  return format(d, "EEEE", { locale: locales[selectedLocale] });
}

export function getLocalTimezone(d: Date) {
  return d.toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
}

export function getDayOfMonth(d: Date) {
  return format(d, "do", { locale: locales[selectedLocale] });
}

export function getDate(dateString: string, format = DEFAULT_DT_FORMAT) {
  return parse(dateString, format, new Date());
}
