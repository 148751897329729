import React from "react";
import { StylesProvider } from "@material-ui/core/styles";
import styled, { ThemeProvider } from "styled-components";
import { typography, color } from "styled-system";
import { Normalize } from "styled-normalize";
import theme from "./theme";
import { AlertProvider } from "./contexts/alertContext";
import { DBContextProvider } from "./contexts/dbContext";

import AuthContainer from "./components/AuthContainer";
import GlobalStyle from "./globalStyles";

const AppContainer = styled.div<{
  className: string;
  fontFamily: string;
  bg: string;
}>`
  height: 100vh;
  ${color}
  ${typography}
`;

export default function App() {
  return (
    <>
      <Normalize />
      <GlobalStyle />
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <DBContextProvider>
              <AppContainer className="App" fontFamily="main" bg="background">
                <AuthContainer />
              </AppContainer>
            </DBContextProvider>
          </AlertProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}
