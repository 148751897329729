import styled from "styled-components";
import { color, space, ColorProps, SpaceProps } from "styled-system";

interface IProps extends ColorProps, SpaceProps {}

const StyledPre = styled.pre<IProps>`
  ${color}
  ${space}
  display: block;
  overflow: auto;
`;

StyledPre.defaultProps = {
  color: "code",
  bg: "codeBackground",
  p: 3,
};

export default StyledPre;
