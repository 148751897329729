import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import styled from "styled-components";

interface DProps {
  label: string;
  defaultDate?: string;
  disabled: boolean;
  onChange: (v: string) => void;
}

const StyledTextField = styled(TextField)<TextFieldProps>`
  margin-right: 16px;
  input {
    font-family: ${({ theme }) => theme.fonts.main};
  }
`;

export function DatePicker({ label, defaultDate, onChange, disabled }: DProps) {
  return (
    <StyledTextField
      type="date"
      disabled={disabled}
      label={label}
      defaultValue={defaultDate}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
    />
  );
}

interface TProps {
  label: string;
  defaultTime: string;
  disabled?: boolean;
  onChange: (v: string) => void;
}

export function TimePicker({ label, defaultTime, onChange, disabled }: TProps) {
  return (
    <StyledTextField
      type="time"
      disabled={disabled}
      label={label}
      defaultValue={defaultTime}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
    />
  );
}
