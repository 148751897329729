import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import SQLCodeEditor from "../common/CodeEditor";
import {
  Box,
  Button,
  CancelButton,
  Form,
  Row,
  SaveButton,
  TextField,
} from "../common";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import useDBConnection from "../../hooks/useDBConnection";
import useDbQueryApi from "../../api/dbQueryApi";
import { IDBQuery } from "../../types";
import useAlert from "../../hooks/useAlert";

export default function SQLQueryForm({
  handleSubmit,
  isSubmitting,
  queryValue,
  onChangeQueryValue,
  query,
}: {
  handleSubmit: () => void;
  isSubmitting: boolean;
  queryValue: string;
  onChangeQueryValue: (v: string) => void;
  query: IDBQuery | null;
}) {
  const { dbConnection } = useDBConnection();
  const incomplete = !queryValue || !dbConnection;
  const [showJobForm, setShowJobForm] = useState(false);
  const [jobName, setJobName] = useState("");
  const history = useHistory();
  const { showError } = useAlert();

  const { t } = useTranslation("SQL Browser");

  const { createQuery, createQueryJob } = useDbQueryApi();

  const createJob = async () => {
    if (dbConnection) {
      try {
        const withQuery =
          query ||
          (await createQuery({
            queryString: queryValue,
          }));
        const job = await createQueryJob(
          withQuery.id,
          dbConnection.id,
          jobName
        );
        history.push(`/app/jobs/${job.id}`);
      } catch {
        showError(t("Error creating job"));
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box mb={3}>
          <SQLCodeEditor
            value={queryValue}
            onChangeValue={onChangeQueryValue}
          />
        </Box>
        <Box>
          <SaveButton
            submitForm
            disabled={isSubmitting || incomplete}
            label={t("Run")}
          />

          <Button
            disabled={isSubmitting || incomplete}
            onClick={() => setShowJobForm(true)}
          >
            {t("Create job")}
          </Button>
        </Box>
      </Form>

      <Modal show={showJobForm} onHide={() => setShowJobForm(false)}>
        <Form onSubmit={createJob}>
          <ModalHeader onClose={() => setShowJobForm(false)}>
            <ModalTitle>{t("Add a name for your job")}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Row>
              <TextField
                id="job-name-input"
                label={t("common:name")}
                onChange={setJobName}
                fullWidth
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <SaveButton disabled={!jobName} submitForm />
            <CancelButton onClick={() => setShowJobForm(false)} />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
