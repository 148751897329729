import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  EditButton,
  LinearProgress,
  TextField,
} from "../common";
import useAlert from "../../hooks/useAlert";
import { statusCodeMap } from "../../constants";
import useWorkflowRunsApi from "../../api/workflowRunsApi";
import useWorkflowApi from "../../api/workflowApi";
import { IWorkflow } from "../../types";
import useSubmitting from "../../hooks/useSubmitting";

export function WorkflowPageHeader({
  workflow,
  refreshWorkflow,
  cancelRun,
  isCanceling,
}: {
  workflow: IWorkflow;
  refreshWorkflow: () => Promise<void>;
  cancelRun: (runId?: string) => Promise<void>;
  isCanceling: boolean;
}) {
  const { t } = useTranslation("Workflow Page");
  const [isEditingName, setIsEditingName] = useState(false);
  const [updatedName, setUpdatedName] = useState(workflow.name);
  const { showError, showWarning, showSuccess } = useAlert();
  const { createWorkflowRun } = useWorkflowRunsApi();
  const { removeWorkflow, updateWorkflow } = useWorkflowApi();
  const history = useHistory();

  useEffect(() => {
    setUpdatedName(workflow.name);
  }, [workflow.name, isEditingName]);

  const [handleRemove, isSubmittingRemove] = useSubmitting(async () => {
    if (!workflow) return null;
    try {
      await removeWorkflow(workflow.id);
      showWarning(t("Worflow removed", { name: workflow.name }));
      history.push("/app/workflows");
    } catch {
      showError(t("Error removing workflow"));
    }
  });

  const [addWorkflowRun, isSubmittingRun] = useSubmitting(async () => {
    try {
      await createWorkflowRun(workflow.id);
      showSuccess(t("Starting workflow run"));
      await refreshWorkflow();
    } catch {
      showError(t("Error creating workflow run"));
    }
  });

  const [saveName, isSubmittingUpdate] = useSubmitting(async () => {
    const updatedWorkflow = { ...workflow, name: updatedName };
    try {
      await updateWorkflow(updatedWorkflow);
      await refreshWorkflow();
    } catch (e) {
      showError(t("Error updating job name!"));
    } finally {
      setIsEditingName(false);
    }
  });

  const latestRunStatus =
    workflow.runs && workflow.runs[0] ? workflow.runs[0].statusCode : undefined;
  const runStatusVariant = latestRunStatus
    ? statusCodeMap[latestRunStatus].variant
    : undefined;
  const isRunning =
    isSubmittingRun ||
    (workflow.runs && workflow.runs[0] && workflow.runs[0].statusCode < 2);

  return (
    <>
      <h1>
        {isEditingName ? (
          <>
            <TextField
              id="job-name"
              fontSize="2.5rem"
              label="Name"
              onChange={setUpdatedName}
              value={updatedName}
            />
            <Box display="inline-block" ml={2}>
              <Button isLoading={isSubmittingUpdate} onClick={saveName}>
                {t("common:save")}
              </Button>
              <Button variant="outline" onClick={() => setIsEditingName(false)}>
                {t("common:cancel")}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <span>{updatedName}</span>
            <EditButton onClick={() => setIsEditingName(true)} />
          </>
        )}
      </h1>
      {isRunning ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Divider size="tall" variant={runStatusVariant} />
      )}
      <Box mb={3}>
        <Button
          mr={3}
          size="lg"
          isLoading={isRunning}
          loadingMessage={t("Running")}
          onClick={addWorkflowRun}
        >
          {t("Run")}
        </Button>
        {isRunning ? (
          <Button
            size="lg"
            variant="warning"
            isLoading={isCanceling}
            loadingMessage={t("Canceling")}
            onClick={() => cancelRun(workflow?.activeRun?.id)}
          >
            {t("Cancel Run")}
          </Button>
        ) : (
          <Button
            size="lg"
            variant="outline-danger"
            isLoading={isSubmittingRemove}
            onClick={() => handleRemove()}
          >
            {t("Remove workflow")}
          </Button>
        )}
      </Box>
    </>
  );
}
