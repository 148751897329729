import useRequest from "../hooks/useRequest";
import { IReport, ISlackConfig } from "../types";

export interface CreateReportProps {
  name: string;
  cronSchedule: string;
  tags: string[] | null;
  notifications: ISlackConfig[];
}

export interface UpdateReportProps {
  name: string;
  cronSchedule: string;
  addedTags?: string[];
  removedTags?: string[];
  addedNotifications?: ISlackConfig[];
  removedNotifications?: string[];
}

export default function useReportsApi() {
  const { get, post, remove } = useRequest();
  return {
    fetchReports: async () => {
      const { data } = await get<IReport[]>("/reports");
      return data;
    },
    createReport: async (report: CreateReportProps) => {
      const { data } = await post<IReport>("/reports", report);
      return data;
    },
    removeReport: async (id: string) => {
      return remove(`/reports/${id}`);
    },
    editReport: async (id: string, update: UpdateReportProps) => {
      const { data } = await post<IReport>(`/reports/${id}`, update);
      return data;
    },
  };
}
