import React, { useState, useEffect } from "react";
import { Virtuoso } from "react-virtuoso";

import useDbQueryApi from "../../api/dbQueryApi";
import useDBConnection from "../../hooks/useDBConnection";
import { Row, Loading, Box, Span, ButtonSpinner } from "../common";
import useAlert from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import SchemaRow from "./SchemaRow";
import DBConnectionForm from "./DBConnectionForm";

export function SectionLoading({ label }: { label: string }) {
  return (
    <Row mt={0} mb={0}>
      <Span color="outline" ml={2} mt={1}>
        <ButtonSpinner />
      </Span>
      <Span color="outline">{label}</Span>
    </Row>
  );
}

export function SectionHeader({ label }: { label: string }) {
  return (
    <Row mt={0} mb={0}>
      <Span
        fontWeight="bold"
        color="navText"
        style={{ textDecoration: "underline" }}
      >
        {label}
      </Span>
    </Row>
  );
}

export default function SchemaTree() {
  const [schemas, setSchemas] = useState<string[]>([]);
  const { dbConnection } = useDBConnection();
  const { fetchSchemas } = useDbQueryApi();
  const { showError } = useAlert();
  const { t } = useTranslation("SQL Browser");

  const getSchemas = async () => {
    if (dbConnection) {
      try {
        const result = await fetchSchemas(dbConnection.id);
        setSchemas(result.schemas);
      } catch {
        showError(t("Error fetching schemas"));
        setSchemas([]);
      }
    }
  };

  useEffect(() => {
    getSchemas();
  }, [dbConnection]);

  return (
    <>
      <DBConnectionForm />

      {dbConnection ? (
        schemas.length > 0 ? (
          <Box mt={3} pb={5} style={{ height: "100%" }}>
            <SectionHeader label={t("Schemas")} />
            <Virtuoso
              itemHeight={30}
              totalCount={schemas.length}
              item={(index) => <SchemaRow schema={schemas[index]} />}
            />
          </Box>
        ) : (
          <Loading />
        )
      ) : null}
    </>
  );
}
