import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useReportsApi, {
  CreateReportProps,
  UpdateReportProps,
} from "../../api/reportsApi";
import useAlert from "../../hooks/useAlert";
import { useOnMount } from "../../hooks/useOnMount";
import { IReport } from "../../types";
import {
  Button,
  Chip,
  Col,
  EditButton,
  Loading,
  RemoveButton,
  Row,
  Table,
  TableIconCell,
} from "../common";
import { formatCron, parseCron } from "../scheduler/SchedulerForm";
import EditReportModalForm from "./EditReportModalForm";
import NewReportModalForm from "./NewReportModalForm";

export default function ReportsPage() {
  const [reports, setReports] = useState<IReport[] | null>(null);
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const {
    fetchReports,
    createReport,
    removeReport,
    editReport,
  } = useReportsApi();
  const { showSuccess, showError, showWarning } = useAlert();
  const { t } = useTranslation("Reports Page");

  const fetch = async () => {
    try {
      const updated = await fetchReports();
      setReports(updated);
    } catch {
      showError(t("Error fetching reports"));
    }
  };
  useOnMount(fetch);

  const handleCreate = async (data: CreateReportProps) => {
    try {
      await createReport(data);
      fetch();
      showSuccess(t("Created report"));
      setShowCreateModal(false);
    } catch {
      showError(t("Error creating report"));
    }
  };

  const handleRemove = async (id: string) => {
    try {
      await removeReport(id);
      fetch();
      showWarning(t("Removing report"));
    } catch {
      showError(t("Error removing report"));
    }
  };

  const handleEdit = async (reportId: string, update: UpdateReportProps) => {
    try {
      await editReport(reportId, update);
      fetch();
      showSuccess(t("Updated report"));
      setShowEditModal(false);
      setSelectedReport(null);
    } catch {
      showError(t("Error updating report"));
    }
  };

  return (
    <>
      <Row centerVertically>
        <Col>
          <h1>{t("Reports")}</h1>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            size="lg"
            onClick={() => {
              setSelectedReport(null);
              setShowCreateModal(true);
            }}
          >
            {t("Add Report")}
          </Button>
        </Col>
      </Row>
      {reports ? (
        <Table>
          <thead>
            <tr>
              <th>{t("Report")}</th>
              <th>{t("common:schedule")}</th>
              <th>{t("Channels")}</th>
              <th>{t("common:tags")}</th>
              <th>{t("common:Created By")}</th>
              <th>{t("common:actions")}</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => {
              return (
                <tr key={report.id}>
                  <td>{report.name}</td>
                  <td>{formatCron(parseCron(report.cronSchedule))}</td>
                  <td>
                    {report.notifications
                      .map(({ channelName }) => channelName)
                      .join(",")}
                  </td>
                  <td>
                    {report.tags.map((tag) => (
                      <Chip label={tag.name} />
                    ))}
                  </td>
                  <td>{report.author.name}</td>
                  <TableIconCell>
                    <EditButton
                      onClick={() => {
                        setSelectedReport(report);
                        setShowEditModal(true);
                      }}
                    />
                    <RemoveButton onClick={() => handleRemove(report.id)} />
                  </TableIconCell>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Loading />
      )}

      <NewReportModalForm
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onSave={handleCreate}
      />

      {selectedReport && (
        <EditReportModalForm
          report={selectedReport}
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false);
            setSelectedReport(null);
          }}
          onSave={(update) => {
            handleEdit(selectedReport.id, update);
          }}
        />
      )}
    </>
  );
}
