import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChangeEvent } from "../../types";
import useAlert from "../../hooks/useAlert";
import {
  Box,
  Button,
  ClearButton,
  Checkbox,
  Select,
  Span,
  Row,
  Col,
  Input,
  EditButton,
  Tooltip,
} from "../common";
import { DatePicker, TimePicker } from "../common/Pickers";
import {
  DEFAULT_DATE_FORMAT,
  formatDateTime,
  getDate,
  getDayOfMonth,
  getDayOfWeek,
  getLocalTimezone,
} from "../../time";
import { IJob, IWorkflow } from "../../types";

const DATE_FORMAT = "yyyy-MM-dd";
const TIME_FORMAT = "HH:mm";
export const HELPER_TIME_FORMAT = "h:mma";
export const DEFAULT_LAST_DAY_SELECTED = false;
const DEFAULT_INTERVAL = "";
export const DEFAULT_SHORT_INTERVAL = 5;
export const DEFAULT_SHORT_INTERVAL_TYPE = "minutes";

interface ICron {
  localDateTime: Date;
  interval: string;
  lastDayOfMonthSelected: boolean;
  shortInterval: number | null;
  shortIntervalType: string;
}

export function formatCron({
  localDateTime,
  interval,
  lastDayOfMonthSelected,
  shortInterval,
  shortIntervalType,
}: ICron) {
  if (interval === "short")
    return `every ${shortInterval} ${shortIntervalType}`;

  let dailyInterval = "";
  if (interval === "daily") dailyInterval = "Daily";
  if (interval === "weekly") {
    dailyInterval = `every ${getDayOfWeek(localDateTime)}`;
  }
  if (interval === "monthly") {
    const dayOfMonth = lastDayOfMonthSelected
      ? "last day"
      : getDayOfMonth(localDateTime);
    dailyInterval = `on the ${dayOfMonth} of every month`;
  }
  const tz = getLocalTimezone(localDateTime);
  const dateString = dailyInterval
    ? `${tz}, ${dailyInterval}`
    : `${tz} on ${formatDateTime(localDateTime, DEFAULT_DATE_FORMAT)}`;
  return `${formatDateTime(localDateTime, HELPER_TIME_FORMAT)} ${dateString}`;
}

export function generateCron(
  selectedDateTime: Date,
  repeatInterval: string,
  lastDayOfMonthSelected: boolean,
  shortInterval: number | null,
  shortIntervalType: string
) {
  let cronTime = `${selectedDateTime.getUTCMinutes()} ${selectedDateTime.getUTCHours()}`;

  if (!repeatInterval) {
    const UTCDate = selectedDateTime.getUTCDate();
    const UTCMonth = selectedDateTime.getUTCMonth() + 1;
    const UTCYear = selectedDateTime.getUTCFullYear();
    return `0 ${cronTime} ${UTCDate} ${UTCMonth} ? ${UTCYear}`;
  }

  let cronInterval = "* * *";

  if (repeatInterval === "weekly")
    cronInterval = `? * ${selectedDateTime.getDay() + 1}`;
  if (repeatInterval === "monthly") {
    const dateOfMonth = lastDayOfMonthSelected
      ? "L"
      : selectedDateTime.getUTCDate();
    cronInterval = `${dateOfMonth} * ?`;
  }
  if (repeatInterval === "short") {
    if (shortIntervalType === "minutes") cronTime = `*/${shortInterval} *`;
    if (shortIntervalType === "hours") cronTime = `0 */${shortInterval}`;
  }
  return `${cronTime} ${cronInterval}`;
}

export function parseCron(cron: string): ICron {
  const cronSplit = cron.split(" ");
  const isRepeating = cronSplit.length <= 5;
  const cronMinutes = cronSplit[isRepeating ? 0 : 1];
  const cronHours = cronSplit[isRepeating ? 1 : 2];
  const cronDate = cronSplit[isRepeating ? 2 : 3];
  const cronMonth = cronSplit[isRepeating ? 3 : 4];
  const hasMinutesInterval = cronMinutes.search("/") !== -1;
  const hasHoursInterval = cronHours.search("/") !== -1;
  const hasDateTime = !hasMinutesInterval && !hasHoursInterval;

  const getLocalDateTime = () => {
    const d = new Date();
    return new Date(
      Date.UTC(
        !isRepeating ? parseInt(cronSplit[6]) : d.getFullYear(),
        !isNaN(parseInt(cronMonth)) ? parseInt(cronMonth) - 1 : d.getMonth(),
        !isNaN(parseInt(cronDate)) ? parseInt(cronDate) : d.getDate(),
        parseInt(cronHours),
        parseInt(cronMinutes)
      )
    );
  };

  const getInterval = () => {
    if (isRepeating && !hasDateTime) return "short";
    if (isRepeating) {
      if (cronDate === "?") return "weekly";
      if (cronDate === "L" || !isNaN(parseInt(cronDate))) return "monthly";
      return "daily";
    }
    return DEFAULT_INTERVAL;
  };

  const getshortInterval = () => {
    if (hasMinutesInterval) return parseInt(cronMinutes.split("/")[1]);
    if (hasHoursInterval) return parseInt(cronHours.split("/")[1]);
    return DEFAULT_SHORT_INTERVAL;
  };

  return {
    localDateTime: hasDateTime ? getLocalDateTime() : new Date(),
    interval: getInterval(),
    lastDayOfMonthSelected: cronDate === "L",
    shortInterval: !hasDateTime ? getshortInterval() : 5,
    shortIntervalType:
      !hasDateTime && hasHoursInterval ? "hours" : DEFAULT_SHORT_INTERVAL_TYPE,
  };
}

export default function SchedulerForm({
  cronLine,
  onSubmit,
  onRemove,
  cronScheduleEnd,
  durationLimit,
  isJob,
}: {
  cronLine: string;
  onSubmit: (
    cron: string,
    expiration?: string,
    durationLimit?: number
  ) => Promise<IJob | IWorkflow>;
  onRemove: () => Promise<IJob | IWorkflow>;
  cronScheduleEnd?: string;
  durationLimit?: number;
  isJob: boolean;
}) {
  const [schedule, setSchedule] = useState(
    cronLine ? parseCron(cronLine) : null
  );
  const [selectedDate, setSelectedDate] = useState(
    formatDateTime(schedule ? schedule.localDateTime : new Date(), DATE_FORMAT)
  );
  const [selectedTime, setSelectedTime] = useState(
    formatDateTime(schedule ? schedule.localDateTime : new Date(), TIME_FORMAT)
  );
  const [repeatInterval, setRepeatInterval] = useState(
    schedule ? schedule.interval : DEFAULT_INTERVAL
  );
  const [expirationSelected, setExpirationSelected] = useState(
    Boolean(cronScheduleEnd)
  );
  const [expiration, setExpiration] = useState(cronScheduleEnd);
  const [lastDayOfMonthSelected, setLastDayOfMonthSelected] = useState(
    schedule ? schedule.lastDayOfMonthSelected : DEFAULT_LAST_DAY_SELECTED
  );
  const [shortInterval, setShortInterval] = useState<number | null>(
    schedule ? schedule.shortInterval : DEFAULT_SHORT_INTERVAL
  );
  const [shortIntervalType, setShortIntervalType] = useState(
    schedule ? schedule.shortIntervalType : DEFAULT_SHORT_INTERVAL_TYPE
  );
  const [durationLimitSelected, setDurationLimitSelected] = useState(
    Boolean(durationLimit)
  );
  const [durationLimitInHours, setDurationLimitInHours] = useState<
    number | undefined
  >(durationLimit || 24);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSuccess, showError, showWarning } = useAlert();
  const { t } = useTranslation("Scheduler");

  const getSelectedDateTime = () => {
    return getDate(
      `${selectedDate} ${selectedTime}`,
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );
  };

  const possibleEndOfMonthSelected = () => {
    return parseInt(selectedDate.split("-")[2]) >= 28;
  };

  const handleIntervalChange = (interval: string) => {
    setLastDayOfMonthSelected(false);
    setRepeatInterval(interval);
  };

  const showHelperText = () => {
    let msg = t("Set a schedule");
    if (!selectedDate) msg = t("Please select a date");
    if (!selectedTime) msg = t("Please select a time");
    if (selectedDate && selectedTime)
      msg = t("Schedule set for", {
        cronTab: formatCron({
          localDateTime: getSelectedDateTime(),
          interval: repeatInterval,
          lastDayOfMonthSelected,
          shortInterval,
          shortIntervalType,
        }),
      });
    return msg;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const cron = generateCron(
      getSelectedDateTime(),
      repeatInterval,
      lastDayOfMonthSelected,
      shortInterval,
      shortIntervalType
    );
    try {
      const updatedDurationLimit =
        durationLimitSelected && setDurationLimitInHours
          ? durationLimitInHours
          : undefined;
      const update = await onSubmit(cron, expiration, updatedDurationLimit);
      setIsSubmitting(false);
      setSchedule(parseCron(update.cronSchedule));
      if (update.cronScheduleEnd) setExpiration(update.cronScheduleEnd);
      // if (update.durationLimit) setDurationLimitInHours(update.durationLimit);
      showSuccess(t("Updated schedule"));
      setIsUpdating(false);
    } catch {
      showError(t("Error setting schedule"));
      setIsSubmitting(false);
    }
  };

  const handleRemove = async () => {
    setIsSubmitting(true);
    try {
      await onRemove();
      setIsSubmitting(false);
      showWarning(t("Removed schedule"));
      setSchedule(null);
    } catch {
      showError(t("Error removing schedule"));
      setIsSubmitting(false);
    }
  };

  // reset the expiration state if user toggles through different repeat intervals
  const resetExpiration = () => {
    if (!repeatInterval) setExpirationSelected(false);
  };
  useEffect(resetExpiration, [repeatInterval]);

  // update the schedule UI if there are server updates to the scheduler info
  const resetSchedule = () => {
    setSchedule(cronLine ? parseCron(cronLine) : null);
    setExpiration(cronScheduleEnd);
    // TODO: only show warning if component already mounted
    // showWarning(t("Schedule updated!"));
  };
  useEffect(resetSchedule, [cronLine, cronScheduleEnd]);

  return (
    <Box>
      <h3>
        {t("Schedule")}
        <Tooltip title="Edit Schedule">
          <EditButton onClick={() => setIsUpdating(true)} />
        </Tooltip>
      </h3>
      {isUpdating ? (
        <>
          <Box mt={3} mb={3}>
            <DatePicker
              disabled={
                repeatInterval === "daily" || repeatInterval === "short"
              }
              label={t("Date")}
              defaultDate={selectedDate}
              onChange={setSelectedDate}
            />
            <TimePicker
              disabled={repeatInterval === "short"}
              label={t("Time")}
              defaultTime={selectedTime}
              onChange={setSelectedTime}
            />
          </Box>
          <Box mb={3}>
            <strong>Repeat:</strong>
            <Span ml={2}>
              <Select
                id="repeat-select"
                name="repeat-interval"
                value={repeatInterval}
                onChange={(v) => handleIntervalChange(v)}
              >
                <option aria-label="None" value="">
                  {t("None")}
                </option>
                <option value="daily">{t("Daily")}</option>
                <option value="weekly">{t("Weekly")}</option>
                <option value="monthly">{t("Monthly")}</option>
                <option value="short">{t("Multiple Times a Day")}</option>
              </Select>
            </Span>
          </Box>

          {repeatInterval === "monthly" && possibleEndOfMonthSelected() && (
            <Box>
              <Checkbox
                checked={lastDayOfMonthSelected}
                label={t("Set to every last day of the month?")}
                onChange={(checkedState: boolean) =>
                  setLastDayOfMonthSelected(checkedState)
                }
              />
            </Box>
          )}

          {repeatInterval === "short" && (
            <Row>
              <Col
                xs={1}
                mr={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                {t("Every")}
              </Col>
              <Col xs={2}>
                <Input
                  type="number"
                  label=""
                  name="short-interval"
                  value={String(shortInterval)}
                  onChange={(e: ChangeEvent) =>
                    setShortInterval(parseInt(e.target.value))
                  }
                />
              </Col>
              <Col pl={0} style={{ display: "flex" }}>
                <Select
                  name="short-interval-type"
                  id="short-interval-select"
                  value={shortIntervalType}
                  onChange={(v) => setShortIntervalType(v)}
                >
                  <option value="minutes">{t("Minutes")}</option>
                  <option value="hours">{t("Hours")}</option>
                </Select>
              </Col>
            </Row>
          )}

          <Box mb={3} color="warning">
            {showHelperText()}
          </Box>

          {repeatInterval && (
            <Box mt={3} mb={3}>
              <Checkbox
                checked={expirationSelected}
                label={t("Set a schedule expiration date?")}
                onChange={(checked) => {
                  setExpirationSelected(checked);
                  if (!checked) setExpiration(undefined);
                }}
              />
            </Box>
          )}

          {repeatInterval && expirationSelected && (
            <Box mt={3} mb={3}>
              <DatePicker
                disabled={false}
                label={t("End schedule on")}
                defaultDate={
                  expiration
                    ? formatDateTime(new Date(expiration), DATE_FORMAT)
                    : ""
                }
                onChange={(s) => {
                  const d = getDate(s, DATE_FORMAT);
                  setExpiration(d.toISOString());
                }}
              />
            </Box>
          )}

          {isJob && (
            <Row mt={0} mb={3}>
              <Box
                style={{
                  display: "flex",
                  alignSelf: "flex-start",
                  alignItems: "center",
                  padding: "8px",
                  margin: 0,
                }}
              >
                <Checkbox
                  checked={durationLimitSelected}
                  label={t("Cancel after")}
                  onChange={(checked) => {
                    setDurationLimitSelected(checked);
                  }}
                />
              </Box>
              <Col xs={2}>
                <Input
                  type="number"
                  label=""
                  name="duration-limit"
                  value={String(durationLimitInHours)}
                  onChange={(e: ChangeEvent) =>
                    setDurationLimitInHours(parseInt(e.target.value))
                  }
                />
              </Col>
              <Col style={{ display: "flex", alignItems: "center" }}>
                {t("Hours")}
              </Col>
            </Row>
          )}

          <Box>
            <Button
              disabled={!selectedDate || !selectedTime}
              isLoading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {t("common:save")}
            </Button>
            <Button variant="outline" onClick={() => setIsUpdating(false)}>
              {t("common:cancel")}
            </Button>
          </Box>
        </>
      ) : (
        <>
          {schedule ? (
            <Box mt={3} mb={3}>
              <strong>{formatCron(schedule)}</strong>
              <ClearButton
                disabled={isSubmitting}
                onClick={() => handleRemove()}
              />
              {expiration && (
                <>
                  <br />
                  <em>
                    {`${t("Expires on")} ${formatDateTime(
                      expiration,
                      DEFAULT_DATE_FORMAT
                    )}`}
                  </em>
                </>
              )}
              {durationLimit && (
                <>
                  <br />
                  <em>
                    {`${t("Cancels after")} ${durationLimit} ${t("Hours")}`}
                  </em>
                </>
              )}
            </Box>
          ) : (
            <p>{t("Not Scheduled")}</p>
          )}
        </>
      )}
    </Box>
  );
}
