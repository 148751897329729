import React from "react";

import { Box, Row, Col, Link } from "../common";

import { IAppJob } from "../../types";
import SchedulerForm from "../scheduler/SchedulerForm";
import TagForm from "../tags/TagForm";
import NotificationForm from "../notifications/NotificationForm";
import useJobsApi from "../../api/jobApi";
import useTagsApi from "../../api/tagsApi";
import useNotificationsApi, {
  UpsertNotificationProps,
} from "../../api/notificationsApi";
import { useTranslation } from "react-i18next";
import AppJobParameterForm from "./AppJobParameterForm";

/**
 * Component for rendering the job details for a job built on an app.
 */
export default function AppJobDetail({
  job,
  refresh,
}: {
  job: IAppJob;
  refresh: () => void;
}) {
  const { addJobTag, removeJobTag, updateJobSchedule } = useJobsApi();
  const { createTag, fetchTags } = useTagsApi();
  const {
    createNotification,
    removeNotification,
    updateNotification,
  } = useNotificationsApi();
  const { t } = useTranslation("Job Page");

  return (
    <section>
      <Row style={{ minHeight: "16rem" }}>
        <Col md={6} xs={12}>
          <Box mb={4}>
            <h3>{t("App")}</h3>
            <p>
              <strong>{t("App Name")}:</strong>&nbsp;
              <Link to={`/app/apps/${job.app.id}`}>{job.app.name}</Link>
            </p>
          </Box>
        </Col>
        <Col pl={2} md={6} xs={12}>
          <Box mb={4}>
            <TagForm
              tags={job.tags}
              fetchTags={async () => {
                return await fetchTags({
                  exclude: job.tags.map((tag) => tag.id),
                });
              }}
              onSubmit={async (tag, isCreating) => {
                const result = isCreating
                  ? await createTag({ name: tag, job_id: job.id })
                  : await addJobTag(job.id, tag);
                if (result) refresh();
              }}
              onRemove={async (tagId, deleteTag) => {
                await removeJobTag(job.id, tagId, { deleteTag });
                refresh();
              }}
            />
          </Box>
          <Box mb={4}>
            <SchedulerForm
              isJob={true}
              cronLine={job.cronSchedule}
              cronScheduleEnd={job.cronScheduleEnd}
              durationLimit={job.durationLimit || undefined}
              onSubmit={(
                cronSchedule: string,
                expiration?: string,
                durationLimit?: number
              ) => {
                return updateJobSchedule(
                  job.id,
                  cronSchedule,
                  expiration,
                  durationLimit
                );
              }}
              onRemove={() => {
                return updateJobSchedule(job.id, "");
              }}
            />
          </Box>
          <Box>
            <NotificationForm
              notifications={job.notifications}
              onSave={async (v: UpsertNotificationProps) => {
                const props = { ...v, jobId: job.id };
                const upsert = v.id ? updateNotification : createNotification;
                await upsert(props);
                refresh();
                return null;
              }}
              onRemove={async (notificationId: string) => {
                await removeNotification(notificationId);
                refresh();
                return null;
              }}
            />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <AppJobParameterForm />
          </Box>
        </Col>
      </Row>
    </section>
  );
}
