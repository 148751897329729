import React, { ReactNode, ReactNodeArray } from "react";
import { Link, LinkProps } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import styled from "styled-components";

export const TMCList = styled(List)``;
TMCList.defaultProps = {
  dense: true,
};

const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-family: ${({ theme }) => theme.fonts.main};
  }
  .MuiListItemText-primary {
    max-width: 520px;
    margin-right: 16px;
  }
`;

export function TMCListItem({
  label,
  subText,
  actions,
  showDivider,
  ...rest
}: {
  label: string | ReactNode;
  subText?: string | ReactNode;
  actions?: ReactNode | ReactNodeArray;
  showDivider?: boolean;
}) {
  return (
    <ListItem disableGutters divider={showDivider} {...rest}>
      <StyledListItemText primary={label} secondary={subText} />
      {actions && <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>}
    </ListItem>
  );
}

// list item links via https://material-ui.com/components/lists/#simple-list
export function ListItemLink({
  icon,
  primary,
  to,
  onClick,
}: {
  icon: React.ReactNode;
  primary: string;
  to: string;
  onClick: () => void;
}) {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(
        (linkProps, ref) => <Link ref={ref} to={to} {...linkProps} />
      ),
    [to]
  );

  return (
    <li>
      <ListItem button component={CustomLink} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <StyledListItemText primary={primary} />
      </ListItem>
    </li>
  );
}
