import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";

export const TMCTab = styled(({ ...props }) => (
  <Tab disableRipple {...props} />
))`
  font-family: ${({ theme }) => theme.fonts.main};
`;

export function TMCTabs({
  value,
  onChange,
  children,
  ...rest
}: {
  children: React.ReactNodeArray;
  value: any;
  onChange: (_: React.ChangeEvent<{}>, val: any) => void;
}) {
  return (
    <Tabs indicatorColor="primary" value={value} onChange={onChange} {...rest}>
      {children}
    </Tabs>
  );
}
