import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useSlackApi from "../../api/slackApi";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { useOnMount } from "../../hooks/useOnMount";
import { ILink, ISlackConfig } from "../../types";
import {
  Autocomplete,
  Box,
  ButtonSpinner,
  Chip,
  Loading,
  Span,
  Tooltip,
} from "../common";
import { AddButton, CancelButton, SaveButton } from "../common/Button";
import SlackAuth from "../notifications/SlackAuth";

export default function ReportSlackConfig({
  selectedChannels,
  setSelectedChannels,
  onSave,
  onDelete,
}: {
  selectedChannels: ISlackConfig[];
  setSelectedChannels: (channels: ISlackConfig[]) => void;
  onSave?: (channels: ISlackConfig[]) => void;
  onDelete?: (actionId: string) => void;
}) {
  const [channelMap] = useState<Map<string, ISlackConfig>>(
    new Map(selectedChannels.map((c) => [c.channelId, c]))
  );
  const [channels, setChannels] = useState<ILink[] | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<ILink | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const { fetchChannels } = useSlackApi();
  const {
    authState: { parsonsUiUser },
  } = useAuth();
  const { t } = useTranslation("Notification Form");
  const { showError } = useAlert();

  useOnMount(async () => {
    try {
      const results = await fetchChannels();
      setChannels(results);
    } catch {
      showError("Error fetching slack channels");
    }
  });

  const renderForm = (slackEnabled: boolean) => {
    return slackEnabled ? (
      <Box mt={3}>
        <h4>
          {t("Channels")}
          {channels ? (
            <Tooltip title="Add Channel">
              <AddButton onClick={() => setIsAdding(true)} />
            </Tooltip>
          ) : (
            <Span ml={3}>
              <ButtonSpinner />
            </Span>
          )}
        </h4>
        <Box>
          {selectedChannels.map(({ id, channelId, channelName }) => (
            <Chip
              key={channelId}
              label={channelName}
              onDelete={() => {
                channelMap.delete(channelId);
                setSelectedChannels([...channelMap.values()]);
                if (onDelete && id) onDelete(id);
              }}
            />
          ))}
        </Box>

        {isAdding && channels && (
          <Box mt={3}>
            <Autocomplete
              id="channel-select"
              label={t("Select Slack channel to post to")}
              options={channels.map((channel) => {
                return { label: channel.name, data: channel };
              })}
              onChange={(_, channel) => {
                if (channel) {
                  setSelectedChannel(channel);
                }
              }}
            />
            <CancelButton onClick={() => setIsAdding(false)} />
            <SaveButton
              disabled={!selectedChannel}
              onClick={() => {
                if (selectedChannel) {
                  channelMap.set(selectedChannel.id, {
                    channelId: selectedChannel.id,
                    channelName: selectedChannel.name,
                    messageBody: "",
                  });
                  const updated = [...channelMap.values()];
                  setSelectedChannels(updated);
                  if (onSave) onSave(updated);
                  setIsAdding(false);
                  setSelectedChannel(null);
                }
              }}
            />
          </Box>
        )}
      </Box>
    ) : (
      <SlackAuth />
    );
  };

  return parsonsUiUser ? renderForm(parsonsUiUser.slackEnabled) : <Loading />;
}
