import React from "react";
import { IWorkflow } from "../../types";
import { Link, List, ListItem } from "../common";
import Status from "../jobs/JobRunStatus";

export default function WorkflowJobRunPoller({
  workflow,
}: {
  workflow: IWorkflow;
}) {
  return (
    <>
      <List style={{ width: "35%" }}>
        {workflow.nodes.map((node) => {
          return (
            <ListItem
              key={node.id}
              label={
                <Link to={`/app/jobs/${node.job.id}`}>{node.job.name}</Link>
              }
              actions={node.activeJobRun && <Status run={node.activeJobRun} />}
            />
          );
        })}
      </List>
    </>
  );
}
