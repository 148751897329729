import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCredentialsApi, {
  CreateCredentialProps,
} from "../../api/credentialsApi";
import useAlert from "../../hooks/useAlert";
import { ICredentialTemplate, ILink, IParameterDefinition } from "../../types";

import { AsyncAutocomplete, Col, Divider, Row } from "../common";
import NewCredentialTemplateModal from "../credentials/NewCredentialTemplateModal";
import ParamDefListItemLabel from "../apps/parameterDefinitions/ParamDefListItemLabel";

export default function AppJobCredParamEditor({
  credential,
  credentialTemplate,
  paramDef,
  required,
  onChange,
}: {
  credential: ILink | null;
  credentialTemplate: ICredentialTemplate;
  paramDef: IParameterDefinition;
  required: boolean;
  onChange: (credentialId: string) => void;
}) {
  const { t } = useTranslation("Job Page");
  const { showSuccess, showError } = useAlert();
  const { createCredential, fetchMyCredentials } = useCredentialsApi();
  // Track the value of the current credential in state to remember it, since we are only
  // persisting the ID back to the parameter
  const [currentCredential, setCurrentCredential] = useState<ILink | null>(
    credential
  );
  // Track whether or not we are currently showing the create credential modal
  const [showModal, setShowModal] = useState(false);

  // When selecting the credential, save the ID as the value on the parameter,
  // and also remember the credential itself so we can show it's name to the user
  const selectCredential = (credential: ILink | null) => {
    setCurrentCredential(credential);
    onChange(credential?.id || "");
  };
  // The option value to use to represent the currently selected credential
  const credentialOption = currentCredential
    ? { label: currentCredential.name, value: currentCredential }
    : null;

  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />

        <Col>
          <AsyncAutocomplete
            id={"credential-selectbox"}
            label={t("Credential")}
            value={credentialOption}
            required={required}
            onChange={async (_, selected) => {
              if (selected) {
                if (selected.showModal) {
                  return setShowModal(true);
                }
                selectCredential(selected);
              } else {
                selectCredential(null);
              }
            }}
            fetch={async () => {
              const creds = await fetchMyCredentials(credentialTemplate.id);
              const options = creds.map((cred) => {
                return { label: cred.name, data: cred };
              });
              return [
                ...options,
                ...[
                  // Add an item for the user to select to create a new credential
                  {
                    label: t("Add a new credential"),
                    data: { showModal: true },
                  },
                ],
              ];
            }}
          />
        </Col>
      </Row>
      <Divider mt={1} mb={1} />

      {
        <NewCredentialTemplateModal
          selectedTemplate={credentialTemplate}
          show={showModal}
          onSave={async (data: CreateCredentialProps) => {
            try {
              const cred = await createCredential(data);
              showSuccess(t("Created credential"));
              setShowModal(false);
              selectCredential(cred);
            } catch {
              showError(t("Error creating credential"));
            }
          }}
          onHide={() => setShowModal(false)}
        />
      }
    </>
  );
}
