import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Box, Form, EditButton, Tooltip } from "../common";
import { IScript } from "../../types";
import useAlert from "../../hooks/useAlert";
import useScriptApi from "../../api/scriptApi";
import ScriptFields from "./ScriptFields";

export default function ScriptForm({
  scriptId,
  script,
}: {
  scriptId: string;
  script: IScript;
}) {
  const [updatedScript, setUpdatedScript] = useState(script);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateScript } = useScriptApi();
  const { showSuccess, showError } = useAlert();
  const { t } = useTranslation("Script Form");

  const onSave = () => {
    setIsSubmitting(true);
    updateScript(scriptId, updatedScript)
      .then((response: IScript) => {
        setUpdatedScript(response);
        showSuccess(t("Updated script"));
        setIsSubmitting(false);
        setIsEditing(false);
      })
      .catch((error) => showError(t("Error updating script")));
  };

  return (
    <>
      <h3>
        {t("Script Info")}
        <Tooltip title="Edit Script">
          <EditButton onClick={() => setIsEditing(true)} />
        </Tooltip>
      </h3>
      {isEditing ? (
        <Form onSubmit={onSave}>
          <ScriptFields
            isEditing={isEditing}
            script={updatedScript}
            updateScript={setUpdatedScript}
          />
          <Box>
            <Button
              mr={1}
              type="submit"
              disabled={
                !updatedScript.gitBranch ||
                !updatedScript.gitRepository ||
                !updatedScript.containerImage ||
                !updatedScript.commands.length
              }
              isLoading={isSubmitting}
              onClick={() => onSave()}
            >
              {t("common:save")}
            </Button>
            <Button variant="outline" onClick={() => setIsEditing(false)}>
              {t("common:cancel")}
            </Button>
          </Box>
        </Form>
      ) : (
        <>
          <ScriptFields
            isEditing={isEditing}
            script={updatedScript}
            updateScript={setUpdatedScript}
          />
        </>
      )}
    </>
  );
}
