import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../common/Table";
import { IUser } from "../../types";
import UserTableRow from "./UserTableRow";

interface IProps {
  users: IUser[];
}

export default function UserTable({ users }: IProps) {
  const { t } = useTranslation("User Management");
  return (
    <Table>
      <thead>
        <tr>
          <th>{t("Name")}</th>
          <th>{t("Administrator")}</th>
          <th>{t("common:emailAddress")}</th>
          <th style={{ textAlign: "center" }}>{t("common:actions")}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <UserTableRow key={user.id} userData={user} />
        ))}
      </tbody>
    </Table>
  );
}
