import React, { useState, useEffect } from "react";
import { IRunStats } from "../../types";
import useJobsApi from "../../api/jobApi";
import { Box, Row, Col, Span, Spinner } from "../common";

export default function JobRunStats({ jobId }: { jobId: string }) {
  const [stats, setStats] = useState<IRunStats | null>(null);
  const { fetchJobRunStats } = useJobsApi();

  const fetchOnce = () => {
    fetchJobRunStats(jobId)
      .then((stats) => setStats(stats))
      .catch((error) => console.log(error));
  };

  useEffect(fetchOnce, []);

  return (
    <Box mt={3}>
      <h4>Run Stats</h4>
      <hr />
      {stats ? (
        <Row>
          <Col>
            <Box>
              <Span fontSize={3}>{stats.count} Total Runs</Span>
            </Box>
            <Box>
              <Span color="outline">
                <em>
                  ({stats.averageDuration}
                  <br />
                  average runtime)
                </em>
              </Span>
            </Box>
          </Col>
          <Col color="success" fontSize={3}>
            {Math.round(
              (stats.success /
                (stats.count - (stats.running + stats.canceled))) *
                100
            )}
            % Success Rate
          </Col>
          <Col color="danger" fontSize={3}>
            {stats.failed} Failed{" "}
          </Col>
          <Col color="warning" fontSize={3}>
            {stats.serverError} Server Errors
          </Col>
          <Col color="outline" fontSize={3}>
            {stats.waiting} Stalled{" "}
          </Col>
        </Row>
      ) : (
        <>
          <Span color="outline">loading stats</Span> <Spinner />
        </>
      )}
    </Box>
  );
}
