import React, { useEffect, useState } from "react";
import useDBConnection from "../../hooks/useDBConnection";
import {
  Button,
  CancelButton,
  Box,
  Form,
  AsyncAutocomplete,
  SaveButton,
  Link,
} from "../common";
import { useTranslation } from "react-i18next";
import { ICredentialTemplate, ICredential } from "../../types";
import { OptionType } from "../common/Autocomplete";
import useCredentialsApi, {
  CreateCredentialProps,
} from "../../api/credentialsApi";
import useAlert from "../../hooks/useAlert";
import NewCredentialTemplateModal from "../credentials/NewCredentialTemplateModal";

export default function DBConnectionForm() {
  const [isEditing, setIsEditing] = useState(false);
  const { dbConnection, setDbConnection } = useDBConnection();
  const [
    connectionTemplate,
    setConnectionTemplate,
  ] = useState<ICredentialTemplate | null>(null);
  const [
    selectedCredential,
    setSelectedCredential,
  ] = useState<ICredential | null>(null);
  const [connectionValue, setConnectionValue] = useState<OptionType | null>(
    dbConnection ? { label: dbConnection.name, data: dbConnection } : null
  );
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation("SQL Browser");
  const { showSuccess, showError } = useAlert();

  const {
    createCredential,
    fetchCredentialTemplates,
    fetchMyCredentials,
  } = useCredentialsApi();

  const fetchTemplate = async () => {
    if (!connectionTemplate) {
      const templates = await fetchCredentialTemplates({
        systemId: "DATABASE_CONNECTION",
      });
      setConnectionTemplate(templates[0]);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const handleSubmit = () => {
    if (selectedCredential) {
      setDbConnection(selectedCredential);
      setIsEditing(false);
    }
  };

  if (!connectionTemplate) {
    return null;
  }

  return (
    <Box>
      {!dbConnection || isEditing ? (
        <>
          <Form onSubmit={handleSubmit}>
            <Box mb={2}>
              <AsyncAutocomplete
                id="credential-selectbox"
                label={t("Select a database connection")}
                value={connectionValue}
                onChange={async (_, selected) => {
                  setConnectionValue(null);
                  if (selected) {
                    if (selected.showModal) {
                      await fetchTemplate();
                      return setShowModal(true);
                    }
                    setConnectionValue({
                      label: selected.name,
                      data: selected,
                    });
                    setSelectedCredential(selected);
                  } else {
                    setSelectedCredential(null);
                  }
                }}
                fetch={async () => {
                  const creds = await fetchMyCredentials(connectionTemplate.id);
                  const options = creds.map((cred) => {
                    return { label: cred.name, data: cred };
                  });
                  return [
                    ...options,
                    ...[
                      {
                        label: t("Add a database connection"),
                        data: { showModal: true },
                      },
                    ],
                  ];
                }}
              />
            </Box>
            <SaveButton
              disabled={!selectedCredential}
              label={t("Connect")}
              submitForm
            />
            {dbConnection && (
              <CancelButton onClick={() => setIsEditing(false)} />
            )}
          </Form>
          <Box mt={2}>
            <Link to="/app/credentials">{t("Manage credentials")} →</Link>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <h3>
              {t("Using connection", { connectionName: dbConnection?.name })}
            </h3>
          </Box>
          <Button variant="outline" onClick={() => setIsEditing(true)}>
            {t("Edit connection")}
          </Button>
        </>
      )}

      {connectionTemplate && (
        <NewCredentialTemplateModal
          selectedTemplate={connectionTemplate}
          show={showModal}
          onSave={async (data: CreateCredentialProps) => {
            try {
              const cred = await createCredential(data);
              showSuccess(t("Created database connection"));
              setShowModal(false);
              setDbConnection(cred);
              setIsEditing(false);
            } catch {
              showError(t("Error creating database connection"));
            }
          }}
          onHide={() => setShowModal(false)}
        />
      )}
    </Box>
  );
}
