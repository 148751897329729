import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router";

import useTagsApi from "../../api/tagsApi";
import { ITag } from "../../types";
import { AsyncAutocomplete, Box, Row, Tab, Tabs } from "../common";
import DashboardList from "./DashboardList";
import DashboardSchedule from "./DashboardSchedule";

function validateViewParam(param: string | null) {
  if (param === "scheduled" || param === "tagged") return param;
  return "scheduled";
}

export default function DashboardPage() {
  const query = new URLSearchParams(useLocation().search);
  const [selectedView, setSelectedView] = useState(
    validateViewParam(query.get("view"))
  );
  const [tagFilters, setTagFilters] = useState<ITag[]>([]);
  const { fetchTags } = useTagsApi();
  const { t } = useTranslation("Dashboard");
  const history = useHistory();

  useEffect(() => {
    fetchTags().then((tags) => {
      const filtered = tags.filter((tag) =>
        query.getAll("tags[]").includes(tag.id)
      );
      setTagFilters(filtered);
    });
  }, []);

  const updateQueryParams = (view: string, tags: ITag[]) => {
    const query = new URLSearchParams();
    query.set("view", view);
    tags.forEach((tag: ITag) => {
      query.append("tags[]", tag.id);
    });
    history.push({ search: `?${query}` });
  };

  return (
    <>
      <Row>
        <AsyncAutocomplete
          id="tag-filter"
          style={{ width: "50%" }}
          label={t("Filter by tag")}
          multiselect
          value={tagFilters.map((tag) => {
            return { label: tag.name, data: tag };
          })}
          fetch={async () => {
            const tags = await fetchTags();
            return tags.map((tag) => {
              return { label: tag.name, data: tag };
            });
          }}
          onChange={(_, selected) => {
            if (selected) {
              setTagFilters(selected);
              updateQueryParams(selectedView, selected);
            }
          }}
        />
      </Row>
      <Box>
        <Tabs
          value={selectedView}
          onChange={(_, selected) => {
            setSelectedView(selected);
            console.log(selected);
            updateQueryParams(selected, tagFilters);
          }}
        >
          <Tab label={t("Scheduled")} value="scheduled" />
          <Tab label={t("Tagged")} value="tagged" />
        </Tabs>
      </Box>
      <Box mt={4}>
        {selectedView === "scheduled" && (
          <DashboardSchedule tags={tagFilters} />
        )}
        {selectedView === "tagged" && <DashboardList tags={tagFilters} />}
      </Box>
    </>
  );
}
