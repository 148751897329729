import useRequest from "../hooks/useRequest";
import { IWorkflowRun } from "../types";

export default function useWorkflowRunsApi() {
  const { post } = useRequest();

  return {
    createWorkflowRun: async (workflowId: string) => {
      const { data } = await post<IWorkflowRun>(`/workflow_runs`, {
        workflowId,
      });
      return data;
    },

    cancelWorkflowRun: async (workflowRunId: string) => {
      const { data } = await post<string>(
        `/workflow_runs/${workflowRunId}/cancel`
      );
      return data;
    },

    retryWorkflowRun: async (runId: string) => {
      const { data } = await post<IWorkflowRun>(
        `/workflow_runs/${runId}/retry`
      );
      return data;
    },
  };
}
