import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  Chip,
  Button,
  SaveButton,
  CancelButton,
  Form,
  AsyncAutocomplete,
  Tooltip,
} from "../common";
import { ILink, ITag } from "../../types";
import useAlert from "../../hooks/useAlert";
import { AddButton } from "../common/Button";

export default function TagForm({
  tags,
  fetchTags,
  onSubmit,
  onRemove,
}: {
  tags: ILink[];
  fetchTags: () => Promise<ITag[]>;
  onSubmit: (tag: string, isCreating: boolean) => void;
  onRemove: (tagId: string, deleteTag: boolean) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [isNewTag, setIsNewTag] = useState(false);
  const { t } = useTranslation("Tag Form");
  const history = useHistory();
  const { showSuccess, showError, showWarning } = useAlert();

  const handleSubmit = async () => {
    if (selectedTag) {
      setIsSubmitting(true);
      try {
        await onSubmit(selectedTag, isNewTag);
        showSuccess(t("Added tag"));
        setSelectedTag(null);
        setIsAdding(false);
      } catch {
        const errorMsg = isNewTag
          ? t("Error creating tag")
          : t("Error updating tag");
        showError(errorMsg);
      }
      setIsSubmitting(false);
    } else {
      showError(t("Please select a tag"));
    }
  };

  const handleRemove = async (tagId: string) => {
    try {
      await onRemove(tagId, false);
      showWarning(t("Removed tag"));
    } catch {
      showError(t("Error removing tag"));
    }
  };

  const manageTags = () => {
    history.push("/app/tags");
  };

  return (
    <>
      <h3>
        {t("Tags")}
        <Tooltip title="Add Tag">
          <AddButton onClick={() => setIsAdding(true)} />
        </Tooltip>
      </h3>
      <Box>
        {tags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            onDelete={() => handleRemove(tag.id)}
          />
        ))}

        {isAdding ? (
          <Form onSubmit={handleSubmit}>
            <Box>
              <AsyncAutocomplete
                canCreateOptions
                id="tag-select"
                label={t("Select or create a tag")}
                fetch={async () => {
                  const tagOptions = await fetchTags();
                  return tagOptions.map((tag) => {
                    return { label: tag.name, data: tag };
                  });
                }}
                onChange={(newTag, selected: ITag) => {
                  setIsNewTag(!selected);
                  const tagValue = selected ? selected.id : newTag;
                  setSelectedTag(tagValue);
                }}
              />
            </Box>
            <Box>
              <SaveButton
                submitForm
                disabled={!selectedTag}
                isLoading={isSubmitting}
              />
              <CancelButton onClick={() => setIsAdding(false)} />
            </Box>
            <Box>
              <Button variant="link" onClick={manageTags}>
                {t("Manage tags")}
              </Button>
            </Box>
          </Form>
        ) : null}
      </Box>
    </>
  );
}
