import useRequest from "../hooks/useRequest";
import {
  IOptionParameter,
  IParameter,
  IPasswordParameter,
  ITextParameter,
} from "../types";

export default function useParametersApi() {
  const { post } = useRequest();
  return {
    updateParameter: async (id: string, update: IParameter) => {
      // this endpoint is only called from the standard
      // jobs page or from the credential management pages
      const { data } = await post<
        ITextParameter | IPasswordParameter | IOptionParameter
      >(`/parameters/${id}`, update);
      return data;
    },
  };
}
