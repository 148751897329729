import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import { IChildProps, IChildrenProps } from "../../types";

export function ModalFooter({ children }: IChildrenProps) {
  return <MuiDialogActions>{children}</MuiDialogActions>;
}

export function ModalBody({ children }: IChildrenProps) {
  return <MuiDialogContent>{children}</MuiDialogContent>;
}

export function ModalTitle({ children }: IChildProps) {
  return <h3>{children}</h3>;
}

const CloseButton = styled(IconButton)`
  font-size: 1rem;
  position: absolute;
  top: 4px;
  right: 8px;
  color: ${({ theme }) => theme.colors.outline};
`;

export function ModalHeader({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <MuiDialogTitle disableTypography>
      {children}
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          X
        </CloseButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const StyledModal = styled(Dialog)`
  font-family: ${({ theme }) => theme.fonts.main};
`;

interface IModalProps {
  children: React.ReactNode | React.ReactNodeArray;
  show: boolean;
  onHide?: () => void;
  centered?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | undefined;
}

export default function TMCModal({
  children,
  show,
  onHide,
  centered,
  size,
}: IModalProps) {
  return (
    <StyledModal open={show} onClose={onHide} fullWidth maxWidth={size}>
      {children}
    </StyledModal>
  );
}
