import React from "react";
import Button from "../common/Button";
import firebase from "firebase/app";
import "firebase/auth";
import styled from "styled-components";

import LoginSplashIcon from "./LoginSplashIcon";
import LoginPrompt from "./LoginPrompt";

interface IProps {
  firebaseUser: firebase.User | null;
  logoutUser: () => void;
}

const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  text-align: center;
`;

export default function LoginPage({ firebaseUser, logoutUser }: IProps) {
  return (
    <Page>
      <LoginSplashIcon />
      {firebaseUser !== null ? (
        <div>
          Invalid login.
          <Button onClick={logoutUser}>Try Again</Button>
        </div>
      ) : (
        <LoginPrompt />
      )}
    </Page>
  );
}
