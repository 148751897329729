import {
  DEFAULT_DATE_FORMAT,
  formatDateTime,
  getDayOfMonth,
  getDayOfWeek,
  getLocalTimezone,
} from "../../time";

export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const HELPER_TIME_FORMAT = "h:mma";
export const DEFAULT_LAST_DAY_SELECTED = false;
export const DEFAULT_INTERVAL = "";
export const DEFAULT_SHORT_INTERVAL = 5;
export const DEFAULT_SHORT_INTERVAL_TYPE = "minutes";

type TIntervals = "" | "daily" | "weekly" | "monthly" | "short";

interface ICron {
  localDateTime: Date;
  interval: TIntervals;
  lastDayOfMonthSelected?: boolean;
  shortInterval?: number | null;
  shortIntervalType?: string;
}

export function formatCron({
  localDateTime,
  interval,
  lastDayOfMonthSelected,
  shortInterval,
  shortIntervalType,
}: ICron) {
  if (interval === "short")
    return `every ${shortInterval} ${shortIntervalType}`;

  let dailyInterval = "";
  if (interval === "daily") dailyInterval = "Daily";
  if (interval === "weekly") {
    dailyInterval = `every ${getDayOfWeek(localDateTime)}`;
  }
  if (interval === "monthly") {
    const dayOfMonth = lastDayOfMonthSelected
      ? "last day"
      : getDayOfMonth(localDateTime);
    dailyInterval = `on the ${dayOfMonth} of every month`;
  }
  const tz = getLocalTimezone(localDateTime);
  const dateString = dailyInterval
    ? `${tz}, ${dailyInterval}`
    : `${tz} on ${formatDateTime(localDateTime, DEFAULT_DATE_FORMAT)}`;
  return `${formatDateTime(localDateTime, HELPER_TIME_FORMAT)} ${dateString}`;
}

export function generateCron(
  selectedDateTime: Date,
  repeatInterval: TIntervals,
  lastDayOfMonthSelected?: boolean,
  shortInterval?: number | null,
  shortIntervalType?: string
) {
  let cronTime = `${selectedDateTime.getUTCMinutes()} ${selectedDateTime.getUTCHours()}`;

  if (!repeatInterval) {
    const UTCDate = selectedDateTime.getUTCDate();
    const UTCMonth = selectedDateTime.getUTCMonth() + 1;
    const UTCYear = selectedDateTime.getUTCFullYear();
    return `0 ${cronTime} ${UTCDate} ${UTCMonth} ? ${UTCYear}`;
  }

  let cronInterval = "* * *";

  if (repeatInterval === "weekly")
    cronInterval = `? * ${selectedDateTime.getDay() + 1}`;
  if (repeatInterval === "monthly") {
    const dateOfMonth = lastDayOfMonthSelected
      ? "L"
      : selectedDateTime.getUTCDate();
    cronInterval = `${dateOfMonth} * ?`;
  }
  if (repeatInterval === "short") {
    if (shortIntervalType === "minutes") cronTime = `*/${shortInterval} *`;
    if (shortIntervalType === "hours") cronTime = `0 */${shortInterval}`;
  }
  return `${cronTime} ${cronInterval}`;
}

export function parseCron(cron: string): ICron {
  const cronSplit = cron.split(" ");
  const isRepeating = cronSplit.length <= 5;
  const cronMinutes = cronSplit[isRepeating ? 0 : 1];
  const cronHours = cronSplit[isRepeating ? 1 : 2];
  const cronDate = cronSplit[isRepeating ? 2 : 3];
  const cronMonth = cronSplit[isRepeating ? 3 : 4];
  const hasMinutesInterval = cronMinutes.search("/") !== -1;
  const hasHoursInterval = cronHours.search("/") !== -1;
  const hasDateTime = !hasMinutesInterval && !hasHoursInterval;

  const getLocalDateTime = () => {
    const d = new Date();
    return new Date(
      Date.UTC(
        !isRepeating ? parseInt(cronSplit[6]) : d.getFullYear(),
        !isNaN(parseInt(cronMonth)) ? parseInt(cronMonth) - 1 : d.getMonth(),
        !isNaN(parseInt(cronDate)) ? parseInt(cronDate) : d.getDate(),
        parseInt(cronHours),
        parseInt(cronMinutes)
      )
    );
  };

  const getInterval = () => {
    if (isRepeating && !hasDateTime) return "short";
    if (isRepeating) {
      if (cronDate === "?") return "weekly";
      if (cronDate === "L" || !isNaN(parseInt(cronDate))) return "monthly";
      return "daily";
    }
    return DEFAULT_INTERVAL;
  };

  const getshortInterval = () => {
    if (hasMinutesInterval) return parseInt(cronMinutes.split("/")[1]);
    if (hasHoursInterval) return parseInt(cronHours.split("/")[1]);
    return DEFAULT_SHORT_INTERVAL;
  };

  return {
    localDateTime: hasDateTime ? getLocalDateTime() : new Date(),
    interval: getInterval(),
    lastDayOfMonthSelected: cronDate === "L",
    shortInterval: !hasDateTime ? getshortInterval() : 5,
    shortIntervalType:
      !hasDateTime && hasHoursInterval ? "hours" : DEFAULT_SHORT_INTERVAL_TYPE,
  };
}
