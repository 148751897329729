import React, { useState, useEffect } from "react";

import Modal from "../common/Modal";
import { ICredentialTemplate, TUpsertCredParameter } from "../../types";
import { CreateCredentialProps } from "../../api/credentialsApi";
import NewCredentialDetailsForm from "./NewCredentialDetailsForm";
import NewCredentialTypeForm from "./NewCredentialTypeForm";
import NewCredentialParameterForm from "./NewCredentialParameterForm";
import NewCredentialTemplateForm from "./NewCredentialTemplateForm";

const defaultCredential = {
  name: "",
  description: "",
  shared: false,
};

const defaultStep = "details";

export default function NewCredentialModal({
  show,
  onHide,
  onSave,
}: {
  show: boolean;
  onHide: () => void;
  onSave: (v: CreateCredentialProps) => void;
}) {
  const [newCredential, setNewCredential] = useState<CreateCredentialProps>(
    defaultCredential
  );
  const [step, setStep] = useState(defaultStep);
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<ICredentialTemplate | null>(null);

  useEffect(() => {
    if (show) {
      setNewCredential(defaultCredential);
      setStep(defaultStep);
    }
  }, [show]);

  const handleSteps = () => {
    switch (step) {
      case "details":
        return (
          <NewCredentialDetailsForm
            newCredential={newCredential}
            onHide={onHide}
            onSave={(name: string, description: string, shared: boolean) => {
              setNewCredential({
                ...newCredential,
                name,
                description,
                shared,
              });
              setStep("select-type");
            }}
          />
        );
      case "select-type":
        return (
          <NewCredentialTypeForm
            onHide={onHide}
            navigate={setStep}
            onSelectTemplate={setSelectedTemplate}
          />
        );
      case "generic":
        return (
          <NewCredentialParameterForm
            navigate={setStep}
            onHide={onHide}
            onFinish={async (parameters: TUpsertCredParameter[]) => {
              await onSave({
                ...newCredential,
                parameters,
              });
            }}
          />
        );
      case "template":
        return selectedTemplate ? (
          <NewCredentialTemplateForm
            template={selectedTemplate}
            stepBack={() => setStep("select-type")}
            onHide={onHide}
            onFinish={async (parameters: TUpsertCredParameter[]) => {
              await onSave({
                ...newCredential,
                templateId: selectedTemplate.id,
                parameters,
              });
            }}
          />
        ) : null;
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      {handleSteps()}
    </Modal>
  );
}
