import React from "react";
import styled from "styled-components";

interface IProps {
  size?: number;
}

const IconDiv = styled.div`
  height: 40vmin;
  pointer-events: none;
`;

export default function ParsonsIcon({ size = 192 }: IProps) {
  const logoUrl = `/static/react/logo${size}.png`;
  return (
    <IconDiv>
      <img src={logoUrl} alt="Parsons" />
    </IconDiv>
  );
}
