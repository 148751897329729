import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IParameterDefinition,
  TCreateParameterDefinition,
} from "../../../types/ParameterDefinitions";

import { Autocomplete, Box } from "../../common";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../common/Modal";
import {
  AddCredentialParamDef,
  EditCredentialParamDef,
} from "./CredentialParamDefForms";
import { AddTextParamDef, EditTextParamDef } from "./TextParamDefForms";
import {
  AddPasswordParamDef,
  EditPasswordParamDef,
} from "./PasswordParamDefForms";
import {
  AddDbQueryParamDef,
  EditDbQueryParamDef,
} from "./DbQueryParamDefForms";
import { AddJSONParamDef, EditJSONParamDef } from "./JSONParamDefForms";
import { AddOptionParamDef, EditOptionParamDef } from "./OptionParamDefForms";

/**
 * Components for rendering parameter definitions create & edit modals.
 */
export function AddParamDefModal({
  onSave,
  onClose,
}: {
  onSave: (p: TCreateParameterDefinition) => Promise<void>;
  onClose: () => void;
}) {
  const [selectedParamDefType, setSelectedParamDefType] = useState("text");

  const { t } = useTranslation("App Page");

  // Map used to generate param def list options in menu and render corresponding form when option is selected
  const paramDefCreators = new Map([
    [
      "text",
      {
        label: t("common:text"),
        component: AddTextParamDef,
      },
    ],
    [
      "password",
      {
        label: t("common:password"),
        component: AddPasswordParamDef,
      },
    ],
    [
      "credential",
      {
        label: t("common:credential"),
        component: AddCredentialParamDef,
      },
    ],
    [
      "db_query",
      {
        label: t("Database Query"),
        component: AddDbQueryParamDef,
      },
    ],
    [
      "json",
      {
        label: t("JSON"),
        component: AddJSONParamDef,
      },
    ],
    [
      "option",
      {
        label: t("Single Select"),
        component: AddOptionParamDef,
      },
    ],
  ]);

  const AddParamDefForm = paramDefCreators.get(selectedParamDefType)?.component;

  return (
    <Modal show={true} onHide={onClose}>
      <ModalHeader onClose={onClose}>
        <ModalTitle>{t("Configure parameter")}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <Box ml={2} mr={2} mb={4}>
          <Autocomplete
            id="param-def-selectbox"
            label={t("Select Parameter Type")}
            filterSelectedOptions={false}
            value={{
              label: paramDefCreators.get(selectedParamDefType)?.label || "",
              data: selectedParamDefType,
            }}
            options={[...paramDefCreators].map(([type, item]) => {
              return { label: item.label, data: type };
            })}
            onChange={(_, type) => {
              if (type) setSelectedParamDefType(type);
            }}
          />

          {AddParamDefForm && (
            <AddParamDefForm
              onSave={async (paramDef: TCreateParameterDefinition) => {
                await onSave(paramDef);
                setSelectedParamDefType("text");
              }}
              onClose={onClose}
            />
          )}
        </Box>
      </ModalBody>
      {/* TODO: Use Modal Footer for Save/Cancel buttons */}
    </Modal>
  );
}

// map for generating Edit UI for individual param definitions
const paramDefEditors = new Map([
  ["text", EditTextParamDef],
  ["password", EditPasswordParamDef],
  ["credential", EditCredentialParamDef],
  ["db_query", EditDbQueryParamDef],
  ["json", EditJSONParamDef],
  ["option", EditOptionParamDef],
]);

export function EditParamDefModal({
  paramDef,
  onUpdate,
  onClose,
}: {
  paramDef: IParameterDefinition;
  onUpdate: (p: IParameterDefinition) => Promise<void>;
  onClose: () => void;
}) {
  const { t } = useTranslation("App Page");

  const Editor = paramDefEditors.get(paramDef.type);

  return Editor ? (
    <Modal show={Boolean(Editor)} onHide={onClose}>
      <ModalHeader onClose={onClose}>
        <ModalTitle>{t("Edit parameter")}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <Box ml={2} mr={2} mb={4}>
          <Editor paramDef={paramDef} onUpdate={onUpdate} onCancel={onClose} />
        </Box>
      </ModalBody>
      {/* TODO: Use Modal Footer for Save/Cancel buttons */}
    </Modal>
  ) : null;
}
