import React from "react";
import Editor from "react-simple-code-editor";
import { Light as Highlighter } from "react-syntax-highlighter";
import sql from "react-syntax-highlighter/dist/esm/languages/hljs/sql";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";

Highlighter.registerLanguage("sql", sql);

export default function ({
  value,
  onChangeValue,
}: {
  value: string;
  onChangeValue: (v: string) => void;
}) {
  return (
    <Editor
      value={value}
      onValueChange={onChangeValue}
      padding={10}
      style={{
        background: "rgb(248, 248, 255) none repeat scroll 0% 0%",
        fontFamily:
          "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
        minHeight: "300px",
      }}
      highlight={(code) => (
        <Highlighter
          language="sql"
          style={docco}
          customStyle={{
            background: "none",
            padding: 0,
            margin: 0,
            fontSize: "inherit",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {code}
        </Highlighter>
      )}
    />
  );
}
