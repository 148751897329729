import React, { createContext, useState, ReactNode } from "react";
import { IDBConnection } from "../types";

export const DBContext = createContext<{
  credential: IDBConnection | null;
  setCredential: (c: IDBConnection | null) => void;
}>({
  credential: null,
  setCredential: (c) => {
    return;
  },
});

export function DBContextProvider({ children }: { children: ReactNode }) {
  const conn = localStorage.getItem("dbConnection");
  const [credential, setCredential] = useState<IDBConnection | null>(
    conn ? JSON.parse(conn) : null
  );

  return (
    <DBContext.Provider value={{ credential, setCredential }}>
      {children}
    </DBContext.Provider>
  );
}
