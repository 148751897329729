import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";

const StyledLabel = styled(FormControlLabel)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.primary};
  }
  .MuiTypography-body1 {
    font-family: ${({ theme }) => theme.fonts.main};
  }
`;

export default function TMCCheckbox({
  checked,
  label,
  onChange,
  disabled,
}: {
  checked?: boolean;
  label?: string;
  onChange?: (c: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <StyledLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(e.target.checked);
          }}
          disabled={disabled}
          name={label}
        />
      }
      label={label}
    />
  );
}
