import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Loading, Table, Span } from "../common";
import { useTranslation } from "react-i18next";

export default function QueryResultsTable({
  isSubmitting,
  querySuccessful,
  rows,
}: {
  isSubmitting: boolean;
  querySuccessful: boolean;
  rows: object[];
}) {
  const { t } = useTranslation("SQL Browser");

  return (
    <Box mt={3}>
      {isSubmitting && <Loading />}
      {querySuccessful && rows.length > 0 && (
        <>
          <h2>Query Results Preview</h2>
          <Box
            mt={3}
            style={{
              overflow: "scroll",
              border: "solid 1px lightgray",
            }}
          >
            <Table dense>
              <thead>
                <tr>
                  {Object.keys(rows[0]).map((k) => (
                    <th key={uuidv4()}>{k}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={uuidv4()}>
                    {Object.values(row).map((val) => (
                      <td key={uuidv4()}>{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </>
      )}
      {querySuccessful && !rows.length && (
        <Box>
          <Span color="outline">{t("No rows returned")}</Span>
        </Box>
      )}
    </Box>
  );
}
