import React from "react";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useAlert from "../../hooks/useAlert";

const TMCAlert = styled(Alert)`
  font-family: ${({ theme }) => theme.fonts.main};

  background-color: ${(props) => {
    if (props.severity === "success") return props.theme.colors.success;
    if (props.severity === "warning") return props.theme.colors.warning;
    if (props.severity === "error") return props.theme.colors.danger;
  }};
`;

export default function GlobalAlert() {
  const { alertState, clearAlert } = useAlert();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.visible}
      autoHideDuration={5000}
      onClose={clearAlert}
    >
      {alertState.visible ? (
        <TMCAlert
          variant="filled"
          severity={alertState.alertType}
          onClose={clearAlert}
        >
          {alertState.message}
        </TMCAlert>
      ) : undefined}
    </Snackbar>
  );
}
