import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../common/Modal";
import { SaveButton, CancelButton, Form, TextField } from "../common";
import { ITag } from "../../types";
import { UpsertTagProps } from "../../api/tagsApi";

export default function TagModal({
  tag,
  show,
  onHide,
  onSave,
}: {
  tag: ITag | null;
  show: boolean;
  onHide: () => void;
  onSave: (v: UpsertTagProps) => void;
}) {
  const [tagName, setTagName] = useState(tag ? tag.name : "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("Tags Page");

  useEffect(() => {
    const resetTagName = !show || !tag ? "" : tag.name;
    setTagName(resetTagName);
    setIsSubmitting(false);
  }, [show, tag]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSave({
      name: tagName,
    });
    setIsSubmitting(false);
  };

  return (
    <Modal show={show}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>
          <ModalTitle>{tag ? t("Update tag") : t("Add a new tag")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <TextField
            id="tag-name-input"
            label={t("common:name")}
            value={tagName}
            required
            onChange={setTagName}
          />
        </ModalBody>
        <ModalFooter>
          <SaveButton disabled={!tagName} isLoading={isSubmitting} submitForm />
          <CancelButton onClick={onHide} />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
