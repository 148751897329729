import React, { useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import styled from "styled-components";
import { space, color } from "styled-system";
import Container, { SidebarContainer } from "./common/Container";
import Alert from "./common/Alert";
import { IUser } from "../types";
import ParsonsNavBar from "./nav/ParsonsNavBar";
import UserManagementPageContainer from "./users/UserManagementPageContainer";
import NewJobPage from "./jobs/NewJobPage";
import JobPage from "./jobs/JobPage";
import JobsList from "./jobs/JobsList";
import LogPage from "./logs/LogPage";
import CredentialsPage from "./credentials/CredentialsPage";
import TagsPage from "./tags/TagsPage";
import SQLBrowserPage from "./sql/SQLBrowserPage";
import NewWorkflowPage from "./workflows/NewWorkflowPage";
import WorkflowPage from "./workflows/WorkflowPage";
import WorkflowList from "./workflows/WorkflowList";
import SecondaryNav from "./nav/SecondaryNav";
import { ResourceContextProvider } from "../contexts/resourceContext";
import AppPage from "./apps/AppPage";
import AppsList from "./apps/AppList";
import NewAppPage from "./apps/NewAppPage";
import DashboardPage from "./dashboard/DashboardPage";
import { JobContextProvider } from "../contexts/jobContext";
import ReportsPage from "./reports/ReportsPage";

interface IProps {
  logoutUser: () => void;
  user: IUser;
}

const AppBody = styled.div<{ className: string; bg: string; pb: number }>`
  ${space}
  ${color}
`;

export default function AppRouter({ logoutUser, user }: IProps) {
  const [showSecondaryNav, setShowSecondaryNav] = useState(false);

  return (
    <AppBody className="app-body" bg="background" pb={4}>
      <HashRouter basename="/">
        <ParsonsNavBar
          logoutUser={logoutUser}
          user={user}
          onToggleSecondaryNav={() => setShowSecondaryNav(!showSecondaryNav)}
        />
        <Alert />
        <SecondaryNav
          open={showSecondaryNav}
          onHide={() => setShowSecondaryNav(false)}
        />
        <Switch>
          <Route exact path="/">
            <Redirect to="/app/dashboard" />
          </Route>
          <Route path="/app">
            <Container>
              <Route exact path="/app/dashboard" component={DashboardPage} />
              <Route exact path="/app/jobs" component={JobsList} />
              <Route
                exact
                path="/app/users"
                component={UserManagementPageContainer}
              />
              <Route exact path="/app/addJob">
                <NewJobPage user={user} />
              </Route>
              <Route
                path="/app/jobs/:jobId/:runId?"
                render={(props) => (
                  <ResourceContextProvider>
                    <JobContextProvider>
                      <JobPage {...props} />
                    </JobContextProvider>
                  </ResourceContextProvider>
                )}
              />
              <Route
                exact
                path="/app/addWorkflow"
                component={NewWorkflowPage}
              />
              <Route
                exact
                path="/app/workflows/:workflowId"
                render={(props) => (
                  <ResourceContextProvider>
                    <WorkflowPage {...props} />
                  </ResourceContextProvider>
                )}
              />
              <Route exact path="/app/workflows" component={WorkflowList} />
              <Route path="/app/logs/:runId" component={LogPage} />
              <Route path="/app/credentials" component={CredentialsPage} />
              <Route path="/app/tags" component={TagsPage} />
              <Route path="/app/apps" exact component={AppsList} />
              <Route path="/app/apps/:appId" exact component={AppPage} />
              <Route path="/app/addApp" exact component={NewAppPage} />
              <Route path="/app/reports" exact component={ReportsPage} />
            </Container>
          </Route>
          <Route path="/data">
            <SidebarContainer>
              <Route
                path="/data/browser/:queryId?"
                exact
                component={SQLBrowserPage}
              />
            </SidebarContainer>
          </Route>
        </Switch>
      </HashRouter>
    </AppBody>
  );
}
