import React from "react";
import { useTranslation } from "react-i18next";

import { IParameterDefinition } from "../../../types/ParameterDefinitions";
import { Span, Chip, Col, Tooltip } from "../../common";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LockIcon from "@material-ui/icons/Lock";
import StorageIcon from "@material-ui/icons/Storage";
import Badge from "../../common/Badge";
import CodeIcon from "@material-ui/icons/Code";
import ListIcon from "@material-ui/icons/List";
import InfoIcon from "@material-ui/icons/Info";

export function ParamDefTypeLabel({
  paramDef,
}: {
  paramDef: IParameterDefinition;
}) {
  const { t } = useTranslation();

  switch (paramDef.type) {
    case "text":
      return <Chip icon={<TextFieldsIcon />} label={t("common:text")} />;
    case "password":
      return <Chip icon={<VpnKeyIcon />} label={t("common:password")} />;
    case "credential":
      return <Chip icon={<LockIcon />} label={t("common:credential")} />;
    case "db_query":
      return <Chip icon={<StorageIcon />} label={t("common:databaseQuery")} />;
    case "json":
      return <Chip icon={<CodeIcon />} label={t("common:json")} />;
    case "option":
      return <Chip icon={<ListIcon />} label={t("common:Single Select")} />;
    default:
      return null;
  }
}

export default function ParamDefListItemLabel({
  paramDef,
}: {
  paramDef: IParameterDefinition;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Col>
        <Span mr={2}>
          <strong>{paramDef.label}</strong>
          {paramDef.description && (
            <Span color="outline">
              <Tooltip
                title={
                  <Span fontSize="1.5em" fontFamily="main">
                    {paramDef.description}
                  </Span>
                }
              >
                <InfoIcon />
              </Tooltip>
            </Span>
          )}
        </Span>{" "}
        {paramDef.required && (
          <Badge variant="danger">{t("common:required")}</Badge>
        )}
      </Col>
      <Col>
        <ParamDefTypeLabel paramDef={paramDef} />
      </Col>
    </>
  );
}
