import React, { useReducer } from "react";

type AlertAction =
  | {
      type: "error";
      message: string;
    }
  | {
      type: "success";
      message: string;
    }
  | {
      type: "warning";
      message: string;
    }
  | { type: "none" };

interface IState {
  visible: boolean;
  message: string | undefined;
  alertType?: "success" | "error" | "warning" | "info" | undefined;
}

type AlertContextValue = {
  state: IState;
  dispatch: React.Dispatch<AlertAction>;
};

const defaultState = {
  visible: false,
  message: undefined,
};

const alertReducer = (_: IState, action: AlertAction): IState => {
  switch (action.type) {
    case "none":
      return defaultState;
    case "error":
      return {
        visible: true,
        alertType: "error",
        message: action.message,
      };
    case "success":
      return {
        visible: true,
        alertType: "success",
        message: action.message,
      };
    case "warning":
      return {
        visible: true,
        alertType: "warning",
        message: action.message,
      };
    default:
      return defaultState;
  }
};

export const AlertContext = React.createContext<AlertContextValue>({
  state: defaultState,
  dispatch: () => {
    return;
  },
});

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<React.Reducer<IState, AlertAction>>(
    alertReducer,
    defaultState
  );

  return (
    <AlertContext.Provider value={{ state, dispatch }}>
      {children}
    </AlertContext.Provider>
  );
}
