import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Form,
  TextField,
  Button,
  SaveButton,
  Box,
  ClearButton,
  CancelButton,
  AsyncAutocomplete,
  List,
  ListItem,
} from "../common";
import useWorkflowApi from "../../api/workflowApi";
import useAlert from "../../hooks/useAlert";
import useJobsApi from "../../api/jobApi";
import { IJob } from "../../types";

export default function WorkflowPage() {
  const [isAddingStep, setIsAddingStep] = useState(false);
  const [name, setName] = useState("");
  const [stepMap, setStepMap] = useState(new Map());
  const [selectedJob, setSelectedJob] = useState<IJob | null>(null);
  const { createWorkflow } = useWorkflowApi();
  const { fetchJobs } = useJobsApi();
  const history = useHistory();
  const { t } = useTranslation("New Workflow Page");
  const { showError } = useAlert();

  const handleSubmit = async () => {
    try {
      const steps = [...stepMap.values()].map((step, index, arr) => {
        return {
          prev: index ? arr[index - 1].job.id : null,
          statusIn: [2],
          next: step.job.id,
        };
      });
      const workflow = await createWorkflow(name, steps);
      history.push(`/app/workflows/${workflow.id}`);
    } catch {
      showError(t("Error creating workflow"));
    }
  };

  return (
    <Box style={{ width: "auto", maxWidth: "640px" }}>
      <h1>{t("Create Workflow")}</h1>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Box>
          <TextField
            id="workflow-name-input"
            label={t("common:name")}
            onChange={setName}
          />
        </Box>
        <Box mt={3}>
          <h3>{t("Steps")}</h3>
          {stepMap.size > 0 && (
            <List style={{ width: "75%" }}>
              {[...stepMap.values()].map((step) => (
                <ListItem
                  key={step.job.id}
                  label={step.job.name}
                  actions={
                    <ClearButton
                      onClick={() => {
                        stepMap.delete(step.job.id);
                        setStepMap(new Map(stepMap));
                      }}
                    />
                  }
                />
              ))}
            </List>
          )}
        </Box>
        {isAddingStep ? (
          <Box mt={3}>
            <Box style={{ width: "75%" }}>
              <AsyncAutocomplete
                id="job-select"
                label={t("Select a job")}
                fetch={async () => {
                  const { jobs } = await fetchJobs();
                  return jobs.map((job) => {
                    return { label: job.name, data: job };
                  });
                }}
                onChange={(_, selected: IJob) => setSelectedJob(selected)}
              />
            </Box>
            <Button
              disabled={!selectedJob}
              onClick={() => {
                if (selectedJob) {
                  stepMap.set(selectedJob.id, { job: selectedJob });
                  setStepMap(new Map(stepMap));
                  setIsAddingStep(false);
                  setSelectedJob(null);
                }
              }}
            >
              {t("Save step")}
            </Button>
            <CancelButton onClick={() => setIsAddingStep(false)} />
          </Box>
        ) : (
          <Button variant="outline" onClick={() => setIsAddingStep(true)}>
            {t("Add step")}
          </Button>
        )}

        <hr />
        <SaveButton submitForm disabled={!name || stepMap.size === 0} />
      </Form>
    </Box>
  );
}
