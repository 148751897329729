import React from "react";

import { Box, Row, Col } from "../common";

import { IScriptJob } from "../../types";
import JobParameterForm from "./JobParameterForm";
import SchedulerForm from "../scheduler/SchedulerForm";
import ScriptForm from "./ScriptForm";
import CredentialsForm from "./CredentialsForm";
import TagForm from "../tags/TagForm";
import NotificationForm from "../notifications/NotificationForm";
import useJobsApi from "../../api/jobApi";
import useTagsApi from "../../api/tagsApi";
import useNotificationsApi, {
  UpsertNotificationProps,
} from "../../api/notificationsApi";
import useJob from "../../hooks/useJob";

export default function JobDetail() {
  const { job, refresh } = useJob();
  const { addJobTag, removeJobTag, updateJobSchedule } = useJobsApi();
  const { createTag, fetchTags } = useTagsApi();
  const {
    createNotification,
    removeNotification,
    updateNotification,
  } = useNotificationsApi();

  if (!job) return null;

  return (
    <section>
      <Row style={{ minHeight: "16rem" }}>
        <Col md={6} xs={12}>
          <Box mb={4}>
            {job?.script && (
              <ScriptForm scriptId={job.script.id} script={job.script} />
            )}
          </Box>
        </Col>
        <Col pl={2} md={6} xs={12}>
          <Box mb={4}>
            <TagForm
              tags={job.tags}
              fetchTags={async () => {
                return await fetchTags({
                  exclude: job.tags.map((tag) => tag.id),
                });
              }}
              onSubmit={async (tag, isCreating) => {
                const result = isCreating
                  ? await createTag({ name: tag, job_id: job.id })
                  : await addJobTag(job.id, tag);
                if (result) refresh();
              }}
              onRemove={async (tagId, deleteTag) => {
                await removeJobTag(job.id, tagId, { deleteTag });
                refresh();
              }}
            />
          </Box>
          <Box></Box>
          <Box mb={4}>
            <SchedulerForm
              isJob={true}
              cronLine={job.cronSchedule}
              cronScheduleEnd={job.cronScheduleEnd}
              durationLimit={job.durationLimit || undefined}
              onSubmit={async (
                cronSchedule: string,
                expiration?: string,
                durationLimit?: number
              ) => {
                const update = await updateJobSchedule(
                  job.id,
                  cronSchedule,
                  expiration,
                  durationLimit
                );
                refresh();
                return update;
              }}
              onRemove={() => updateJobSchedule(job.id, "")}
            />
          </Box>
          <Box>
            <NotificationForm
              notifications={job.notifications}
              onSave={async (v: UpsertNotificationProps) => {
                const props = { ...v, jobId: job.id };
                const upsert = v.id ? updateNotification : createNotification;
                await upsert(props);
                refresh();
                return null;
              }}
              onRemove={async (notificationId: string) => {
                await removeNotification(notificationId);
                refresh();
                return null;
              }}
            />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <CredentialsForm jobId={job.id} credentials={job.credentials} />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <JobParameterForm />
          </Box>
        </Col>
      </Row>
    </section>
  );
}
