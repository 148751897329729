import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Table, Row, Col, Loading } from "../common";

import NewCredentialModalForm from "./NewCredentialModalForm";
import EditCredentialModalForm from "./EditCredentialModalForm";
import CredentialRow from "./CredentialRow";
import useApi, {
  UpsertCredentialProps,
  CreateCredentialProps,
} from "../../api/credentialsApi";
import { ICredential } from "../../types";
import useAlert from "../../hooks/useAlert";

export default function CredentialsPage() {
  const [credentials, setCredentials] = useState<ICredential[] | null>(null);
  const [
    selectedCredential,
    setSelectedCredential,
  ] = useState<ICredential | null>(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const {
    createCredential,
    updateCredential,
    removeCredential,
    fetchMyCredentials,
  } = useApi();
  const { showSuccess, showError, showWarning } = useAlert();
  const { t } = useTranslation("Credentials Page");

  const refresh = async () => {
    try {
      const updatedCreds = await fetchMyCredentials();
      setCredentials(updatedCreds);
    } catch {
      showError(t("Error fetching credentials"));
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleRemove = async (id: string) => {
    try {
      await removeCredential(id);
      refresh();
      showWarning(t("Removing credential"));
    } catch {
      showError(t("Error removing credential"));
    }
  };

  const handleCreate = async (data: CreateCredentialProps) => {
    try {
      await createCredential(data);
      refresh();
      showSuccess(t("Created credential"));
      setShowCreateModal(false);
    } catch {
      showError(t("Error creating credential"));
    }
  };

  const handleEdit = async (data: UpsertCredentialProps) => {
    try {
      await updateCredential(data);
      refresh();
      showSuccess(t("Updated credential"));
      return setShowEditModal(false);
    } catch {
      return showError(t("Error updating credential"));
    }
  };

  return (
    <>
      <Row centerVertically>
        <Col>
          <h1>{t("Credentials")}</h1>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            size="lg"
            onClick={() => {
              setSelectedCredential(null);
              setShowCreateModal(true);
            }}
          >
            {t("Add Credential")}
          </Button>
        </Col>
      </Row>

      {credentials ? (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>{t("Credential")}</th>
              <th>{t("common:Created By")}</th>
              <th>{t("common:shared")}</th>
              <th style={{ textAlign: "center" }}>{t("common:actions")}</th>
            </tr>
          </thead>
          <tbody>
            {credentials.map((credential) => (
              <CredentialRow
                key={credential.id}
                credential={credential}
                onEdit={() => {
                  setSelectedCredential(credential);
                  setShowEditModal(true);
                }}
                onSave={handleEdit}
                onRemove={() => handleRemove(credential.id)}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <Loading />
      )}

      <NewCredentialModalForm
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onSave={handleCreate}
      />

      {selectedCredential && (
        <EditCredentialModalForm
          credential={selectedCredential}
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          onSave={handleEdit}
        />
      )}
    </>
  );
}
