import React from "react";
import { IParameterDefinition } from "../../types";
import { IUpsertJSONParam } from "../../types/Parameters";
import ParamDefListItemLabel from "../apps/parameterDefinitions/ParamDefListItemLabel";
import { Box, Divider, Row } from "../common";
import JSONCodeEditor from "../editors/JSONCodeEditor";

export default function ({
  parameter,
  paramDef,
  onChange,
}: {
  parameter: IUpsertJSONParam;
  paramDef: IParameterDefinition;
  onChange: (v: string) => void;
}) {
  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />
      </Row>
      <Box mb={3}>
        <JSONCodeEditor value={parameter.value} onChangeValue={onChange} />
      </Box>
      <Divider mt={1} mb={1} />
    </>
  );
}
