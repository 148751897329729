import React, { useContext } from "react";

import { TextArea, Tooltip, Box } from "../common";
import { useTranslation } from "react-i18next";
import { ResourceContext } from "../../contexts/resourceContext";
import Button from "@material-ui/core/Button/Button";

interface ITemplateStringMap {
  [key: string]: string;
}

const jobTemplateStrings: ITemplateStringMap = {
  job_id: "The ID of the job that ran",
  job_name: "The name of the job that ran",
  job_run_id: "The ID of the job run",
  job_run_start_time: "The start time of the job run",
  job_run_end_time: "The end time of the job run",
  job_run_duration: "The duration of the job run",
  job_run_state: "The state of the job (eg 'Success', 'Failed', etc.).",
  job_run_log: "The output from the job run",
  job_run_url: "A URL linking to the job run",
} as const;

const workflowTemplateStrings: ITemplateStringMap = {
  workflow_id: "The ID of the workflow that ran",
  workflow_name: "The name of the workflow that ran",
  workflow_run_id: "The ID of the workflow run",
  workflow_run_start_time: "The start time of the workflow run",
  workflow_run_end_time: "The end time of the workflow run",
  workflow_run_duration: "The duration of the workflow run",
  workflow_run_state:
    "The state of the workflow (eg 'Success', 'Failed', etc.).",
  workflow_run_url: "A URL linking to the workflow run",
} as const;

export default function NotificationMessageBody({
  messageBody,
  setMessageBody,
}: {
  messageBody: string;
  setMessageBody: (text: string) => void;
}) {
  const { t } = useTranslation("Notification Form");
  const { resourceType } = useContext(ResourceContext);
  // TODO: leverage current cursor position when adding template string to the message
  // Right now, we just append the template string to the end of the message body
  // This is not ideal, but I can't figure out how to get at the selectionStart
  // from the underlying ref through the many layers of styled components and Material UI;
  // right now, "ref" on the TextArea resolves to a HTMLDivElement
  const onClickButton = (templateString: string) =>
    setMessageBody(`${messageBody}{{${templateString}}}`);
  // TODO: figure out how to better wire up the styled TMCButton with the Tooltip .. I think
  // this will involve forwardRef, but it is a bit over my head at the moment
  // Generate our template string buttons (one for every key of the map above) with a Tooltip
  // clicking the button adds the corresponding template string to the message
  const templateStrings =
    resourceType === "workflow" ? workflowTemplateStrings : jobTemplateStrings;
  const templateStringButtons = Object.keys(templateStrings).map(
    (templateString) => (
      <Tooltip
        key={templateString}
        title={t(templateStrings[templateString]) || "Error"}
      >
        <Button
          variant="outlined"
          onClick={() => onClickButton(templateString)}
        >
          {`{{${templateString}}}`}
        </Button>
      </Tooltip>
    )
  );
  return (
    <>
      <TextArea
        id="notification-message-body"
        label="Message Body"
        onChange={setMessageBody}
        style={{ width: "80%" }}
        value={messageBody}
      />
      <Box>
        <strong>{t("Email Variables")}:</strong>
        <div>{templateStringButtons}</div>
      </Box>
    </>
  );
}
