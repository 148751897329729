import { useContext } from "react";
import { DBContext } from "../contexts/dbContext";
import { ICredential } from "../types";

export default function useDBConnection() {
  const { credential, setCredential } = useContext(DBContext);

  return {
    dbConnection: credential,
    setDbConnection: (credential: ICredential | null) => {
      if (credential) {
        localStorage.setItem("dbConnection", JSON.stringify(credential));
      } else {
        localStorage.removeItem("dbConnection");
      }
      setCredential(credential);
    },
  };
}
