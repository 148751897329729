import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../common/Modal";
import {
  Box,
  SaveButton,
  CancelButton,
  Form,
  TextField,
  TextArea,
  Checkbox,
  Tooltip,
} from "../common";
import AddParameterForm from "../parameters/AddParameterForm";
import {
  ICredential,
  IParameter,
  IPasswordParameter,
  ITextParameter,
  TUpsertCredParameter,
} from "../../types";
import useApi, { UpsertCredentialProps } from "../../api/credentialsApi";
import useAlert from "../../hooks/useAlert";
import {
  EditTextParam,
  EditPasswordParam,
  EditOptionParam,
} from "../parameters/EditableParameter";
import { AddButton } from "../common/Button";

export default function EditCredentialModal({
  credential,
  show,
  onHide,
  onSave,
}: {
  credential: ICredential;
  show: boolean;
  onHide: () => void;
  onSave: (v: UpsertCredentialProps) => Promise<void>;
}) {
  const [credName, setCredName] = useState(credential.name);
  const [credDescription, setCredDescription] = useState(
    credential.description
  );
  const [isShared, setIsShared] = useState(credential.shared);
  const [parameters, setParameters] = useState(credential.parameters);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddingVar, setIsAddingVar] = useState(false);
  const { addCredentialParameter, removeCredentialParameter } = useApi();
  const { t } = useTranslation("Credentials Page");
  const { showError } = useAlert();
  const isTemplate = credential.template;

  useEffect(() => {
    setCredName(credential.name);
    setCredDescription(credential.description);
    setParameters(credential.parameters);
    setIsShared(credential.shared);
  }, [credential]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSave({
      credential,
      name: credName,
      description: credDescription,
      shared: isShared,
      parameters,
    });
    setIsSubmitting(false);
  };

  const handleRemoveParam = async (varId: string) => {
    try {
      const vars = await removeCredentialParameter(credential.id, varId);
      return setParameters(vars);
    } catch {
      showError(t("Error removing parameter"));
    }
  };

  const handleUpdate = async (param: IParameter) => {
    const paramMap = parameters
      ? new Map(parameters.map((e) => [e.id, e]))
      : new Map();
    paramMap.set(param.id, param);
    setParameters([...paramMap.values()]);
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>
          <ModalTitle>{t("Edit credential")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Box mb={3}>
            <TextField
              id="credential-name-input"
              placeholder={t("Add a helpful name for your credential")}
              label={t("common:name")}
              value={credName}
              required
              style={{ width: "65%" }}
              onChange={setCredName}
            />
          </Box>

          <Box mb={4}>
            <TextArea
              id="credential-description-input"
              placeholder={t(
                "Add a helpful description that lets your team know how this credential can be used"
              )}
              label={t("Description")}
              value={credDescription}
              fullWidth
              rows={3}
              onChange={setCredDescription}
            />
          </Box>

          {credential.isAuthor && (
            <Box>
              <Checkbox
                checked={isShared}
                label={t("Share with your team")}
                onChange={setIsShared}
              />
            </Box>
          )}

          <Box pl={2}>
            {/* TODO: break out parameters into separate editing experience for form accessibility */}
            <h3>
              {t("Parameters")}
              {!isTemplate && (
                <Tooltip title="Add Parameter to Credential">
                  <AddButton onClick={() => setIsAddingVar(true)} />
                </Tooltip>
              )}
            </h3>
            {parameters && parameters.length > 0 && (
              <Box mt={3} mb={3}>
                {parameters.map((param) => {
                  switch (param.type) {
                    case "text":
                      return (
                        <EditTextParam
                          key={param.id}
                          parameter={param}
                          canEditKey={true}
                          onUpdate={handleUpdate}
                          postUpdate={true}
                          onRemove={!isTemplate ? handleRemoveParam : undefined}
                        />
                      );
                    case "password":
                      return (
                        <EditPasswordParam
                          key={param.id}
                          parameter={param}
                          canEditKey={true}
                          onUpdate={handleUpdate}
                          postUpdate={true}
                          onRemove={!isTemplate ? handleRemoveParam : undefined}
                        />
                      );
                    case "option":
                      return (
                        <EditOptionParam
                          key={param.id}
                          parameter={param}
                          canEditKey={true}
                          onUpdate={handleUpdate}
                          postUpdate={true}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </Box>
            )}

            {!isTemplate && (
              <AddParameterForm
                isAddingVar={isAddingVar}
                setIsAddingVar={setIsAddingVar}
                onSave={async (param: ITextParameter | IPasswordParameter) => {
                  try {
                    const newVars = await addCredentialParameter(
                      credential.id,
                      param
                    );
                    return setParameters(newVars as TUpsertCredParameter[]);
                  } catch {
                    showError(t("Error adding parameter"));
                  }
                }}
                submitForm={false}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <SaveButton
            disabled={!credName}
            isLoading={isSubmitting}
            submitForm
          />
          <CancelButton
            onClick={() => {
              onHide();
            }}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
