import React, { useState, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";

import useAlert from "../../hooks/useAlert";
import LoadingScreen from "../common/LoadingScreen";
import Modal, { ModalHeader, ModalTitle, ModalBody } from "../common/Modal";
import { Box, Span } from "../common";
import JobDetail from "./JobDetail";
import JobRunStats from "./JobRunStats";
import JobRunsTable from "./JobRunsTable";
import LogDetail from "../logs/LogDetail";
import { IAppJob, IJob, IScriptJob } from "../../types";
import useJobsApi from "../../api/jobApi";
import { JobPageHeader } from "./JobPageHeader";
import useJobRunApi from "../../api/jobRunApi";
import { ResourceContext } from "../../contexts/resourceContext";
import AppJobDetail from "./AppJobDetail";
import { useInterval } from "../../hooks/useInterval";
import useSubmitting from "../../hooks/useSubmitting";
import useSlackAuth from "../../hooks/useSlackAuth";
import useJob from "../../hooks/useJob";

type TParams = { jobId: string; runId?: string };

// user-defined type guard to make sure Typescript knows we have a job with an app
function isAppJob(job: IJob): job is IAppJob {
  return job.app !== null;
}

// user-defined type guard to make sure Typescript knows we have a job with a script
function isScriptJob(job: IJob): job is IScriptJob {
  return job.script !== null;
}

export default function JobPage({ match }: RouteComponentProps<TParams>) {
  const { jobId, runId } = match.params;
  const { job, setJob, runsPage, refresh } = useJob();
  const [logId, setLogId] = useState<string | null>(runId || null);

  const { showError, showWarning } = useAlert();
  const { resourceType, setResourceType } = useContext(ResourceContext);
  const { fetchJob } = useJobsApi();
  const { cancelRun } = useJobRunApi();
  const { t } = useTranslation("Job Page");

  useSlackAuth();

  const fetch = () => fetchJob(jobId, { runsPage });
  const handleResponse = (response: IJob) => {
    if (!resourceType) setResourceType("job");
    return setJob(response);
  };
  const handleError = () => showError(t("Error fetching job"));

  // Start polling the server for changes to the job. Changes flow down to all child components of the job page, including sub forms, run lists, etc
  useInterval({
    fetch,
    handleResponse,
    handleError,
    dependencies: [runsPage],
  });

  const [cancel, isCanceling] = useSubmitting(async (runId?: string) => {
    if (runId) {
      try {
        await cancelRun(runId);
        showWarning(t("Canceling job!"));
      } catch {
        showError(t("Error cancelling job"));
      }
    }
  });

  let jobDetail: React.ReactNode;

  if (!job) {
    return <LoadingScreen />;
  }

  if (isAppJob(job)) {
    jobDetail = <AppJobDetail job={job} refresh={refresh} />;
  } else if (isScriptJob(job)) {
    jobDetail = <JobDetail />;
  } else {
    // We should never get here.
    throw new Error("Unknown job type for job.");
  }

  return (
    <>
      <JobPageHeader cancel={cancel} isCanceling={isCanceling} />
      {jobDetail}

      {job && job.runCount > 0 ? <JobRunStats jobId={job.id} /> : null}

      {job && job.runCount > 0 && job.runs ? (
        <JobRunsTable
          handleLogClick={setLogId}
          cancel={cancel}
          isCanceling={isCanceling}
        />
      ) : (
        <Box mt={4}>
          <Span color="outline">{t("No job runs yet")}</Span>
        </Box>
      )}

      {logId && (
        <Modal
          show={logId ? true : false}
          onHide={() => setLogId(null)}
          centered
          size="lg"
        >
          <ModalHeader onClose={() => setLogId(null)}>
            <ModalTitle>{t("Logs for job", { jobName: job.name })}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <LogDetail runId={logId} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
