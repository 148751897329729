import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { timeAgo, formatDuration } from "../../time";
import {
  Chip,
  Loading,
  Box,
  Button,
  Link,
  AsyncAutocomplete,
  Row,
  Span,
  Pagination,
} from "../common";
import Table from "../common/Table";

import useAlert from "../../hooks/useAlert";
import Status from "./JobRunStatus";
import useJobsApi from "../../api/jobApi";
import { IJobList, IJobsPaginatedList, ITag } from "../../types";

import {} from "../common";
import useTagsApi from "../../api/tagsApi";
import { useInterval } from "../../hooks/useInterval";

function JobRow({ job }: { job: IJobList }) {
  const { t } = useTranslation("JobsList");
  const { latestRun } = job;
  return (
    <tr>
      <td>
        <Link color="link" to={`/app/jobs/${job.id}`}>
          {job.name}
        </Link>
      </td>
      <td>{job.author.name}</td>
      <td>{latestRun ? timeAgo(latestRun.createdAt) : t("Unavailable")}</td>
      <td>
        {latestRun && latestRun.duration && formatDuration(latestRun.duration)}
      </td>
      <td>
        {job.tags.map((tag) => (
          <Chip key={tag.id} label={tag.name} />
        ))}
      </td>
      <td>{latestRun ? <Status run={latestRun} /> : t("No Runs Yet")}</td>
    </tr>
  );
}

export default function JobsList() {
  const [jobs, setJobs] = useState<IJobList[] | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [tagFilters, setTagFilters] = useState<ITag[]>([]);
  const { fetchJobs } = useJobsApi();
  const { fetchTags } = useTagsApi();
  const { showError } = useAlert();
  const { t } = useTranslation("JobsList");
  const pageRef = useRef(page);

  useInterval<IJobsPaginatedList>({
    fetch: () =>
      fetchJobs({
        tag_filters: tagFilters.map((tag) => tag.id),
        page: pageRef.current,
      }),
    handleResponse: (res) => {
      setJobs(res.jobs);
      setPageCount(res.pageCount);
    },
    handleError: () => showError(t("Error fetching jobs!")),
    dependencies: [tagFilters, page],
  });

  return (
    <Box>
      <Row justify="space-between">
        <Row style={{ width: "50%" }} alignItems="center">
          <AsyncAutocomplete
            id="tag-filter"
            style={{ width: "50%" }}
            label={t("Filter by tag")}
            multiselect
            fetch={async () => {
              const tags = await fetchTags();
              return tags.map((tag) => {
                return { label: tag.name, data: tag };
              });
            }}
            onChange={(_, selected) => {
              if (selected) {
                setTagFilters(selected);
                setJobs(undefined);
              }
            }}
          />
          <Span ml={3}>
            <Link to="/app/workflows">{t("View workflows")} →</Link>
          </Span>
        </Row>
        <Box style={{ textAlign: "right" }}>
          <Link to="/app/addJob">
            <Button size="lg">{t("Add Job")}</Button>
          </Link>
        </Box>
      </Row>

      <Box mt={4}>
        {jobs ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>{t("common:name")}</th>
                  <th>{t("common:Created By")}</th>
                  <th>{t("Last Run")}</th>
                  <th>{t("Duration")}</th>
                  <th>{t("common:tags")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => (
                  <JobRow key={job.id} job={job} />
                ))}
              </tbody>
            </Table>
            <Row direction="row-reverse">
              <Pagination
                count={pageCount}
                page={page}
                setPage={(page) => {
                  pageRef.current = page;
                  setPage(page);
                }}
              />
            </Row>
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}
