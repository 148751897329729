import useRequest from "../hooks/useRequest";
import { IParameterDefinition } from "../types";

export default function useParamDefApi() {
  const { get } = useRequest();
  return {
    fetchParamDef: async (id: string) => {
      const { data } = await get<IParameterDefinition>(
        `/parameter_definitions/${id}`
      );
      return data;
    },
  };
}
