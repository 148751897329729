import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "../common";
import { IParameter } from "../../types";
import AddParameterForm from "../parameters/AddParameterForm";
import useJob from "../../hooks/useJob";
import useJobsApi from "../../api/jobApi";
import useAlert from "../../hooks/useAlert";
import { IPasswordParameter, ITextParameter } from "../../types/Parameters";
import {
  EditTextParam,
  EditPasswordParam,
} from "../parameters/EditableParameter";

// utility method to create a JS map from array of parameters
function mapParameters(params: IParameter[]) {
  return new Map(params.map((p) => [p.id, p]));
}

export default function JobParameterForm() {
  /* LOAD RESOURCE CONTEXT */
  const { job } = useJob();
  if (!job) throw new Error();

  /* SET COMPONENT STATE */
  const [parameterMap, setParameterMap] = useState(
    mapParameters(job.parameters)
  );
  const [isAddingParam, setIsAddingParam] = useState(false);

  /* LOAD HOOKS */
  const { addJobParameter, removeJobParameter } = useJobsApi();
  const { showWarning, showError } = useAlert();
  const { t } = useTranslation("Job Page");

  return (
    <Box>
      <h3>{t("Parameters")}</h3>
      <Box mb={3}>{t("Add custom parameters helper text")}</Box>
      <Box>
        {[...parameterMap.values()].map((param) => {
          switch (param.type) {
            case "text":
              return (
                <EditTextParam
                  key={param.id}
                  parameter={param}
                  canEditKey={true}
                  postUpdate={true}
                  onUpdate={(update: IParameter) => {
                    parameterMap.set(update.id, update);
                    setParameterMap(new Map(parameterMap));
                  }}
                  onRemove={async (varId: string) => {
                    try {
                      const updated = await removeJobParameter(job.id, varId);
                      setParameterMap(mapParameters(updated));
                      showWarning(t("Removed parameter"));
                    } catch {
                      showError(t("Error removing parameter"));
                    }
                  }}
                />
              );
            case "password":
              return (
                <EditPasswordParam
                  key={param.id}
                  parameter={param}
                  canEditKey={true}
                  postUpdate={true}
                  onUpdate={(update: IParameter) => {
                    parameterMap.set(update.id, update);
                    setParameterMap(new Map(parameterMap));
                  }}
                  onRemove={async (varId: string) => {
                    try {
                      const updated = await removeJobParameter(job.id, varId);
                      setParameterMap(mapParameters(updated));
                      showWarning(t("Removed parameter"));
                    } catch {
                      showError(t("Error removing parameter"));
                    }
                  }}
                />
              );
            default:
              return null;
          }
        })}

        <AddParameterForm
          submitForm
          onSave={async (p: ITextParameter | IPasswordParameter) => {
            try {
              const updated = await addJobParameter(job.id, p);
              setParameterMap(mapParameters(updated));
            } catch {
              showError(t("Error adding parameter"));
            }
          }}
          isAddingVar={isAddingParam}
          setIsAddingVar={setIsAddingParam}
        />

        {!isAddingParam && (
          <Box textAlign="center">
            <Button variant="outline" onClick={() => setIsAddingParam(true)}>
              Add Parameter
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
