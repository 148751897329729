import { useEffect, useRef } from "react";
import { DEFAULT_POLL_INTERVAL } from "../constants";

export function useInterval<T>({
  fetch,
  handleResponse,
  handleError,
  delay,
  dependencies = [],
}: {
  fetch: () => Promise<T>;
  handleResponse?: (r: T) => void;
  handleError?: () => void;
  delay?: number;
  dependencies?: any[];
}) {
  const queuedRequests = useRef(0);
  const isMounted = useRef(true);

  const callback = async () => {
    try {
      const response = await fetch();
      // only handle the response if the component is still mounted, otherwise we get errors about setting state or rendering on un-mounted components
      if (isMounted.current && handleResponse) {
        handleResponse(response);
      }
    } catch {
      if (isMounted.current && handleError) handleError();
    }
  };

  const setupInterval = () => {
    const id = setInterval(() => {
      queuedRequests.current += 1;
      if (isMounted.current && queuedRequests.current === 1) {
        const othersWaiting = queuedRequests.current > 1;

        callback().finally(() => {
          queuedRequests.current = 0;
          if (othersWaiting) {
            callback();
          }
        });
      }
    }, delay || DEFAULT_POLL_INTERVAL);

    return () => clearInterval(id);
  };

  // fetch once on mount
  const onMount = () => {
    callback();
  };
  useEffect(onMount, dependencies);

  // start the poller
  useEffect(setupInterval, dependencies);

  // disable isMounted ref when component unmounts, so we know not to change state on unmounted components
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
}
