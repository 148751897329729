import React from "react";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";
import { variant } from "styled-system";

const colorVariants = variant({
  variants: {
    primary: {
      color: "white",
      bg: "primary",
    },
    warning: {
      color: "white",
      bg: "warning",
    },
    success: {
      color: "white",
      bg: "success",
    },
    danger: {
      color: "white",
      bg: "danger",
    },
    outline: {
      color: "white",
      bg: "gray",
    },
  },
});

const CustomBadge = styled(({ variant, ...rest }) => (
  <Chip size="small" variant="default" {...rest} />
))`
  border-radius: 2px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 60%;
  height: auto;
  ${colorVariants}

  .MuiChip-labelSmall {
    padding: 4px;
    font-weight: 700;
  }
`;

CustomBadge.defaultProps = {
  variant: "primary",
};

export default function TMCBadge({
  children,
  variant,
}: {
  children: string;
  variant: string;
}) {
  return <CustomBadge variant={variant} label={children} />;
}
