import React from "react";
import { useTranslation } from "react-i18next";
import { IWorkflowRun } from "../../types";
import { Box, Pagination, Row, Table } from "../common";
import WorkflowRunsRow from "./WorkflowRunsRow";

export default function WorkRunsTable({
  runs,
  onCancel,
  page,
  setPage,
  totalPages,
}: {
  runs: IWorkflowRun[];
  onCancel: (runId: string) => void;
  page: number;
  setPage: (n: number) => void;
  totalPages: number;
}) {
  const { t } = useTranslation("Workflow Page");

  return (
    <Box mt={4}>
      <h4>{t("Latest runs")}</h4>
      <Table>
        <tbody>
          <tr>
            <th></th>
            <th>{t("When")}</th>
            <th>{t("Duration")}</th>
            <th>{t("Status")}</th>
            <th></th>
          </tr>
          {runs.map((run) => {
            return (
              <WorkflowRunsRow key={run.id} run={run} onCancel={onCancel} />
            );
          })}
        </tbody>
      </Table>
      <Row direction="row-reverse">
        <Pagination count={totalPages} page={page} setPage={setPage} />
      </Row>
    </Box>
  );
}
