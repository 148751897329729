import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Col, List, ListItem, Row, Tooltip } from "../common";
import { AddButton, ClearButton, EditButton } from "../common/Button";
import {
  AddParamDefModal,
  EditParamDefModal,
} from "./parameterDefinitions/ParamDefModalForm";
import ParamDefListItemLabel from "./parameterDefinitions/ParamDefListItemLabel";

import { IApp } from "../../types";
import {
  IParameterDefinition,
  TCreateParameterDefinition,
} from "../../types/ParameterDefinitions";

import useAppsApi from "../../api/appsApi";
import useAlert from "../../hooks/useAlert";

/**
 * Component for managing parameter definitions for an app.
 */
export default function AppParameterDefinitions({
  app,
  refreshApp,
  canEdit,
}: {
  app: IApp;
  refreshApp: () => Promise<void>;
  canEdit: boolean;
}) {
  const [isAddingParamDef, setIsAddingParamDef] = useState(false);
  const [
    editingParamDef,
    setEditingParamDef,
  ] = useState<IParameterDefinition | null>(null);

  const { t } = useTranslation("App Page");
  const { showError, showWarning, showSuccess } = useAlert();
  const {
    addParameterDefinition,
    removeParameterDefinition,
    updateParameterDefinition,
  } = useAppsApi();

  // Callback to update the parameter definition on the server.
  const handleUpdate = async (paramDef: IParameterDefinition) => {
    try {
      await updateParameterDefinition(app.id, paramDef);
      showSuccess(t("Updated parameter!"));
      // Refresh the app to get the new list of parameter definitions
      refreshApp();
      setEditingParamDef(null);
    } catch {
      showError(t("Error updating parameter!"));
    }
  };
  // Callback to remove the parameter definitions from the server.
  const handleRemove = async (paramDefId: string) => {
    try {
      await removeParameterDefinition(app.id, paramDefId);
      showWarning(t("Removed parameter"));
      // Refresh the app to get the new list of parameter definitions
      refreshApp();
    } catch {
      showError("Error removing parameter!");
    }
  };
  // Callback to add a new parameter definition on the server.
  const handleAddParamDef = async (paramDef: TCreateParameterDefinition) => {
    try {
      await addParameterDefinition(app.id, paramDef);
      showSuccess(t("Added parameter"));
      setIsAddingParamDef(false);
      // Refresh the app to get the new list of parameter definitions
      refreshApp();
    } catch {
      showError(t("Error adding parameter!"));
    }
  };

  return (
    <Box>
      <h3>
        {t("App Parameters")}{" "}
        {canEdit && (
          <Tooltip title="Add Parameter to App">
            <AddButton onClick={() => setIsAddingParamDef(true)} />
          </Tooltip>
        )}
      </h3>
      {canEdit && <Box mb={3}>{t("Add custom parameters helper text")}</Box>}
      <Row>
        <Col>
          <List dense={false}>
            {app.parameterDefinitions.map((paramDef) => {
              return (
                <ListItem
                  key={`${paramDef.id}-label`}
                  showDivider
                  label={
                    <Row>
                      <ParamDefListItemLabel paramDef={paramDef} />
                    </Row>
                  }
                  actions={
                    canEdit && (
                      <>
                        <EditButton
                          size="small"
                          onClick={() => setEditingParamDef(paramDef)}
                        />
                        <ClearButton
                          size="small"
                          onClick={() => handleRemove(paramDef.id)}
                        />
                      </>
                    )
                  }
                />
              );
            })}
          </List>
        </Col>
      </Row>

      {isAddingParamDef && (
        <AddParamDefModal
          onSave={handleAddParamDef}
          onClose={() => setIsAddingParamDef(false)}
        />
      )}

      {editingParamDef && (
        <EditParamDefModal
          paramDef={editingParamDef}
          onUpdate={handleUpdate}
          onClose={() => setEditingParamDef(null)}
        />
      )}
    </Box>
  );
}
