import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useJobsApi from "../../api/jobApi";
import useWorkflowApi from "../../api/workflowApi";
import useAlert from "../../hooks/useAlert";
import { IJob, IWorkflow } from "../../types";
import {
  AsyncAutocomplete,
  Box,
  Button,
  CancelButton,
  ClearButton,
  Link,
  List,
  ListItem,
} from "../common";

export default function WorkflowNodeEditor({
  workflow,
  onUpdate,
}: {
  workflow: IWorkflow;
  onUpdate: () => void;
}) {
  const [isAddingStep, setIsAddingStep] = useState(false);
  const [selectedJob, setSelectedJob] = useState<IJob | null>(null);

  const { removeWorkflowNode, addWorkflowNode } = useWorkflowApi();
  const { fetchJobs } = useJobsApi();

  const { showError } = useAlert();
  const { t } = useTranslation("Workflow Page");

  const handleRemoveStep = async (stepId: string) => {
    if (!workflow) return null;
    try {
      await removeWorkflowNode(workflow.id, stepId);
      onUpdate();
    } catch {
      showError(t("Error removing step"));
    }
  };

  return (
    <>
      <List style={{ width: "40%" }}>
        {workflow.nodes.map((node) => (
          <ListItem
            key={node.id}
            label={<Link to={`/app/jobs/${node.job.id}`}>{node.job.name}</Link>}
            actions={<ClearButton onClick={() => handleRemoveStep(node.id)} />}
          />
        ))}
      </List>
      {isAddingStep ? (
        <Box mt={3}>
          <Box style={{ width: "50%" }}>
            <AsyncAutocomplete
              id="job-select"
              label={t("Select a job")}
              fetch={async () => {
                const { jobs } = await fetchJobs();
                return jobs.map((job) => {
                  return { label: job.name, data: job };
                });
              }}
              onChange={(_, selected: IJob) => setSelectedJob(selected)}
            />
          </Box>
          <Button
            disabled={!selectedJob}
            onClick={async () => {
              if (selectedJob) {
                try {
                  const { nodes } = workflow;
                  await addWorkflowNode(workflow.id, {
                    prev: nodes.length > 0 ? nodes[nodes.length - 1].id : "",
                    statusIn: [2],
                    next: selectedJob.id,
                  });
                } catch {
                  showError(t("Error adding step"));
                }
                onUpdate();
                setIsAddingStep(false);
                setSelectedJob(null);
              }
            }}
          >
            {t("Save step")}
          </Button>
          <CancelButton onClick={() => setIsAddingStep(false)} />
        </Box>
      ) : (
        <Box>
          <Button variant="outline" onClick={() => setIsAddingStep(true)}>
            {t("Add step")}
          </Button>
        </Box>
      )}
    </>
  );
}
