import React, { useState, useEffect } from "react";
import { INotificationCriteria, TStatusCodes } from "../../types";
import { statusCodeMap } from "../../constants";
import { ToggleButtonGroup, ToggleButton, Box } from "../common";
import { useTranslation } from "react-i18next";

const defaultStatusCodes = Object.keys(statusCodeMap)
  .slice(2)
  .map((value) => parseInt(value, 10) as TStatusCodes);

export default function NotificationCriteria({
  criteria,
  setCriteria,
}: {
  criteria: INotificationCriteria | null;
  setCriteria: (criteria: INotificationCriteria) => void;
}) {
  const { t } = useTranslation("Notification Form");
  const [statuses, setStatuses] = useState(
    criteria ? [...criteria.status] : defaultStatusCodes
  );
  const statusValues = defaultStatusCodes;
  // Generate our toggle buttons, one for each state (Success, Failed, etc.)
  const statusButtons = statusValues.map((value) => (
    <ToggleButton key={value} value={value}>
      {t(statusCodeMap[value].message)}
    </ToggleButton>
  ));
  // Notify the parent component whenever the status values change
  useEffect(() => setCriteria({ status: statuses }), [setCriteria, statuses]);
  return (
    <Box mb={3}>
      <label>{t("Notify On")}</label>
      <div>
        <ToggleButtonGroup value={statuses} onChange={setStatuses}>
          {statusButtons}
        </ToggleButtonGroup>
      </div>
    </Box>
  );
}
