import useRequest from "../hooks/useRequest";
import { IDashboardList, IListRequestParams } from "../types";

export default function useDashboardApi() {
  const { get } = useRequest();

  return {
    fetchRunning: async (options?: IListRequestParams) => {
      const { data } = await get<IDashboardList>("/dashboard/running", {
        params: { ...options },
      });
      return data;
    },
    fetchScheduled: async (options?: IListRequestParams) => {
      const { data } = await get<IDashboardList>("/dashboard/scheduled", {
        params: { ...options },
      });
      return data;
    },
    fetchTagged: async (options?: IListRequestParams) => {
      const { data } = await get<IDashboardList>("/dashboard/tagged", {
        params: { ...options },
      });
      return data;
    },
  };
}
