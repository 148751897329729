import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Pre, TextArea, TextField, Autocomplete, Span } from "../common";
import { ICredential, IScript } from "../../types";
import ScriptGitCredentialField from "../scripts/ScriptGitCredentialField";

export default function ScriptFields({
  isEditing,
  script,
  updateScript,
}: {
  isEditing: boolean;
  script: IScript;
  updateScript: (script: IScript) => void;
}) {
  const { t } = useTranslation("Script Form");

  const commands = script.commands ? script.commands.join("\n") : "";
  const updateCommands = (newCommands: string) => {
    updateScript({
      ...(script as IScript),
      commands: newCommands.split("\n"),
    });
  };

  const updateScriptField = (fieldName: keyof IScript, value: string) => {
    updateScript({
      ...(script as IScript),
      [fieldName]: value,
    });
  };

  const selectGitCredential = (credential: ICredential | null) => {
    updateScript({
      ...(script as IScript),
      gitCredential: credential,
    });
  };

  return (
    <>
      {isEditing ? (
        <>
          <Box mt={3} mb={3}>
            <Box mb={2}>
              <TextField
                id="formGitRepository"
                label={t("Git Repository")}
                placeholder="https://github.com/move-coop/parsons"
                required
                fullWidth
                size="medium"
                value={script.gitRepository}
                onChange={(val) => updateScriptField("gitRepository", val)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                id="formGitBranch"
                label={t("Git Branch")}
                placeholder="main"
                required
                fullWidth
                size="medium"
                value={script.gitBranch}
                onChange={(val) => updateScriptField("gitBranch", val)}
              />
            </Box>
            <Box mb={2}>
              <TextArea
                id="formRunCommands"
                label={t("Run Commands")}
                placeholder="python main.py"
                required
                value={commands}
                rows={3}
                fullWidth
                onChange={updateCommands}
              />
            </Box>
            <Box mb={3}>
              <p>
                {t(
                  "Add a Github credential if your script is in a private repo."
                )}
              </p>
              <ScriptGitCredentialField
                credential={script.gitCredential}
                selectCredential={selectGitCredential}
                systemId="GIT_CREDENTIALS"
              />
            </Box>
            <Box mb={2}>
              <p>{t("Select the environment your script should run in")}</p>
              <Autocomplete
                id="formContainerImage"
                label={t("Container Image")}
                defaultValue={{
                  label: script.containerImage,
                  data: script.containerImage,
                }}
                required
                canCreateOptions
                options={[
                  {
                    label: "movementcooperative/parsons",
                    data: "movementcooperative/parsons",
                  },
                  { label: "python", data: "python" },
                ]}
                onChange={(userInput, selected) => {
                  updateScriptField("containerImage", selected || userInput);
                }}
              />
              <Span color="outline">
                {t("You can use any public docker image from")}{" "}
                <a
                  href="https://hub.docker.com/search?q=&type=image"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DockerHub
                </a>
                .
              </Span>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box mt={3} mb={2}>
            <strong>{t("Git Repository")}: </strong>&nbsp;
            {script.gitRepository}
          </Box>
          <Box mb={2}>
            <strong>{t("Git Branch")}:</strong>&nbsp;
            {script.gitBranch}
          </Box>
          <Box mb={2}>
            <strong>{t("Git Credentials")}:</strong>&nbsp;
            {script.gitCredential ? script.gitCredential.name : ""}
          </Box>
          <Box mb={2}>
            <strong>{t("Container Image")}:</strong>&nbsp;
            {script.containerImage}
          </Box>
          <Box mb={2}>
            <Box>
              <strong>{t("Run Commands")}:</strong>&nbsp;
            </Box>
            <Pre style={{ width: "85%" }}>
              <code>{script.commands.join("\n")}</code>
            </Pre>
          </Box>
        </>
      )}
    </>
  );
}
