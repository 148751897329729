import React from "react";
import { ChangeEvent } from "../../types";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  font-family: ${({ theme }) => theme.fonts.main};
`;

export default function TMCSelect({
  value,
  id,
  label,
  name,
  onChange,
  children,
  style,
}: {
  value: string;
  label?: string;
  id: string;
  name: string;
  onChange: (v: string) => void;
  children: React.ReactNodeArray | React.ReactNode;
  style?: object;
}) {
  return (
    <>
      {label ? <InputLabel htmlFor={id}>{label}</InputLabel> : null}
      <StyledSelect
        native
        value={value}
        onChange={(
          e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>
        ) => {
          const val = e.target.value ? String(e.target.value) : "";
          onChange(val);
        }}
        style={style}
        inputProps={{
          name,
          id,
        }}
      >
        {children}
      </StyledSelect>
    </>
  );
}
