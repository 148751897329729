import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime, getDate } from "../../time";
import { Box, Span } from "../common";
import { TimePicker } from "../common/Pickers";
import {
  DATE_FORMAT,
  generateCron,
  parseCron,
  TIME_FORMAT,
} from "./schedulerHelpers";

const DEFAULT_INTERVAL = "daily";

export default function ReportSchedulerForm({
  cronLine,
  onChange,
}: {
  cronLine: string;
  onChange: (cron: string) => void;
}) {
  const { localDateTime } = parseCron(cronLine);
  const { t } = useTranslation("Scheduler");

  return (
    <Box mt={3}>
      <h4>{t("Schedule")}</h4>
      <TimePicker
        label=""
        defaultTime={formatDateTime(localDateTime, TIME_FORMAT)}
        onChange={(selectedTime) => {
          if (selectedTime) {
            const updatedDateTime = getDate(
              `${formatDateTime(localDateTime, DATE_FORMAT)} ${selectedTime}`,
              `${DATE_FORMAT} ${TIME_FORMAT}`
            );
            const cron = generateCron(updatedDateTime, DEFAULT_INTERVAL);
            onChange(cron);
          }
        }}
      />
      <Span ml={3}>
        <strong>{t("Daily")}</strong>
      </Span>
    </Box>
  );
}
