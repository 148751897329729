import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ButtonSpinner } from "../common/Spinner";
import styled from "styled-components";
import { space, variant, SpaceProps } from "styled-system";
import { darken, lighten } from "polished";
import { ThemeProps } from "../../types";

const colorVariants = variant({
  variants: {
    primary: {
      color: "white",
      bg: "primary",
      "&:hover": {
        bg: ({ colors }: ThemeProps) => darken(0.1, colors.primary),
      },
    },
    warning: {
      color: "white",
      bg: "warning",
      "&:hover": {
        color: "white",
        bg: ({ colors }: ThemeProps) => darken(0.1, colors.warning),
      },
    },
    success: {
      color: "white",
      bg: "success",
    },
    danger: {
      color: "white",
      bg: "danger",
      "&:hover": {
        bg: ({ colors }: ThemeProps) => darken(0.1, colors.danger),
      },
    },
    link: {
      color: ({ colors }: ThemeProps) => colors.link,
      padding: "0 1px 4px 0",
      "&:hover": {
        color: ({ colors }: ThemeProps) => lighten(0.2, colors.link),
        bg: "transparent",
      },
    },
    navLink: {
      color: ({ colors }: ThemeProps) => colors.navText,
      bg: "transparent",
      "&:hover": {
        color: ({ colors }: ThemeProps) => lighten(0.2, colors.navText),
        bg: "transparent",
      },
    },
    outline: {
      color: "outline",
      bg: "white",
      borderColor: "outline",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        bg: "white",
        color: "primary",
        borderColor: "primary",
      },
    },
    "outline-danger": {
      color: "outline",
      bg: "white",
      borderColor: "outline",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "danger",
        bg: "white",
        borderColor: "danger",
      },
    },
  },
});

const sizeVariants = variant({
  prop: "size",
  variants: {
    md: {
      width: 150,
      lineHeight: 1.5,
    },
    lg: {
      width: 250,
      padding: ".5rem 1rem",
      fontSize: "1.25rem",
      lineHeight: 1.5,
    },
  },
});

const CustomButton = styled(({ size, variant, ...rest }) => (
  <Button
    variant="contained"
    disableElevation
    disableFocusRipple
    disableRipple
    {...rest}
  />
))`
  border-color: transparent;
  border-radius: 2px;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 1rem;
  text-transform: none;
  background-color: transparent;
  ${space}
  ${colorVariants}
  ${sizeVariants}
`;

CustomButton.defaultProps = {
  variant: "primary",
  mr: 1,
};

interface IProps extends SpaceProps {
  isLoading?: boolean;
  loadingMessage?: string;
  children?: string | React.ReactNodeArray;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: string;
  className?: string;
  variant?: string;
  size?: string;
  disabled?: true | false;
}

export default function TMCButton({
  isLoading,
  loadingMessage,
  children,
  ...rest
}: IProps) {
  return isLoading ? (
    <CustomButton disabled {...rest}>
      <ButtonSpinner />
      {loadingMessage ? loadingMessage : children}
    </CustomButton>
  ) : (
    <CustomButton {...rest}>{children}</CustomButton>
  );
}

interface SaveButtonProps extends IProps {
  submitForm?: boolean;
  label?: string;
}
export function SaveButton({
  onClick,
  submitForm,
  label,
  ...rest
}: SaveButtonProps) {
  const { t } = useTranslation("common");
  return (
    <TMCButton
      type={submitForm ? "submit" : "button"}
      onClick={onClick}
      {...rest}
    >
      {label || t("save")}
    </TMCButton>
  );
}

export function CancelButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation("common");
  return (
    <TMCButton variant="outline" onClick={onClick}>
      {t("cancel")}
    </TMCButton>
  );
}

export const RemoveButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps
>((props, ref) => (
  <IconButton aria-label="remove" ref={ref} {...props}>
    <RemoveIcon />
  </IconButton>
));

export const ClearButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => (
    <IconButton aria-label="clear" ref={ref} {...props}>
      <ClearIcon />
    </IconButton>
  )
);

export const AddButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => (
    <IconButton aria-label="add" ref={ref} {...props}>
      <AddIcon />
    </IconButton>
  )
);

export const EditButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => (
    <IconButton aria-label="edit" ref={ref} {...props}>
      <EditIcon />
    </IconButton>
  )
);

export function ExpandButton({
  open,
  onToggle,
}: {
  open: boolean;
  onToggle: (openState: boolean) => void;
}) {
  return (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => onToggle(!open)}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}{" "}
    </IconButton>
  );
}
