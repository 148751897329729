import React from "react";
import Badge from "../common/Badge";

import { IJobRun, IWorkflowRun, IRunLog } from "../../types";
import { statusCodeMap } from "../../constants";

export default function Status({
  run,
}: {
  run: IJobRun | IWorkflowRun | IRunLog;
}) {
  const { message, variant } = statusCodeMap[run.statusCode];
  return <Badge variant={variant}>{message}</Badge>;
}
