import React from "react";
import { useTranslation } from "react-i18next";
import { formatDuration, timeAgo } from "../../time";
import { IResource } from "../../types";
import { Span } from "../common";
import Status from "../jobs/JobRunStatus";

export default function LastRunStatus({ resource }: { resource: IResource }) {
  const { t } = useTranslation("Dashboard");
  if (!resource.latestRun) return <>{t("No Runs Yet")}</>;

  const run = resource.latestRun;
  return (
    <>
      <Status run={run} />{" "}
      <Span ml={1}>
        {timeAgo(run.createdAt)}{" "}
        {run.duration && `in ${formatDuration(run.duration)}`}
      </Span>
    </>
  );
}
