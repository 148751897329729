import React from "react";
import { IParameterDefinition } from "../../types";
import { IUpsertDbQueryParam } from "../../types/Parameters";
import ParamDefListItemLabel from "../apps/parameterDefinitions/ParamDefListItemLabel";
import { Box, Divider, Row } from "../common";
import SQLCodeEditor from "../editors/SQLCodeEditor";

export default function ({
  parameter,
  paramDef,
  onChange,
}: {
  parameter: IUpsertDbQueryParam;
  paramDef: IParameterDefinition;
  onChange: (v: string) => void;
}) {
  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />
      </Row>
      <Box mb={3}>
        <SQLCodeEditor value={parameter.queryString} onChangeValue={onChange} />
      </Box>
      <Divider mt={1} mb={1} />
    </>
  );
}
