import { useContext } from "react";
import { AlertContext } from "../contexts/alertContext";

export default function useAlert() {
  const { state, dispatch } = useContext(AlertContext);

  const showSuccess = (message: string) => {
    dispatch({
      type: "success",
      message,
    });
  };

  const showError = (message: string) => {
    dispatch({
      type: "error",
      message,
    });
  };

  const showWarning = (message: string) => {
    dispatch({
      type: "warning",
      message,
    });
  };

  const clearAlert = () => {
    dispatch({
      type: "none",
    });
  };

  return {
    alertState: state,
    showError,
    showSuccess,
    showWarning,
    clearAlert,
  };
}
