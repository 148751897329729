import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  SaveButton,
  Form,
  TextField,
  TextArea,
  Span,
} from "../common";
import { IScript, IApp } from "../../types";
import useAlert from "../../hooks/useAlert";
import ScriptFields from "../jobs/ScriptFields";
import useAppsApi from "../../api/appsApi";
import useSubmitting from "../../hooks/useSubmitting";

const newScript: IScript = {
  id: "",
  gitRepository: "",
  gitBranch: "",
  containerImage: "",
  commands: [],
  gitCredential: null,
};

const newApp: IApp = {
  id: "",
  name: "",
  description: null,
  script: newScript,
  author: {
    id: "",
    name: "",
  },
  createdAt: "",
  createdFromJob: null,
  jobs: [],
  owner: {
    id: "",
    name: "",
  },
  parameterDefinitions: [],
  updatedAt: "",
  isSystemApp: false,
};

export default function NewAppPage() {
  const [app, setApp] = useState<IApp>(newApp);
  const { showError } = useAlert();
  const { createApp } = useAppsApi();
  const history = useHistory();
  const { t } = useTranslation("App Page");

  const updateField = (fieldName: keyof IApp, value: string) => {
    setApp({
      ...(app as IApp),
      [fieldName]: value,
    });
  };

  const updateScript = (script: IScript) => {
    setApp({
      ...(app as IApp),
      script,
    });
  };

  const [handleSave, isSubmittingSave] = useSubmitting(async () => {
    try {
      const newApp = await createApp(app);
      history.push(`/app/apps/${newApp.id}`);
    } catch {
      showError(t("Error creating job"));
    }
  });

  return (
    <Box style={{ width: "auto", maxWidth: "640px" }}>
      <h1>{t("Create App")}</h1>
      <p>{t("Create app instructions")}</p>
      <hr />
      <Form className="mb-3" onSubmit={handleSave}>
        <Box>
          <TextField
            id="app-name-input"
            label={t("App Name")}
            onChange={(val) => updateField("name", val)}
            helperText={t("Add a helpful name for the app")}
          />
        </Box>
        <Box mb={3}>
          <TextArea
            rows={4}
            fullWidth
            id="app-description-input"
            label={t("App Description")}
            onChange={(val) => updateField("description", val)}
            helperText={t(
              "Add a description to help your team install and run your app."
            )}
          />
        </Box>
        <h2>
          <Span color="outline">{t("Script & Container Info")}</Span>
        </h2>
        <Box>
          <ScriptFields
            script={app.script}
            updateScript={updateScript}
            isEditing
          />
        </Box>

        <Box>
          <SaveButton
            disabled={
              !app.name ||
              !app.script.gitRepository ||
              !app.script.containerImage ||
              !app.script.commands.length
            }
            isLoading={isSubmittingSave}
            submitForm
          />
          <Link to="/apps/apps">
            <Button variant="outline">Cancel</Button>
          </Link>
        </Box>
      </Form>
    </Box>
  );
}
