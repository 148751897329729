import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    font-family: ${({ theme }) => theme.fonts.main};
  }
`;

export default function TMCPagination({
  page,
  count,
  setPage,
}: {
  page: number;
  count: number;
  setPage: (p: number) => void;
}) {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return <StyledPagination count={count} page={page} onChange={handleChange} />;
}
