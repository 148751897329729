import React, { createContext, ReactNode, useState } from "react";
import { IJob } from "../types";

interface IJobContext {
  job: IJob | null;
  setJob: (j: IJob) => void;
  runsPage: number;
  setRunsPage: (n: number) => void;
}

export const JobContext = createContext<IJobContext>({
  job: null,
  setJob: (j) => {
    return;
  },
  runsPage: 1,
  setRunsPage: (n) => {
    return;
  },
});

export function JobContextProvider({ children }: { children: ReactNode }) {
  const [job, setJob] = useState<IJob | null>(null);
  const [runsPage, setRunsPage] = useState(1);

  return (
    <JobContext.Provider value={{ job, setJob, runsPage, setRunsPage }}>
      {children}
    </JobContext.Provider>
  );
}
