import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Form,
  SaveButton,
  CancelButton,
  TextField,
  Col,
  Row,
  Checkbox,
  Button,
  List,
  ListItem,
  ClearButton,
  Span,
  Divider,
  TextArea,
} from "../../common";
import {
  ICreateOptionParamDefinition,
  IParameterDefinition,
} from "../../../types/ParameterDefinitions";
import useSubmitting from "../../../hooks/useSubmitting";

interface IOptionParamDefFields {
  label: string;
  defaultKeyName: string;
  options: string[];
  required: boolean;
  description: string | null;
}

function OptionParamDefFields({
  paramDef,
  setParamDef,
}: {
  paramDef: IOptionParamDefFields;
  setParamDef: (p: IOptionParamDefFields) => void;
}) {
  const [newOption, setNewOption] = useState("");
  // const optionMap =  useState(
  // );
  const [isAddingOption, setIsAddingOption] = useState(false);
  const { t } = useTranslation("App Page");
  const optionMap = new Map(paramDef.options.map((option) => [option, option]));

  return (
    <>
      <Row>
        <TextField
          id="label-input"
          label={t("Label")}
          fullWidth
          required
          helperText={t(
            "Add a helpful label for this parameter to help your team know what it's for."
          )}
          value={paramDef.label}
          onChange={(label) => {
            setParamDef({
              ...paramDef,
              label,
            });
          }}
        />
      </Row>
      <Row>
        <TextArea
          rows={4}
          fullWidth
          id="description-input"
          label={t("Description")}
          value={paramDef.description}
          onChange={(description) => {
            setParamDef({
              ...paramDef,
              description,
            });
          }}
          helperText={t(
            "Add a description for this parameter to help your team know how to use it."
          )}
        />
      </Row>
      <Row>
        <TextField
          id="default-keyname-input"
          label={t("Default Key Name")}
          value={paramDef.defaultKeyName}
          fullWidth
          required
          helperText={t(
            "Set the key name that your app is expecting for this parameter."
          )}
          onChange={(defaultKeyName) =>
            setParamDef({
              ...paramDef,
              defaultKeyName,
            })
          }
        />
      </Row>
      <Divider />
      <Row>
        <Span color="outline">
          <h4>{t("Options")}</h4>
        </Span>
      </Row>
      {optionMap.size > 0 && (
        <List>
          {[...optionMap.values()].map((option) => (
            <ListItem
              key={`${option}-key`}
              label={option}
              actions={
                <ClearButton
                  onClick={() => {
                    optionMap.delete(option);
                    setParamDef({
                      ...paramDef,
                      options: [...optionMap.values()],
                    });
                  }}
                />
              }
            />
          ))}
        </List>
      )}
      {isAddingOption ? (
        <Row>
          <Col xs={8}>
            <TextField
              fullWidth
              id="add-option-field"
              label={t("Add option")}
              onChange={(val) => setNewOption(val)}
            />
          </Col>
          <Col>
            <SaveButton
              disabled={!newOption}
              onClick={() => {
                optionMap.set(newOption, newOption);
                setParamDef({
                  ...paramDef,
                  options: [...optionMap.values()],
                });
                setIsAddingOption(false);
              }}
            />
            <CancelButton onClick={() => setIsAddingOption(false)} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Button onClick={() => setIsAddingOption(true)}>
            {t("Add option")}
          </Button>
        </Row>
      )}
      <Divider />
      <Row>
        <Checkbox
          checked={paramDef.required}
          label={t("common:required")}
          onChange={(required) =>
            setParamDef({
              ...paramDef,
              required,
            })
          }
        />
      </Row>
    </>
  );
}

export function AddOptionParamDef({
  onSave,
  onClose,
}: {
  onSave: (paramDef: ICreateOptionParamDefinition) => Promise<void>;
  onClose: () => void;
}) {
  const [formState, setFormState] = useState<IOptionParamDefFields>({
    label: "",
    defaultKeyName: "",
    options: [],
    required: false,
    description: null,
  });
  const { t } = useTranslation("App Page");

  const [addParameterDefinition, isSubmitting] = useSubmitting(async () => {
    await onSave({
      ...formState,
      type: "option",
    });
  });

  return (
    <Form onSubmit={addParameterDefinition}>
      <Row mt={3} mb={3}>
        <Col data-i18n-key="optionParameterDefinitionHelperText">
          {t("optionParameterDefinitionHelperText")}
        </Col>
      </Row>

      <OptionParamDefFields paramDef={formState} setParamDef={setFormState} />

      <Row direction="row-reverse">
        <CancelButton onClick={onClose} />

        <SaveButton
          isLoading={isSubmitting}
          onClick={addParameterDefinition}
          label={t("common:add")}
          disabled={
            !formState.label ||
            !formState.defaultKeyName ||
            !formState.options.length
          }
        />
      </Row>
    </Form>
  );
}

export function EditOptionParamDef({
  paramDef,
  onUpdate,
  onCancel,
}: {
  paramDef: IParameterDefinition;
  onUpdate: (p: IParameterDefinition) => Promise<void>;
  onCancel: () => void;
}) {
  if (paramDef.type !== "option")
    throw new Error("Incorrect param def type for component");

  const [formState, setFormState] = useState({
    label: paramDef.label,
    defaultKeyName: paramDef.defaultKeyName,
    options: paramDef.options,
    required: paramDef.required,
    description: paramDef.description,
  });
  const { t } = useTranslation("App Page");

  const [submitUpdate, isSubmittingUpdate] = useSubmitting(async () => {
    await onUpdate({ ...paramDef, ...formState });
  });

  return (
    <Form onSubmit={submitUpdate}>
      <OptionParamDefFields paramDef={formState} setParamDef={setFormState} />

      <Row direction="row-reverse">
        <CancelButton onClick={onCancel} />

        <SaveButton
          isLoading={isSubmittingUpdate}
          submitForm
          onClick={submitUpdate}
          label={t("common:save")}
          disabled={
            !formState.label ||
            !formState.defaultKeyName ||
            !formState.options.length
          }
        />
      </Row>
    </Form>
  );
}
