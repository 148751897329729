import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IApp } from "../../types";
import useAppsApi from "../../api/appsApi";
import { Box, Button, Divider, EditButton, TextField } from "../common";
import useAlert from "../../hooks/useAlert";
import useSubmitting from "../../hooks/useSubmitting";
import { useHistory } from "react-router";

export default function AppPageHeader({
  app,
  refreshApp,
  canEdit,
}: {
  app: IApp;
  refreshApp: () => Promise<void>;
  canEdit: boolean;
}) {
  const { t } = useTranslation("App Page");
  const [isEditingName, setIsEditingName] = useState(false);
  const [updatedName, setUpdatedName] = useState(app.name);
  const { showError, showSuccess } = useAlert();
  const { createJobFromApp, updateApp } = useAppsApi();
  const history = useHistory();

  useEffect(() => {
    setUpdatedName(app.name);
  }, [app.name, isEditingName]);

  const [saveName, isSubmittingUpdate] = useSubmitting(async () => {
    const updatedApp = { ...app, name: updatedName };
    try {
      await updateApp(app.id, updatedApp);
      await refreshApp();
      showSuccess(t("App name updated!"));
    } catch {
      showError(t("Error updating app name!"));
    } finally {
      setIsEditingName(false);
    }
  });

  const [createJob, isSubmittingCreateJob] = useSubmitting(async () => {
    try {
      const job = await createJobFromApp(app);
      history.push(`/app/jobs/${job.id}`);
    } catch {
      showError(t("Error creating job!"));
    }
  });

  return (
    <>
      <h1>
        {isEditingName ? (
          <>
            <TextField
              id="job-name"
              fontSize="2.5rem"
              label="Name"
              onChange={setUpdatedName}
              value={updatedName}
            />
            <Box display="inline-block" ml={2}>
              <Button isLoading={isSubmittingUpdate} onClick={saveName}>
                {t("common:save")}
              </Button>
              <Button variant="outline" onClick={() => setIsEditingName(false)}>
                {t("common:cancel")}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <span>{updatedName}</span>
            {canEdit && <EditButton onClick={() => setIsEditingName(true)} />}
          </>
        )}
      </h1>
      <Divider size="normal" variant="outline" />
      <Box mb={3}>
        <Button
          mr={3}
          size="lg"
          isLoading={isSubmittingCreateJob}
          loadingMessage={t("common:creating")}
          onClick={() => createJob()}
        >
          {t("Install")}
        </Button>
      </Box>
    </>
  );
}
