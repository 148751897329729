import React, { useState, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";

import WorkflowRunsTable from "./WorkflowRunsTable";
import { IWorkflow } from "../../types";
import useWorkflowApi from "../../api/workflowApi";
import { Loading, Box, Span, Row, Col } from "../common";
import useAlert from "../../hooks/useAlert";
import WorkflowNodeEditor from "./WorkflowNodeEditor";
import WorkflowJobRunPoller from "./WorkflowJobRunPoller";
import SchedulerForm from "../scheduler/SchedulerForm";
import TagForm from "../tags/TagForm";
import useTagsApi from "../../api/tagsApi";
import NotificationForm from "../notifications/NotificationForm";
import useNotificationsApi, {
  UpsertNotificationProps,
} from "../../api/notificationsApi";
import { WorkflowPageHeader } from "./WorkflowPageHeader";
import useSubmitting from "../../hooks/useSubmitting";
import useWorkflowRunsApi from "../../api/workflowRunsApi";
import { ResourceContext } from "../../contexts/resourceContext";
import { useInterval } from "../../hooks/useInterval";
import useSlackAuth from "../../hooks/useSlackAuth";

export default function WorkflowPage({
  match,
}: RouteComponentProps<{ workflowId: string }>) {
  const { workflowId } = match.params;
  const { resourceType, setResourceType } = useContext(ResourceContext);
  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);
  const [runsPage, setRunsPage] = useState(1);

  const {
    fetchWorkflow,
    updateWorkflowSchedule,
    addWorkflowTag,
    removeWorkflowTag,
  } = useWorkflowApi();
  const {
    createNotification,
    removeNotification,
    updateNotification,
  } = useNotificationsApi();
  const { cancelWorkflowRun } = useWorkflowRunsApi();
  const { fetchTags, createTag } = useTagsApi();
  const { showWarning, showError } = useAlert();

  useSlackAuth();

  const { t } = useTranslation("Workflow Page");

  const fetch = () => fetchWorkflow(workflowId, { runsPage });
  const handleResponse = (response: IWorkflow) => {
    setWorkflow(response);
    if (!resourceType) setResourceType("workflow");
  };
  const handleError = () => showError(t("Error fetching workflow"));

  // Start polling the server for changes to the workflow. Changes flow down to all child components of the job page, including sub forms, run lists, etc
  useInterval({
    fetch,
    handleResponse,
    handleError,
    dependencies: [runsPage],
  });

  const updateWorkflow = async () => {
    try {
      const response = await fetch();
      handleResponse(response);
    } catch {
      handleError();
    }
  };

  const [cancelRun, isCanceling] = useSubmitting(async (runId?: string) => {
    if (runId) {
      try {
        await cancelWorkflowRun(runId);
        showWarning(t("Canceling workflow run"));
      } catch {
        showError(t("Error canceling workflow run"));
      }
    }
  });

  return workflow ? (
    <div>
      <WorkflowPageHeader
        workflow={workflow}
        refreshWorkflow={updateWorkflow}
        cancelRun={cancelRun}
        isCanceling={isCanceling}
      />
      <Row>
        <Col md={6}>
          <SchedulerForm
            isJob={false}
            cronLine={workflow.cronSchedule}
            cronScheduleEnd={workflow.cronScheduleEnd}
            onSubmit={(cronSchedule: string, expiration?: string) => {
              return updateWorkflowSchedule(
                workflow.id,
                cronSchedule,
                expiration
              );
            }}
            onRemove={() => updateWorkflowSchedule(workflow.id, "")}
          />
        </Col>
        <Col md={6}>
          <Box mb={4}>
            <TagForm
              tags={workflow.tags}
              fetchTags={async () => {
                return await fetchTags({
                  exclude: workflow.tags.map((t) => t.id),
                });
              }}
              onSubmit={async (tag, isCreating) => {
                const result = isCreating
                  ? await createTag({ name: tag, workflow_id: workflow.id })
                  : await addWorkflowTag(workflow.id, tag);
                if (result) updateWorkflow();
              }}
              onRemove={async (tagId, deleteTag) => {
                const result = await removeWorkflowTag(workflow.id, tagId, {
                  deleteTag,
                });
                if (result) updateWorkflow();
              }}
            />
          </Box>
          <NotificationForm
            notifications={workflow.notifications}
            onSave={async (v: UpsertNotificationProps) => {
              const props = { ...v, workflowId: workflow.id };
              const upsert = v.id ? updateNotification : createNotification;
              await upsert(props);
              await updateWorkflow();
              return null;
            }}
            onRemove={async (notificationId: string) => {
              await removeNotification(notificationId);
              await updateWorkflow();
              return null;
            }}
          />
        </Col>
      </Row>
      <section>
        <h3>{t("Jobs")}</h3>
        {workflow &&
          (Boolean(workflow?.activeRun) ? (
            <WorkflowJobRunPoller workflow={workflow} />
          ) : (
            <WorkflowNodeEditor workflow={workflow} onUpdate={updateWorkflow} />
          ))}
      </section>

      {workflow && workflow.runs && workflow.runs.length > 0 ? (
        <WorkflowRunsTable
          runs={workflow.runs}
          onCancel={cancelRun}
          page={runsPage}
          setPage={setRunsPage}
          totalPages={workflow.runsPages || 0}
        />
      ) : (
        <Box mt={4}>
          <Span color="outline">{t("No workflow runs yet")}</Span>
        </Box>
      )}
    </div>
  ) : (
    <Loading />
  );
}
