import React, { useEffect, useState } from "react";
import { Box, AsyncAutocomplete } from "../common";
import { useTranslation } from "react-i18next";
import { ICredentialTemplate, ICredential } from "../../types";
import useCredentialsApi, {
  CreateCredentialProps,
} from "../../api/credentialsApi";
import useAlert from "../../hooks/useAlert";
import NewCredentialTemplateModal from "../credentials/NewCredentialTemplateModal";

export default function ScriptGitCredentialField({
  credential,
  systemId,
  selectCredential,
}: {
  credential: ICredential | null;
  systemId: string;
  selectCredential: (credential: ICredential | null) => void;
}) {
  const [
    credentialTemplate,
    setCredentialTemplate,
  ] = useState<ICredentialTemplate | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation("Script Form");
  const { showSuccess, showError } = useAlert();

  const {
    createCredential,
    fetchCredentialTemplates,
    fetchMyCredentials,
  } = useCredentialsApi();

  const fetchTemplate = async () => {
    if (!credentialTemplate) {
      const templates = await fetchCredentialTemplates({ systemId });
      setCredentialTemplate(templates[0]);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const credentialOption = credential
    ? { label: credential.name, value: credential }
    : null;

  if (!credentialTemplate) {
    return null;
  }

  return (
    <Box>
      <>
        <Box mb={2}>
          <AsyncAutocomplete
            id="credential-selectbox"
            label={t("Git Credentials")}
            value={credentialOption}
            onChange={async (_, selected) => {
              if (selected) {
                if (selected.showModal) {
                  return setShowModal(true);
                }
                selectCredential(selected);
              } else {
                selectCredential(null);
              }
            }}
            fetch={async () => {
              const creds = await fetchMyCredentials(credentialTemplate.id);
              const options = creds.map((cred) => {
                return { label: cred.name, data: cred };
              });
              return [
                ...options,
                ...[
                  {
                    label: t("Add git credentials"),
                    data: { showModal: true },
                  },
                ],
              ];
            }}
          />
        </Box>
      </>

      {credentialTemplate && (
        <NewCredentialTemplateModal
          selectedTemplate={credentialTemplate}
          show={showModal}
          onSave={async (data: CreateCredentialProps) => {
            try {
              const cred = await createCredential(data);
              showSuccess(t("Created credential"));
              setShowModal(false);
              selectCredential(cred);
            } catch {
              showError(t("Error creating git credentials"));
            }
          }}
          onHide={() => setShowModal(false)}
        />
      )}
    </Box>
  );
}
