import { useState } from "react";

export default function useSubmitting<T>(
  callback: (...rest: any[]) => Promise<T>
): [(...rest: any[]) => Promise<void>, boolean] {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const wrapped = async (...rest: any[]) => {
    setIsSubmitting(true);
    try {
      await callback(...rest);
    } finally {
      setIsSubmitting(false);
    }
  };

  return [wrapped, isSubmitting];
}
