import useRequest from "../hooks/useRequest";
import { ILink } from "../types";

export default function useSlackApi() {
  const { get } = useRequest();
  return {
    fetchChannels: async () => {
      const { data } = await get<ILink[]>("/slack/channels");
      return data;
    },
  };
}
