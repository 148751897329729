import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Divider, Drawer, List } from "../common";
import { ListItemLink } from "../common/List";
import FlareIcon from "@material-ui/icons/Flare";
import StorageIcon from "@material-ui/icons/Storage";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AppsIcon from "@material-ui/icons/Apps";
import LockIcon from "@material-ui/icons/Lock";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";

export default function SecondaryNav({
  open,
  onHide,
}: {
  open: boolean;
  onHide: () => void;
}) {
  const { t } = useTranslation("Sidebar Navigation");

  return (
    <Drawer open={open} onClose={onHide}>
      <Box mt={3}>
        <List aria-label="main site links">
          <ListItemLink
            icon={<DashboardIcon />}
            primary={t("Dashboard")}
            to="/app/dashboard"
            onClick={onHide}
          />
          <ListItemLink
            icon={<FlareIcon />}
            primary={t("Jobs")}
            to="/app/jobs"
            onClick={onHide}
          />
          <ListItemLink
            icon={<AccountTreeIcon />}
            primary="Workflows"
            to="/app/workflows"
            onClick={onHide}
          />
        </List>
        <Divider />
        <List aria-label="secondary site links">
          <ListItemLink
            icon={<AppsIcon />}
            primary={t("Apps")}
            to="/app/apps"
            onClick={onHide}
          />
          <ListItemLink
            icon={<LockIcon />}
            primary={t("Credentials")}
            to="/app/credentials"
            onClick={onHide}
          />
          <ListItemLink
            icon={<DescriptionIcon />}
            primary={t("Reports")}
            to="/app/reports"
            onClick={onHide}
          />
        </List>
      </Box>
    </Drawer>
  );
}
