import React, { createContext, ReactNode, useState } from "react";
import { IJob, IWorkflow } from "../types";

type TResources = IJob | IWorkflow | null;
type TResourceTypes = "job" | "workflow" | null;

interface IResourceContext {
  resource: TResources;
  resourceType: TResourceTypes;
  setResource: (r: TResources) => void;
  setResourceType: (t: TResourceTypes) => void;
}

export const ResourceContext = createContext<IResourceContext>({
  resource: null,
  resourceType: null,
  setResource: (r) => {
    return;
  },
  setResourceType: (t) => {
    return;
  },
});

export function ResourceContextProvider({ children }: { children: ReactNode }) {
  const [resource, setResource] = useState<TResources>(null);
  const [resourceType, setResourceType] = useState<TResourceTypes>(null);
  return (
    <ResourceContext.Provider
      value={{ resource, resourceType, setResource, setResourceType }}
    >
      {children}
    </ResourceContext.Provider>
  );
}
