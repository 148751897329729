import React, { useState } from "react";
import { Virtuoso } from "react-virtuoso";

import useDbQueryApi from "../../api/dbQueryApi";
import useDBConnection from "../../hooks/useDBConnection";
import { Row, ExpandButton, Span, Box } from "../common";
import useAlert from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import TableRow from "./TableRow";
import { SectionHeader, SectionLoading } from "./SchemaTree";

export default function SchemaRow({ schema }: { schema: string }) {
  const [open, setOpen] = useState(false);
  const [tables, setTables] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState<string[]>([]);
  const [isLoadingViews, setIsLoadingViews] = useState(false);
  const { fetchTables, fetchViews } = useDbQueryApi();
  const { dbConnection } = useDBConnection();
  const { t } = useTranslation("SQL Browser");
  const { showError } = useAlert();

  const getSchemaTables = async () => {
    if (dbConnection) {
      setIsLoading(true);
      try {
        const { tables } = await fetchTables(dbConnection.id, schema);
        setTables(tables);
      } catch {
        showError(t("Error fetching tables"));
      }
      setIsLoading(false);
    }
  };

  const getSchemaViews = async () => {
    if (dbConnection) {
      setIsLoadingViews(true);
      try {
        const { views } = await fetchViews(dbConnection.id, schema);
        setViews(views);
      } catch {
        showError(t("Error fetching views"));
      }
      setIsLoadingViews(false);
    }
  };

  return (
    <>
      <Row mt={0} mb={0}>
        <ExpandButton
          open={open}
          onToggle={async (open) => {
            setOpen(open);
            if (!tables.length) getSchemaTables();
            if (!views.length) getSchemaViews();
          }}
        />
        <Span>{schema}</Span>
      </Row>
      {open && (
        <>
          <Box ml={3} mt={0} mb={0}>
            {isLoading ? (
              <SectionLoading label={t("Getting tables")} />
            ) : tables.length > 0 ? (
              <>
                <SectionHeader label={t("Tables")} />
                <Virtuoso
                  style={{
                    height: `${tables.length * 30}px`,
                    maxHeight: "300px",
                  }}
                  itemHeight={30}
                  totalCount={tables.length}
                  item={(index) => (
                    <TableRow schema={schema} table={tables[index]} />
                  )}
                />
              </>
            ) : (
              <Span color="outline">{t("No tables")}</Span>
            )}
          </Box>
          <Box ml={3} mt={0} mb={0}>
            {isLoadingViews ? (
              <SectionLoading label={t("Getting views")} />
            ) : views.length > 0 ? (
              <>
                <SectionHeader label={t("Views")} />
                {views.map((view) => (
                  <Row>{view}</Row>
                ))}
              </>
            ) : (
              <Span color="outline">{t("No views")}</Span>
            )}
          </Box>
        </>
      )}
    </>
  );
}
