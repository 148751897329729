import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loading, Box, Button, Link, Row } from "../common";
import Table from "../common/Table";

import useAlert from "../../hooks/useAlert";
import useAppsApi from "../../api/appsApi";
import { IApp } from "../../types";

import {} from "../common";
import { useHistory } from "react-router";
import useSubmitting from "../../hooks/useSubmitting";

function AppRow({ app }: { app: IApp }) {
  const { t } = useTranslation("Apps List");
  const { showError } = useAlert();
  const { createJobFromApp } = useAppsApi();
  const history = useHistory();

  const [createJob, isSubmittingCreateJob] = useSubmitting(async () => {
    try {
      const job = await createJobFromApp(app);
      history.push(`/app/jobs/${job.id}`);
    } catch {
      showError(t("Error creating job!"));
    }
  });

  return (
    <tr>
      <td>
        <Link color="link" to={`/app/apps/${app.id}`}>
          {app.name}
        </Link>
      </td>
      <td>{app.isSystemApp ? "The Movement Cooperative" : app.author?.name}</td>
      <td>
        <Button
          isLoading={isSubmittingCreateJob}
          loadingMessage={t("common:creating")}
          onClick={() => createJob()}
        >
          {t("Install")}
        </Button>
      </td>
    </tr>
  );
}

export default function AppsList() {
  const [apps, setApps] = useState<IApp[] | undefined>(undefined);
  const { fetchApps } = useAppsApi();
  const { showError } = useAlert();
  const { t } = useTranslation("Apps List");

  const getJobs = async () => {
    try {
      const apps = await fetchApps();
      setApps(apps);
    } catch {
      showError(t("Error fetching apps!"));
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <Box>
      <Box style={{ textAlign: "right" }}>
        <Link to="/app/addApp">
          <Button size="lg">{t("Create App")}</Button>
        </Link>
      </Box>

      <Box mt={4}>
        {apps ? (
          <Table>
            <thead>
              <tr>
                <th>{t("common:name")}</th>
                <th>{t("common:Created By")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {apps.map((app) => (
                <AppRow key={app.id} app={app} />
              ))}
            </tbody>
          </Table>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}
