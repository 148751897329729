import { IUser } from "../types";
import useRequest from "../hooks/useRequest";

export default function useUserApi() {
  const { get, post, remove } = useRequest();
  return {
    setCurrentUser: async (orgId: string | null) => {
      const { data } = await post<IUser>("/me", { orgId });
      return data;
    },
    fetchUsers: async () => {
      const { data } = await get<IUser[]>("/users");
      return data;
    },
    createUser: async (newUser: IUser) => {
      const { data } = await post<IUser>("/users", newUser);
      return data;
    },
    updateUser: async (id: string, update: IUser) => {
      const { data } = await post<IUser>(`/users/${id}`, update);
      return data;
    },
    removeUser: (id: string) => {
      return remove(`/users/${id}`);
    },
  };
}
