import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import {
  SaveButton,
  CancelButton,
  Row,
  Col,
  Span,
  TextField,
  EditButton,
  ClearButton,
  Chip,
} from "../common";
import { DenseCol } from "../common/Grid";
import { ITextParameter } from "../../types";
import useAlert from "../../hooks/useAlert";
import useParametersApi from "../../api/parametersApi";

export default function EditTextParam({
  parameter,
  onUpdate,
  canEditKey,
  onRemove,
  postUpdate,
}: {
  parameter: ITextParameter;
  onUpdate: (v: ITextParameter) => void;
  canEditKey: boolean;
  onRemove?: (id: string) => void;
  postUpdate: boolean;
}) {
  const [updatedKey, setUpdatedKey] = useState(parameter.name);
  const [updatedValue, setUpdatedValue] = useState(parameter.value);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateParameter } = useParametersApi();
  const { t } = useTranslation("Credentials Page");
  const { showSuccess, showError } = useAlert();

  const colStyles = { overflow: "hidden", textOverflow: "ellipsis" };

  const handleEdit = async () => {
    setIsSubmitting(true);
    try {
      const update: ITextParameter = {
        ...parameter,
        name: canEditKey ? updatedKey : parameter.name,
        value: updatedValue,
      };
      const updatedVar = postUpdate
        ? await updateParameter(parameter.id, update)
        : { ...update, id: parameter.id };
      onUpdate(updatedVar as ITextParameter);
      setIsEditing(false);
      showSuccess(t("Updated parameter!"));
    } catch {
      showError(t("Error updating parameter"));
    }
    setIsSubmitting(false);
  };

  return isEditing ? (
    <Row>
      <Col xs={5} style={{ display: "flex" }}>
        <Span mr={3}>
          <Chip icon={<TextFieldsIcon />} label={t("common:text")} />
        </Span>
        {canEditKey ? (
          <TextField
            id="env-key-input"
            label={t("Key")}
            defaultValue={parameter.name}
            onChange={setUpdatedKey}
            fullWidth
          />
        ) : (
          parameter.name
        )}
      </Col>
      <Col xs={4}>
        <TextField
          id="env-value-input"
          label={t("Value")}
          defaultValue={parameter.value}
          onChange={setUpdatedValue}
          fullWidth
        />
      </Col>
      <Col xs={3}>
        <CancelButton onClick={() => setIsEditing(false)} />{" "}
        <SaveButton onClick={handleEdit} />
      </Col>
    </Row>
  ) : (
    <Row>
      <DenseCol xs={6} style={{ ...colStyles, display: "flex" }}>
        <Span mr={3}>
          <Chip icon={<TextFieldsIcon />} label={t("common:text")} />
        </Span>
        {parameter.name}
      </DenseCol>
      <DenseCol xs={3} style={colStyles}>
        <Span color="outline">{parameter.value}</Span>
      </DenseCol>
      <DenseCol xs={2} style={{ textAlign: "right" }}>
        <EditButton size="small" onClick={() => setIsEditing(true)} />
        {onRemove && (
          <ClearButton
            size="small"
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              onRemove(parameter.id);
            }}
          />
        )}
      </DenseCol>
    </Row>
  );
}
