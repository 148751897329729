import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Form,
  Box,
  TextField,
  TextArea,
  Checkbox,
  SaveButton,
} from "../common";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import { CreateCredentialProps } from "../../api/credentialsApi";

export default function NewCredentialStepOne({
  newCredential,
  onSave,
  onHide,
}: {
  newCredential: CreateCredentialProps;
  onSave: (name: string, description: string, shared: boolean) => void;
  onHide: () => void;
}) {
  const [credName, setCredName] = useState(newCredential.name || "");
  const [credDescription, setCredDescription] = useState(
    newCredential.description || ""
  );
  const [isShared, setIsShared] = useState(newCredential.shared || false);
  const { t } = useTranslation("Credentials Page");

  return (
    <>
      <ModalHeader onClose={onHide}>
        <ModalTitle>{t("Add a new credential")}</ModalTitle>
        <Box>{t("New credential form helper text")}</Box>
      </ModalHeader>

      <Form
        onSubmit={() => {
          if (credName) onSave(credName, credDescription, isShared);
        }}
      >
        <ModalBody>
          <Box mb={3}>
            <TextField
              id="credential-name-input"
              placeholder={t("Add a helpful name for your credential")}
              label={t("common:name")}
              value={credName}
              required
              style={{ width: "65%" }}
              onChange={setCredName}
            />
          </Box>

          <Box mb={4}>
            <TextArea
              id="credential-description-input"
              placeholder={t(
                "Add a helpful description that lets your team know how this credential can be used"
              )}
              label={t("Description")}
              value={credDescription}
              fullWidth
              rows={3}
              onChange={setCredDescription}
            />
          </Box>

          <Box>
            <Checkbox
              checked={isShared}
              label={t("Share with your team")}
              onChange={setIsShared}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <SaveButton
            disabled={!credName}
            label={t("common:next")}
            submitForm
          />
        </ModalFooter>
      </Form>
    </>
  );
}
