import useRequest from "../hooks/useRequest";
import { IDBQuery, IJob } from "../types";

export interface IColumn {
  name: string;
  type: string;
}

export default function useDbQueryApi() {
  const { get, post } = useRequest();

  return {
    runQuery: async (queryId: string, credentialId: string) => {
      const { data } = await post<{
        results: object[];
      }>(`/db_queries/preview`, { queryId, credentialId });
      return data;
    },

    createQuery: async (postData: { queryString: string }) => {
      const { data } = await post<IDBQuery>("/db_queries", postData);
      return data;
    },

    createQueryJob: async (
      queryId: string,
      connectionId: string,
      name: string
    ) => {
      const { data } = await post<IJob>("/db_queries/jobs", {
        queryId,
        connectionId,
        name,
      });
      return data;
    },

    fetchQueries: async () => {
      const { data } = await get<IDBQuery[]>("/db_queries");
      return data;
    },

    fetchQuery: async (queryId: string) => {
      const { data } = await get<IDBQuery>(`/db_queries/${queryId}`);
      return data;
    },

    fetchSchemas: async (credentialId: string) => {
      const { data } = await get<{ schemas: string[] }>(
        `/db_queries/${credentialId}/schemas`
      );
      return data;
    },

    fetchTables: async (credentialId: string, schema: string) => {
      const { data } = await get<{ tables: string[] }>(
        `/db_queries/${credentialId}/schema/${schema}/tables`
      );
      return data;
    },

    fetchViews: async (credentialId: string, schema: string) => {
      const { data } = await get<{ views: string[] }>(
        `/db_queries/${credentialId}/schema/${schema}/views`
      );
      return data;
    },

    fetchColumns: async (
      credentialId: string,
      schema: string,
      table: string
    ) => {
      const { data } = await get<{ columns: IColumn[] }>(
        `/db_queries/${credentialId}/schema/${schema}/tables/${table}/columns`
      );
      return data;
    },
  };
}
