import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SaveButton,
  Box,
  Button,
  AsyncAutocomplete,
  Form,
  Row,
  Col,
} from "../common";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import useCredentialsApi from "../../api/credentialsApi";
import { ICredentialTemplate } from "../../types";

export default function NewCredentialTypeForm({
  navigate,
  onSelectTemplate,
  onHide,
}: {
  navigate: (step: string) => void;
  onSelectTemplate: (template: ICredentialTemplate) => void;
  onHide: () => void;
}) {
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<ICredentialTemplate | null>(null);
  const [next, setNext] = useState("");
  const { fetchCredentialTemplates } = useCredentialsApi();
  const { t } = useTranslation("Credentials Page");

  return (
    <>
      <ModalHeader onClose={onHide}>
        <ModalTitle>
          {t("What type of credential do you want to create?")}
        </ModalTitle>
        <Box>
          {t("Add your own custom parameters or select an existing template.")}
        </Box>
      </ModalHeader>
      <Form
        onSubmit={() => {
          if (next) return navigate(next);
          if (selectedTemplate) {
            onSelectTemplate(selectedTemplate);
            return navigate("template");
          }
        }}
      >
        <ModalBody>
          <Row>
            <Col xs={10}>
              <AsyncAutocomplete
                id="credential-template-selectbox"
                label={t("Select credential type")}
                onChange={(_, option) => {
                  setSelectedTemplate(null);
                  setNext("");
                  if (option) {
                    if (option.navigate) return setNext(option.navigate);
                    return setSelectedTemplate(option);
                  }
                }}
                fetch={async () => {
                  const templates = await fetchCredentialTemplates();
                  const list = [
                    {
                      label: t("Add my own parameters"),
                      data: { navigate: "generic" },
                    },
                  ];
                  const templateListItems = templates.map((template) => {
                    return { label: template.name, data: template };
                  });
                  return [...list, ...templateListItems];
                }}
              />
            </Col>
            <Col></Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={() => navigate("details")}>
            {t("Back to details")}
          </Button>
          <SaveButton
            submitForm
            disabled={!next && !selectedTemplate}
            label={t("common:next")}
          />
        </ModalFooter>
      </Form>
    </>
  );
}
