import React, { useState } from "react";
import useDbQueryApi, { IColumn } from "../../api/dbQueryApi";
import useDBConnection from "../../hooks/useDBConnection";
import { Row, ExpandButton, Span, Box } from "../common";
import useAlert from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import { DenseCol } from "../common/Grid";
import { SectionLoading, SectionHeader } from "./SchemaTree";

export default function TableRow({
  schema,
  table,
}: {
  schema: string;
  table: string;
}) {
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchColumns } = useDbQueryApi();
  const { dbConnection } = useDBConnection();
  const { showError } = useAlert();
  const { t } = useTranslation("SQL Browser");

  const getColumns = async () => {
    if (dbConnection) {
      setIsLoading(true);
      try {
        const { columns } = await fetchColumns(dbConnection.id, schema, table);
        setColumns(columns);
      } catch {
        showError("Error fetching columns");
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row mt={0} mb={0}>
        <ExpandButton
          open={open}
          onToggle={async (open) => {
            setOpen(open);
            if (!columns.length) await getColumns();
          }}
        />
        <Span>{table}</Span>
      </Row>
      {open && (
        <Box ml={3} mt={0} mb={0}>
          {isLoading ? (
            <SectionLoading label={t("Getting columns")} />
          ) : columns.length > 0 ? (
            <>
              <SectionHeader label={t("Columns")} />
              {columns.map((column) => (
                <Row mt={1} mb={1} wrap="nowrap">
                  <DenseCol>{column.name}</DenseCol>
                  <DenseCol>
                    <Span color="outline" fontStyle="italic">
                      {column.type}
                    </Span>
                  </DenseCol>
                </Row>
              ))}
            </>
          ) : (
            <Span color="outline">{t("No columns")}</Span>
          )}
        </Box>
      )}
    </>
  );
}
