import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import useJobsApi from "../api/jobApi";
import { JobContext } from "../contexts/jobContext";
import { IJob } from "../types";
import useAlert from "./useAlert";

export default function useJob() {
  const { job, setJob, runsPage, setRunsPage } = useContext(JobContext);
  const { fetchJob } = useJobsApi();
  const { showError } = useAlert();
  const { t } = useTranslation("Job Page");

  const jobRef = useRef(job);
  const runsPageRef = useRef(runsPage);

  const updateJob = (j: IJob) => {
    jobRef.current = j;
    setJob(j);
  };

  return {
    job,
    setJob: updateJob,
    runsPage,
    setRunsPage: (page: number) => {
      runsPageRef.current = page;
      setRunsPage(page);
    },
    refresh: async () => {
      if (jobRef.current) {
        const { current } = jobRef;
        try {
          const update = await fetchJob(current.id, {
            runsPage: runsPageRef.current,
          });
          updateJob(update);
        } catch {
          showError(t("Error fetching job"));
        }
      }
    },
  };
}
