import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import {
  SaveButton,
  CancelButton,
  Box,
  Row,
  Col,
  TextField,
  Form,
  Autocomplete,
  Button,
  Chip,
} from "../common";
import { ITextParameter, IPasswordParameter } from "../../types";

type TParams = ITextParameter | IPasswordParameter;

export default function AddParametersForm({
  onSave,
  submitForm,
  isAddingVar,
  setIsAddingVar,
}: {
  onSave?: ((newVar: TParams) => Promise<void>) | ((newVar: TParams) => void);
  submitForm: boolean;
  isAddingVar: boolean;
  setIsAddingVar?: (isAdding: boolean) => void;
}) {
  const [selectedParamType, setSelectedParamType] = useState<
    "text" | "password" | null
  >("text");
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmParamType, setConfirmParamType] = useState(false);

  const { t } = useTranslation("Credentials Page");

  const handleNewParameter = async () => {
    if (selectedParamType) {
      setIsSubmitting(true);
      if (setIsAddingVar) setIsAddingVar(true);
      const newVar: TParams = {
        id: newKey,
        name: newKey,
        parameterDefinitionId: null,
        type: selectedParamType,
        value: newValue,
      };
      if (onSave) await onSave(newVar);
      resetForm();
    }
  };

  const resetForm = () => {
    setIsSubmitting(false);
    setNewKey("");
    setNewValue("");
    setSelectedParamType("text");
    setConfirmParamType(false);
    if (setIsAddingVar) setIsAddingVar(false);
  };

  const renderFields = () => {
    switch (selectedParamType) {
      case "text":
        return (
          <>
            <Row>
              <Col style={{ display: "flex" }}>
                <Chip icon={<TextFieldsIcon />} label={t("common:text")} />

                <TextField
                  id="env-key-input"
                  label={t("Key")}
                  required
                  onChange={setNewKey}
                  fullWidth
                  value={newKey}
                />
              </Col>
              <Col>
                <TextField
                  id="env-value-input"
                  label={t("Value")}
                  required
                  onChange={setNewValue}
                  fullWidth
                  value={newValue}
                />
              </Col>
            </Row>

            <Box>
              <SaveButton
                isLoading={isSubmitting}
                submitForm={submitForm}
                onClick={!submitForm ? handleNewParameter : undefined}
                label={t("common:add")}
              />
              <CancelButton onClick={resetForm} />
            </Box>
          </>
        );
      case "password":
        return (
          <>
            <Row>
              <Col style={{ display: "flex" }}>
                <Chip icon={<VpnKeyIcon />} label={t("common:password")} />
                <TextField
                  id="env-key-input"
                  label={t("Key")}
                  required
                  onChange={setNewKey}
                  fullWidth
                  value={newKey}
                />
              </Col>
              <Col>
                <TextField
                  id="env-value-input"
                  label={t("Value")}
                  required
                  onChange={setNewValue}
                  fullWidth
                  value={newValue}
                />
              </Col>
            </Row>

            <Box>
              <SaveButton
                isLoading={isSubmitting}
                submitForm={submitForm}
                onClick={!submitForm ? handleNewParameter : undefined}
                label={t("common:add")}
              />
              <CancelButton onClick={resetForm} />
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const renderForm = () => {
    return submitForm ? (
      <Form onSubmit={handleNewParameter}>{renderFields()}</Form>
    ) : (
      renderFields()
    );
  };

  if (!isAddingVar) return null;

  return confirmParamType ? (
    renderForm()
  ) : (
    <Row>
      <Col>
        <Autocomplete
          id="parameter-type-selectbox"
          label={t("Select parameter type")}
          defaultValue={{ label: t("common:text"), data: "text" }}
          filterSelectedOptions={false}
          options={[
            { label: t("common:text"), data: "text" },
            { label: t("common:password"), data: "password" },
          ]}
          onChange={(_, selected) => {
            if (selected) setSelectedParamType(selected);
          }}
        />
      </Col>
      <Col>
        <Button
          disabled={!selectedParamType}
          onClick={() => setConfirmParamType(true)}
        >
          {t("common:select")}
        </Button>
        {setIsAddingVar && (
          <CancelButton onClick={() => setIsAddingVar(false)} />
        )}
      </Col>
    </Row>
  );
}
