import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useAlert from "../../hooks/useAlert";
import { IDBQuery } from "../../types";
import useDbQueryApi from "../../api/dbQueryApi";
import useDBConnection from "../../hooks/useDBConnection";

import { Button, Box, Span, Row, Loading } from "../common";
import SQLQueryForm from "./SQLQueryForm";
import QueryResultsTable from "./QueryResultsTable";
import QueryHistory from "./QueryHistory";
import HistoryIcon from "@material-ui/icons/History";
import SchemaTree from "./SchemaTree";
import { PinnedSidebar } from "../common/Drawer";
import { RouteComponentProps, useHistory } from "react-router";

const MainContainer = styled.main`
  flex-grow: 1;
  background-color: white;
  padding: 2em;
  max-width: 1000px;
`;

export default function SQLBrowserPage({
  match,
}: RouteComponentProps<{ queryId?: string }>) {
  const defaultQueryString = "";
  const { queryId } = match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [newQuery, setNewQuery] = useState(defaultQueryString);
  const [isSubmittingQuery, setIsSubmittingQuery] = useState(false);
  const [querySuccessful, setQuerySuccessful] = useState(false);
  const [rows, setRows] = useState<object[]>([]);
  const [queries, setQueries] = useState<IDBQuery[] | null>(null);
  const [selectedQuery, setSelectedQuery] = useState<IDBQuery | null>(null);
  const [showHistory, setShowHistory] = useState(false);

  const { dbConnection } = useDBConnection();
  const { runQuery, fetchQuery, fetchQueries, createQuery } = useDbQueryApi();
  const { t } = useTranslation("SQL Browser");
  const { showSuccess, showError } = useAlert();
  const history = useHistory();

  const loadQuery = async () => {
    setNewQuery(defaultQueryString);
    setSelectedQuery(null);
    if (queryId) {
      setIsLoading(true);
      try {
        const query = await fetchQuery(queryId);
        setNewQuery(query.queryString);
        setSelectedQuery(query);
      } catch {
        showError(t("Error fetching query"));
      }
    }
    setIsLoading(false);
  };

  const fetchQueryHistory = async () => {
    const queries = await fetchQueries();
    setQueries(queries);
  };

  useEffect(() => {
    loadQuery();
    fetchQueryHistory();
  }, [queryId]);

  const handleSubmit = async (options?: { query: IDBQuery }) => {
    if (dbConnection) {
      setIsSubmittingQuery(true);
      setQuerySuccessful(false);
      setRows([]);
      const queryString = options?.query.queryString || newQuery;
      const savedQuery = selectedQuery || (await createQuery({ queryString }));
      try {
        const { results } = await runQuery(savedQuery.id, dbConnection.id);
        showSuccess(t("Success running query"));
        setRows(results);
        setQuerySuccessful(true);
        fetchQueryHistory();
      } catch {
        showError(t("Error running query"));
      }
      setIsSubmittingQuery(false);
    } else {
      showError(t("Please select a database credential first"));
    }
  };

  return (
    <>
      <PinnedSidebar>
        <SchemaTree />
      </PinnedSidebar>

      <MainContainer>
        <Row justify="space-between">
          <h1>{t("SQL Query Browser")}</h1>
          {queries && (
            <Box>
              <Button variant="link" onClick={() => setShowHistory(true)}>
                <HistoryIcon />
                <Span ml={1}>{t("View query history")} </Span>
              </Button>
            </Box>
          )}
        </Row>

        {isLoading ? (
          <Loading />
        ) : (
          <SQLQueryForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmittingQuery}
            queryValue={newQuery}
            onChangeQueryValue={(val) => {
              setNewQuery(val);
              setSelectedQuery(null);
            }}
            query={selectedQuery}
          />
        )}

        {queries && (
          <QueryHistory
            queries={queries}
            show={showHistory}
            onClose={() => setShowHistory(false)}
            onClickItem={(query: IDBQuery) => {
              setShowHistory(false);
              history.push(`/data/browser/${query.id}`);
            }}
          />
        )}

        <QueryResultsTable
          rows={rows}
          isSubmitting={isSubmittingQuery}
          querySuccessful={querySuccessful}
        />
      </MainContainer>
    </>
  );
}
