import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import { IUser } from "../types";
import useUserApi from "../api/userApi";

// Configure Firebase.
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
});

interface IState {
  parsonsUiUser: IUser | null;
  firebaseUser: firebase.User | null;
  isLoading: boolean;
  token: string | null;
  expirationTime: string | null;
  claims: {
    org_id: string | null;
  };
}

export async function logout() {
  await firebase.auth().signOut();
}

export default function useAuth() {
  const [authState, setAuthState] = useState<IState>({
    parsonsUiUser: null,
    firebaseUser: null,
    isLoading: true,
    token: null,
    expirationTime: null,
    claims: {
      org_id: null,
    },
  });
  const { setCurrentUser } = useUserApi();
  const orgId = new URLSearchParams(window.location.search).get("orgId");

  const handleAuthStateChange = async (firebaseUser: firebase.User | null) => {
    if (firebaseUser) {
      const parsonsUiUser = await setCurrentUser(orgId);

      setAuthState({
        ...authState,
        firebaseUser,
        isLoading: false,
        parsonsUiUser,
      });
    } else {
      setAuthState({ ...authState, isLoading: false });
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(handleAuthStateChange);
    return () => unregisterAuthObserver();
  }, []);

  return {
    authState,
    authActions: {
      updateToken: (
        token: string,
        expirationTime: string,
        claims: { org_id: string }
      ) => {
        setAuthState({ ...authState, token, expirationTime, claims });
      },
    },
  };
}
