import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";

import { IApp, ILink } from "../../types";
import useAppsApi from "../../api/appsApi";
import LoadingScreen from "../common/LoadingScreen";
import { Col, Link, List, ListItem, Row } from "../common";
import ScriptForm from "../jobs/ScriptForm";
import AppPageHeader from "./AppPageHeader";
import AppParameterDefinitions from "./AppParameterDefinitions";
import useAuth from "../../hooks/useAuth";
import AppDescriptionForm from "./AppDescriptionForm";

type TParams = { appId: string };

function JobListItem({ job }: { job: ILink }) {
  return (
    <ListItem
      showDivider
      label={<Link to={`/app/jobs/${job.id}`}>{job.name}</Link>}
    />
  );
}

export default function AppPage({ match }: RouteComponentProps<TParams>) {
  const { appId } = match.params;
  const [app, setApp] = useState<IApp | null>(null);
  const { t } = useTranslation("App Page");
  const {
    authState: { parsonsUiUser },
  } = useAuth();
  const canEdit = parsonsUiUser?.orgId === app?.owner?.id;

  const { fetchApp } = useAppsApi();
  const fetch = async () => {
    const app = await fetchApp(appId);
    return setApp(app);
  };
  useEffect(() => {
    fetch();
  }, []);

  if (app === null) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AppPageHeader app={app} refreshApp={fetch} canEdit={canEdit} />

      <section>
        {/* <Box> */}
        <AppDescriptionForm app={app} refresh={fetch} canEdit={canEdit} />
        {/* </Box> */}
      </section>

      <section>
        {canEdit && (
          <Row>
            <Col>
              <ScriptForm scriptId={app.script.id} script={app.script} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <AppParameterDefinitions
              canEdit={canEdit}
              app={app}
              refreshApp={fetch}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Jobs</h3>
            <List>
              {app.jobs.length > 0 ? (
                app.jobs.map((job) => <JobListItem job={job} key={job.id} />)
              ) : (
                <ListItem showDivider label={t("No jobs using this app")} />
              )}
            </List>
          </Col>
        </Row>
      </section>
    </>
  );
}
