import useRequest from "../hooks/useRequest";

import { IJobRun, IRunLog } from "../types";

export default function useJobRunApi() {
  const { get, post } = useRequest();

  return {
    createRun: async (job_id: string) => {
      const { data } = await post<IJobRun>("/runs", { job_id });
      return data;
    },

    fetchRun: async (runId: string) => {
      const { data } = await get<IJobRun>(`/runs/${runId}`);
      return data;
    },

    fetchLog: async (id: string) => {
      const { data } = await get<IRunLog>(`runs/${id}/log`);
      return data;
    },

    cancelRun: async (runId: string) => {
      const { data } = await post<IJobRun[]>(`runs/cancel`, {
        run_id: runId,
      });
      return data;
    },

    createSQLExport: async (jobId: string) => {
      const { data } = await post<IJobRun>(`/runs/sql_export`, {
        jobId,
      });
      return data;
    },
  };
}
