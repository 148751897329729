import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Form,
  SaveButton,
  CancelButton,
  TextField,
  Col,
  Row,
  Checkbox,
  TextArea,
} from "../../common";
import {
  ICreateTextParamDefinition,
  IParameterDefinition,
} from "../../../types/ParameterDefinitions";
import useSubmitting from "../../../hooks/useSubmitting";

interface ITextParamDefFields {
  label: string;
  defaultKeyName: string;
  defaultValue: string | null;
  required: boolean;
  description: string | null;
}

function TextParamDefFields({
  paramDef,
  setParamDef,
}: {
  paramDef: ITextParamDefFields;
  setParamDef: (p: ITextParamDefFields) => void;
}) {
  const { t } = useTranslation("App Page");

  return (
    <>
      <Row>
        <TextField
          id="label-input"
          label={t("Label")}
          fullWidth
          required
          helperText={t(
            "Add a helpful label for this parameter to help your team know what it's for."
          )}
          value={paramDef.label}
          onChange={(label) => {
            setParamDef({
              ...paramDef,
              label,
            });
          }}
        />
      </Row>
      <Row>
        <TextArea
          rows={4}
          fullWidth
          id="description-input"
          label={t("Description")}
          value={paramDef.description}
          onChange={(description) => {
            setParamDef({
              ...paramDef,
              description,
            });
          }}
          helperText={t(
            "Add a description for this parameter to help your team know how to use it."
          )}
        />
      </Row>
      <Row>
        <TextField
          id="default-keyname-input"
          label={t("Default Key Name")}
          value={paramDef.defaultKeyName}
          fullWidth
          required
          helperText={t(
            "Set the key name that your app is expecting for this parameter."
          )}
          onChange={(defaultKeyName) =>
            setParamDef({
              ...paramDef,
              defaultKeyName,
            })
          }
        />
      </Row>
      <Row>
        <TextField
          id="default-value-input"
          label={t("Default Value")}
          fullWidth
          value={paramDef.defaultValue || ""}
          helperText={t("Set an optional default value for this parameter.")}
          onChange={(defaultValue) =>
            setParamDef({
              ...paramDef,
              defaultValue,
            })
          }
        />
      </Row>
      <Row>
        <Checkbox
          checked={paramDef.required}
          label={t("common:required")}
          onChange={(required) =>
            setParamDef({
              ...paramDef,
              required,
            })
          }
        />
      </Row>
    </>
  );
}

export function AddTextParamDef({
  onSave,
  onClose,
}: {
  onSave: (paramDef: ICreateTextParamDefinition) => Promise<void>;
  onClose: () => void;
}) {
  const [formState, setFormState] = useState<ITextParamDefFields>({
    label: "",
    defaultKeyName: "",
    defaultValue: "",
    required: false,
    description: null,
  });
  const { t } = useTranslation("App Page");

  const [addParameterDefinition, isSubmitting] = useSubmitting(async () => {
    await onSave({
      ...formState,
      type: "text",
    });
  });

  return (
    <Form onSubmit={addParameterDefinition}>
      <Row mt={3} mb={3}>
        <Col data-i18n-key="textParameterDefinitionHelperText">
          {t("textParameterDefinitionHelperText")}
        </Col>
      </Row>

      <TextParamDefFields paramDef={formState} setParamDef={setFormState} />

      <Row direction="row-reverse">
        <CancelButton onClick={onClose} />

        <SaveButton
          isLoading={isSubmitting}
          onClick={addParameterDefinition}
          label={t("common:add")}
          disabled={!formState.label || !formState.defaultKeyName}
        />
      </Row>
    </Form>
  );
}

export function EditTextParamDef({
  paramDef,
  onUpdate,
  onCancel,
}: {
  paramDef: IParameterDefinition;
  onUpdate: (p: IParameterDefinition) => Promise<void>;
  onCancel: () => void;
}) {
  if (paramDef.type !== "text")
    throw new Error("Incorrect param def type for component");

  const [formState, setFormState] = useState({
    label: paramDef.label,
    defaultKeyName: paramDef.defaultKeyName,
    defaultValue: paramDef.defaultValue,
    required: paramDef.required,
    description: paramDef.description,
  });
  const { t } = useTranslation("App Page");

  const [submitUpdate, isSubmittingUpdate] = useSubmitting(async () => {
    await onUpdate({ ...paramDef, ...formState });
  });

  return (
    <Form onSubmit={submitUpdate}>
      <TextParamDefFields paramDef={formState} setParamDef={setFormState} />

      <Row direction="row-reverse">
        <CancelButton onClick={onCancel} />

        <SaveButton
          isLoading={isSubmittingUpdate}
          submitForm
          onClick={submitUpdate}
          label={t("common:save")}
        />
      </Row>
    </Form>
  );
}
