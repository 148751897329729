import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  EditButton,
  LinearProgress,
  TextField,
} from "../common";
import useJobsApi from "../../api/jobApi";
import useAlert from "../../hooks/useAlert";
import useJobRunApi from "../../api/jobRunApi";
import useSubmitting from "../../hooks/useSubmitting";
import { statusCodeMap } from "../../constants";
import VertMenu, { VertMenuItem } from "../common/VertMenu";
import useJob from "../../hooks/useJob";
import { mapParamsByParamDef, isValidAppJob } from "./AppJobParameterForm";

export function JobPageHeader({
  cancel,
  isCanceling,
}: {
  cancel: (runId: string) => Promise<void>;
  isCanceling: boolean;
}) {
  const { job, setJob, refresh } = useJob();
  const { t } = useTranslation("Job Page");
  const [isEditingName, setIsEditingName] = useState(false);
  const [updatedName, setUpdatedName] = useState(job?.name || "");
  const { showError, showWarning, showSuccess } = useAlert();
  const { removeJob, updateJob } = useJobsApi();
  const { createRun } = useJobRunApi();
  const history = useHistory();
  const isRunnable = job?.app
    ? isValidAppJob(
        job.app.parameterDefinitions,
        mapParamsByParamDef(job.parameters, job.app.parameterDefinitions)
      )
    : true;

  const [addJobRun, isSubmittingRun] = useSubmitting(async () => {
    if (job) {
      try {
        await createRun(job.id);
        showSuccess(t("Starting run of", { jobName: job.name }));
        refresh();
      } catch {
        showError(t("Error adding job run!"));
      }
    }
  });

  const [handleRemove, isSubmittingRemove] = useSubmitting(async () => {
    if (job) {
      try {
        await removeJob(job.id);
        showWarning(t("Job removed", { jobName: job.name }));
        history.push("/");
      } catch {
        showError(t("Error removing job"));
      }
    }
  });

  const [saveName, isSubmittingUpdate] = useSubmitting(async () => {
    if (job) {
      const newJob = { ...job, name: updatedName };
      try {
        const update = await updateJob(job.id, newJob);
        setJob(update);
        showSuccess(t("Job name updated!"));
      } catch {
        showError(t("Error updating job name!"));
      } finally {
        setIsEditingName(false);
      }
    }
  });

  if (!job) return null;

  const cancelLatest = async () => {
    if (!job || !job.latestRun) return;
    cancel(job.latestRun.id);
  };

  const latestRunStatus = job.latestRun ? job.latestRun.statusCode : undefined;
  const runStatusVariant = latestRunStatus
    ? statusCodeMap[latestRunStatus].variant
    : undefined;
  const isRunning =
    job.latestRun &&
    (job.latestRun.statusCode === 0 || job.latestRun.statusCode === 1);

  return (
    <>
      <Box display="flex">
        <h1 style={{ flexGrow: 1 }}>
          {isEditingName ? (
            <>
              <TextField
                id="job-name"
                fontSize="2.5rem"
                label="Name"
                onChange={setUpdatedName}
                value={updatedName}
              />
              <Box display="inline-block" ml={2}>
                <Button isLoading={isSubmittingUpdate} onClick={saveName}>
                  {t("common:save")}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setIsEditingName(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <span>{updatedName}</span>
              <EditButton onClick={() => setIsEditingName(true)} />
            </>
          )}
        </h1>
        <Box>
          <VertMenu>
            <VertMenuItem onClick={handleRemove} disabled={isSubmittingRemove}>
              {t("Remove Job")}
            </VertMenuItem>
          </VertMenu>
        </Box>
      </Box>
      {isRunning ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Divider size="tall" variant={runStatusVariant} />
      )}
      <Box mb={3}>
        {isRunning ? (
          <>
            <Button
              mr={3}
              size="lg"
              isLoading
              loadingMessage={t("Running")}
              onClick={() => addJobRun()}
            >
              {t("Run Now")}
            </Button>
            <Button
              mr={3}
              size="lg"
              isLoading={isCanceling}
              loadingMessage={t("Canceling")}
              onClick={cancelLatest}
              variant="warning"
            >
              {t("Cancel Run")}
            </Button>
          </>
        ) : (
          <>
            <Button
              mr={3}
              size="lg"
              isLoading={isSubmittingRun}
              loadingMessage={t("Running")}
              onClick={() => addJobRun()}
              disabled={!isRunnable}
            >
              {t("Run Now")}
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
