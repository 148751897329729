import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ICredentialTemplate, TUpsertCredParameter } from "../../types";
import {
  ModalFooter,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import {
  Form,
  Row,
  Col,
  TextField,
  SaveButton,
  Button,
  Box,
  Autocomplete,
  Span,
} from "../common";
import { IParameterDefinition } from "../../types/ParameterDefinitions";

function generateParameter(
  paramDef: IParameterDefinition
): TUpsertCredParameter | undefined {
  const paramBase = {
    id: "",
    parameterDefinitionId: paramDef.id,
  };
  switch (paramDef.type) {
    case "text":
      return {
        ...paramBase,
        type: "text",
        name: paramDef.defaultKeyName,
        value: paramDef.defaultValue || "",
      };
    case "password":
      return {
        ...paramBase,
        type: "password",
        name: paramDef.defaultKeyName,
        value: "",
      };
    case "option":
      return {
        ...paramBase,
        type: "option",
        name: paramDef.defaultKeyName,
        value: "",
      };
    default:
      return undefined;
  }
}

export default function NewCredentialTemplateForm({
  template,
  stepBack,
  stepBackLabel,
  onFinish,
  onHide,
}: {
  template: ICredentialTemplate;
  stepBack: () => void;
  stepBackLabel?: string;
  onFinish: (vars: TUpsertCredParameter[]) => void;
  onHide: () => void;
}) {
  const [formState, setFormState] = useState(
    new Map(
      template.parameterDefinitions.map((paramDef) => [
        paramDef.id,
        generateParameter(paramDef),
      ])
    )
  );
  const [githubUsername, setGithubUsername] = useState("");
  const [githubToken, setGithubToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("Credentials Page");
  const isGitCredential = template.systemId === "GIT_CREDENTIALS";

  const renderParamFields = (paramDef: IParameterDefinition) => {
    switch (paramDef.type) {
      case "text":
      case "password":
        return (
          <Row key={paramDef.id} alignItems="center">
            <Col xs={2}>{t(paramDef.label)}</Col>
            <Col xs={5}>
              <TextField
                id="env-key-input"
                label={t("Key")}
                defaultValue={paramDef.defaultKeyName}
                required={paramDef.required}
                onChange={(name) => {
                  const parameter = formState.get(paramDef.id);
                  if (
                    parameter &&
                    (parameter.type === "text" || parameter.type === "password")
                  ) {
                    parameter.name = name;
                    const update = new Map(
                      formState.set(paramDef.id, parameter)
                    );
                    setFormState(update);
                  }
                }}
              />
            </Col>
            <Col xs={5}>
              <TextField
                id="env-value-input"
                label={t("Value")}
                required
                onChange={(value) => {
                  const parameter = formState.get(paramDef.id);
                  if (parameter) {
                    const update = new Map(
                      formState.set(paramDef.id, {
                        ...parameter,
                        value,
                      })
                    );
                    setFormState(update);
                  }
                }}
              />
            </Col>
          </Row>
        );
      case "option":
        return (
          <Row key={paramDef.id} alignItems="center">
            <Col xs={2}>{t(paramDef.label)}</Col>
            <Col xs={5}>
              <TextField
                id="env-key-input"
                label={t("Key")}
                defaultValue={paramDef.defaultKeyName}
                required={paramDef.required}
                onChange={(name) => {
                  const parameter = formState.get(paramDef.id);
                  if (parameter) {
                    parameter.name = name;
                    const update = new Map(
                      formState.set(paramDef.id, parameter)
                    );
                    setFormState(update);
                  }
                }}
              />
            </Col>
            <Col xs={5}>
              <Col>
                <Autocomplete
                  id="parameter-option-selectbox"
                  label={t("Select value")}
                  required
                  options={paramDef.options.map((option) => {
                    return {
                      label: option,
                      data: option,
                    };
                  })}
                  onChange={(_, selected) => {
                    const parameter = formState.get(paramDef.id);
                    if (parameter) {
                      const update = new Map(
                        formState.set(paramDef.id, {
                          ...parameter,
                          value: selected,
                        })
                      );
                      setFormState(update);
                    }
                  }}
                />
              </Col>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  const renderGitCredFields = () => {
    return (
      <>
        <Box mb="2">
          <TextField
            id="username-input"
            label={t("Github Username")}
            required
            fullWidth
            onChange={(value) => {
              setGithubUsername(value);
            }}
          />
        </Box>

        <Box mb="2">
          <TextField
            id="token-input"
            label={t("Github Access Token")}
            required
            fullWidth
            onChange={(value) => {
              setGithubToken(value);
            }}
          />
        </Box>
        <Span color="outline">
          {t("Requires a")}{" "}
          <a
            href="https://docs.github.com/en/github/authenticating-to-github/keeping-your-account-and-data-secure/creating-a-personal-access-token"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("personal access token")}
          </a>
          .
        </Span>
      </>
    );
  };

  return (
    <>
      <ModalHeader onClose={onHide}>
        <ModalTitle>
          {t("Set template parameters", {
            templateName: template ? template.name : null,
          })}
        </ModalTitle>
        <Box mb={3}>{t("Fill in all required values for this template.")}</Box>
      </ModalHeader>
      <Form
        onSubmit={async () => {
          setIsSubmitting(true);
          // filter out any possible undefined parameters
          const fields = [...formState.values()].filter(
            (param) => param
          ) as TUpsertCredParameter[];

          if (isGitCredential && githubUsername && githubToken) {
            const paramDef = template.parameterDefinitions[0];
            const param = fields.find(
              (field) => field.parameterDefinitionId === paramDef.id
            );
            if (param) param.value = `${githubUsername}:${githubToken}`;
          }

          await onFinish(fields);
          setIsSubmitting(false);
        }}
      >
        <ModalBody>
          <Box mb={3}>
            {isGitCredential
              ? renderGitCredFields()
              : template.parameterDefinitions.map((paramDef) =>
                  renderParamFields(paramDef)
                )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={stepBack}>
            {stepBackLabel || t("common:back")}
          </Button>
          <SaveButton
            label={t("Create Credential")}
            submitForm
            isLoading={isSubmitting}
          />
        </ModalFooter>
      </Form>
    </>
  );
}
