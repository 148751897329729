import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Box, List, ListItem } from "../common";
import { IDBQuery } from "../../types";
import { IconButton } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import { useHistory } from "react-router";

export default function QueryHistory({
  queries,
  show,
  onClickItem,
  onClose,
}: {
  queries: IDBQuery[];
  show: boolean;
  onClickItem: (i: IDBQuery) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation("SQL Browser");
  const history = useHistory();

  return (
    <Drawer open={show} anchor="right" onClose={onClose} showCloseButton>
      <Box>
        <h3>{t("Query History")}</h3>
        <List dense={false}>
          {queries.map((query) => (
            <ListItem
              key={query.id}
              label={query.queryString}
              showDivider
              actions={
                <IconButton onClick={() => onClickItem(query)}>
                  <ReplayIcon />
                </IconButton>
              }
            />
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
