import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  SaveButton,
  Form,
  AsyncAutocomplete,
  TextField,
} from "../common";
import { IJob, IUser, IScript, IApp } from "../../types";
import useJobsApi from "../../api/jobApi";
import useAlert from "../../hooks/useAlert";
import ScriptFields from "./ScriptFields";
import useAppsApi from "../../api/appsApi";

const newScript: IScript = {
  id: "",
  gitRepository: "",
  gitBranch: "",
  containerImage: "",
  commands: [],
  gitCredential: null,
};

const newJob = {
  id: "",
  name: "",
  script: newScript,
  app: null,
  cronSchedule: "",
  cronScheduleEnd: "",
  author: {
    id: "",
    name: "",
  },
  runCount: 0,
  created_at: "",
  credentials: [],
  tags: [],
  parameters: [],
  type: "container",
  notifications: [],
  resourceType: "job",
};

export default function NewJobPage({ user }: { user: IUser }) {
  const [job, setJob] = useState<IJob>(newJob);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showError, showSuccess } = useAlert();
  const { createJob } = useJobsApi();
  const { fetchApps } = useAppsApi();
  const history = useHistory();
  const { t } = useTranslation(["Job Page"]);

  const updateField = (fieldName: keyof IJob, value: string) => {
    setJob({
      ...(job as IJob),
      [fieldName]: value,
    });
  };

  const updateApp = (app: IApp | null) => {
    setJob({
      ...(job as IJob),
      app,
    });
  };

  const updateScript = (script: IScript | null) => {
    setJob({
      ...(job as IJob),
      script,
    });
  };

  const useScript = () => {
    updateScript(newScript);
  };

  const useApp = () => {
    updateScript(null);
  };

  const handleSave = () => {
    setIsSubmitting(true);
    const data = {
      ...(job as IJob),
      org_id: user.orgId,
    };
    createJob(data)
      .then((newJob) => {
        showSuccess(t("Job created", { jobName: newJob.name }));
        setIsSubmitting(false);
        history.push(`/app/jobs/${newJob.id}`);
      })
      .catch(() => showError(t("Error creating job")));
  };

  return (
    <Box style={{ width: "auto", maxWidth: "640px" }}>
      <h1>{t("Create Job")}</h1>
      <p>{t("Create job instructions")}</p>
      <hr />
      <Form className="mb-3" onSubmit={handleSave}>
        <Box mb={3}>
          <p>Name your job to make it easier to find later:</p>
          <TextField
            id="formJobName"
            label={t("Job Name")}
            placeholder={t("Add a helpful name for the job")}
            required
            fullWidth
            size="medium"
            onChange={(name) => updateField("name", name)}
            value={job.name}
          />
        </Box>

        <Box>
          {job.script ? (
            <p>
              {t("Configure a new script")}
              {t(", or ")}
              <Button variant="link" onClick={useApp}>
                {t("Use an app")}
              </Button>
              .
            </p>
          ) : (
            <p>
              <Button variant="link" onClick={useScript}>
                {t("Configure a new script")}
              </Button>
              {t(", or ")}
              {t("Use an app")}
            </p>
          )}
          {!job.script ? (
            <AsyncAutocomplete
              id="script-selectbox"
              label={t("Search for an app")}
              fetch={async (input?: string) => {
                const apps = await fetchApps();
                const appItems = apps.map((app) => {
                  return { label: app.name, data: app };
                });
                return appItems;
              }}
              onChange={(_, option) => {
                updateApp(option);
              }}
            />
          ) : null}
          {job.script ? (
            <ScriptFields
              script={job.script}
              updateScript={updateScript}
              isEditing
            />
          ) : null}
        </Box>

        <Box>
          <SaveButton
            disabled={
              !job.name ||
              (job.script === null && job.app === null) ||
              (job.script !== null &&
                (!job.script.gitRepository ||
                  !job.script.containerImage ||
                  !job.script.commands.length))
            }
            isLoading={isSubmitting}
            submitForm
          />
          <Link to="/">
            <Button variant="outline">Cancel</Button>
          </Link>
        </Box>
      </Form>
    </Box>
  );
}
