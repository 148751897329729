import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useTagsApi from "../../api/tagsApi";
import { ILink, ITag } from "../../types";
import { AsyncAutocomplete, Box, Chip, Tooltip } from "../common";
import { AddButton, CancelButton, SaveButton } from "../common/Button";

export default function ReportsTagForm({
  tags,
  setTags,
  onSave,
  onDelete,
}: {
  tags: ILink[];
  setTags: (tags: ILink[]) => void;
  onSave?: (tagIds: string[]) => void;
  onDelete?: (tagId: string) => void;
}) {
  const [tagMap] = useState<Map<string, ILink>>(
    new Map(tags.map((tag) => [tag.id, tag]))
  );
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const { fetchTags } = useTagsApi();
  const { t } = useTranslation("Reports Page");

  return (
    <>
      <h4>
        {t("common:tags")}
        <Tooltip title="Add Tag">
          <AddButton onClick={() => setIsAdding(true)} />
        </Tooltip>
      </h4>
      <Box>
        {tags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            onDelete={() => {
              tagMap.delete(tag.id);
              setTags([...tagMap.values()]);
              if (onDelete) onDelete(tag.id);
            }}
          />
        ))}
        {isAdding && (
          <>
            <AsyncAutocomplete
              id="tag-select"
              label={t("Select tag")}
              fetch={async () => {
                const tagOptions = await fetchTags({
                  exclude: tags.map((tag) => tag.id),
                });
                return tagOptions.map((tag) => {
                  return { label: tag.name, data: tag };
                });
              }}
              onChange={(_, selected: ITag) => {
                setSelectedTag(selected);
              }}
            />
            <Box>
              <CancelButton onClick={() => setIsAdding(false)} />
              <SaveButton
                disabled={!selectedTag}
                onClick={() => {
                  if (selectedTag) {
                    tagMap.set(selectedTag.id, selectedTag);
                    setTags([...tagMap.values()]);
                    if (onSave) onSave([...tagMap.keys()]);
                    setIsAdding(false);
                    setSelectedTag(null);
                  }
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
