import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateReportProps } from "../../api/reportsApi";
import { ILink, ISlackConfig } from "../../types";
import { Box, CancelButton, Form, SaveButton, TextField } from "../common";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../common/Modal";
import ReportSchedulerField from "../scheduler/ReportSchedulerField";
import { generateCron } from "../scheduler/schedulerHelpers";
import ReportSlackConfig from "./ReportSlackConfig";
import ReportsTagForm from "./ReportsTagForm";

export default function NewReportModal({
  show,
  onHide,
  onSave,
}: {
  show: boolean;
  onHide: () => void;
  onSave: (v: CreateReportProps) => void;
}) {
  const [name, setName] = useState("");
  const [cronSchedule, setCronSchedule] = useState(
    generateCron(new Date(), "daily")
  );
  const [tags, setTags] = useState<ILink[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<ISlackConfig[]>([]);
  const { t } = useTranslation("Reports Page");

  useEffect(() => {
    if (!show) {
      setName("");
      setTags([]);
      setSelectedChannels([]);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader onClose={onHide}>
        <ModalTitle>{t("Add a new report")}</ModalTitle>
        <Box>{t("New report form helper text")}</Box>
      </ModalHeader>
      <Form
        onSubmit={() => {
          onSave({
            name,
            cronSchedule,
            tags: tags.map((tag) => tag.id),
            notifications: selectedChannels,
          });
        }}
      >
        <ModalBody>
          <Box mb={3}>
            <TextField
              id="credential-name-input"
              placeholder={t("Add a helpful name for your report")}
              label={t("common:name")}
              value={name}
              required
              style={{ width: "65%" }}
              onChange={setName}
            />
          </Box>
          <ReportSchedulerField
            cronLine={cronSchedule}
            onChange={setCronSchedule}
          />
          <ReportSlackConfig
            selectedChannels={selectedChannels}
            setSelectedChannels={setSelectedChannels}
          />
          <ReportsTagForm tags={tags} setTags={setTags} />
        </ModalBody>

        <ModalFooter>
          <CancelButton onClick={onHide} />
          <SaveButton disabled={!name || !cronSchedule} submitForm />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
