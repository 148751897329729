import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useDashboardApi from "../../api/dashboardApi";
import useAlert from "../../hooks/useAlert";
import { useInterval } from "../../hooks/useInterval";
import { IDashboardList, IResource, ITag } from "../../types";
import { Box, Chip, Link, Span, Table } from "../common";
import { formatDateTime } from "../../time";
import LastRunStatus from "./LastRunStatus";

export default function DashboardSchedule({ tags }: { tags: ITag[] }) {
  const [running, setRunning] = useState<IResource[] | undefined>(undefined);
  const [scheduled, setScheduled] = useState<IResource[] | undefined>(
    undefined
  );
  const { fetchScheduled, fetchRunning } = useDashboardApi();

  const { t } = useTranslation("Dashboard");
  const { showError } = useAlert();

  useInterval<IDashboardList>({
    fetch: () => fetchRunning({ tag_filters: tags.map((tag) => tag.id) }),
    handleResponse: (res) => {
      setRunning(res.resources);
    },
    dependencies: [tags],
  });

  useInterval<IDashboardList>({
    fetch: () => fetchScheduled({ tag_filters: tags.map((tag) => tag.id) }),
    handleResponse: (res) => {
      setScheduled(res.resources);
    },
    handleError: () => showError(t("Error fetching scheduled list!")),
    dependencies: [tags],
  });

  return (
    <>
      <Box mb={4}>
        <h4>{t("Running")}</h4>
        {running && running.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>{t("common:name")}</th>
                <th>{t("common:type")}</th>
                <th>{t("common:tags")}</th>
                <th>{t("Last Run")}</th>
                <th>{t("Next Run")}</th>
              </tr>
            </thead>
            <tbody>
              {running?.map((resource) => (
                <tr key={resource.id}>
                  <td>
                    <Link
                      color="link"
                      to={`/app/${resource.resourceType}s/${resource.id}`}
                    >
                      {resource.name}
                    </Link>
                  </td>
                  <td>{resource.resourceType}</td>
                  <td>
                    {resource.tags.map((tag) => (
                      <Chip key={tag.id} label={tag.name} />
                    ))}
                  </td>
                  <td>
                    <LastRunStatus resource={resource} />
                  </td>
                  <td>
                    {resource.nextRuntime
                      ? formatDateTime(resource.nextRuntime)
                      : t("common:unavailable")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Box mt={3}>
            <Span color="outline" fontStyle="italic">
              {t("Nothing running right now...")}
            </Span>
          </Box>
        )}
      </Box>

      <h4>{t("Scheduled")}</h4>
      <Table>
        <thead>
          <tr>
            <th>{t("common:name")}</th>
            <th>{t("common:type")}</th>
            <th>{t("common:tags")}</th>
            <th>{t("Last Run")}</th>
            <th>{t("Next Run")}</th>
          </tr>
        </thead>
        <tbody>
          {scheduled?.map((resource) => (
            <tr key={resource.id}>
              <td>
                <Link
                  color="link"
                  to={`/app/${resource.resourceType}s/${resource.id}`}
                >
                  {resource.name}
                </Link>
              </td>
              <td>{resource.resourceType}</td>
              <td>
                {resource.tags.map((tag) => (
                  <Chip key={tag.id} label={tag.name} />
                ))}
              </td>
              <td>
                <LastRunStatus resource={resource} />
              </td>
              <td>
                {resource.nextRuntime
                  ? formatDateTime(resource.nextRuntime)
                  : t("common:unavailable")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
