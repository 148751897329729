import React from "react";
import ParsonsIcon from "../common/ParsonsLogo";

export default function LoginSplashIcon() {
  return (
    <div className="login-splash-icon">
      <ParsonsIcon />
      <h2>Parsons UI</h2>
    </div>
  );
}
