import useRequest from "../hooks/useRequest";
import { IJobList, IScript, ICredential } from "../types";

export default function useScriptApi() {
  const { get, post, remove } = useRequest();

  return {
    addScriptCredential: async (scriptId: string, credId: string) => {
      const { data } = await post<ICredential[]>(
        `/scripts/${scriptId}/credentials/${credId}`
      );
      return data;
    },

    fetchScripts: async (filters?: { nameSearch?: string }) => {
      const { data } = await get<IJobList[]>("/scripts", { params: filters });
      return data;
    },

    removeScriptCredential: async (scriptId: string, credId: string) => {
      const { data } = await remove<ICredential[]>(
        `/scripts/${scriptId}/credentials/${credId}`
      );
      return data;
    },

    updateScript: async (scriptId: string, update: IScript) => {
      const { data } = await post<IScript>(`/scripts/${scriptId}`, update);
      return data;
    },
  };
}
