import React from "react";
import Spinner from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const StyledSpinner = styled(Spinner)`
  margin-right: 0.5rem;
  color: inherit;
`;
StyledSpinner.defaultProps = {
  size: "1.25rem",
};

export function ButtonSpinner() {
  return <StyledSpinner />;
}

export function TripleSpinner() {
  return (
    <>
      <StyledSpinner />
      <StyledSpinner />
      <StyledSpinner />
    </>
  );
}

export default StyledSpinner;
