import React, { useState, useEffect, useContext } from "react";
import { ILink, ISlackConfig } from "../../types";
import { useTranslation } from "react-i18next";
import { Box, AsyncAutocomplete } from "../common";
import NotificationMessageBody from "./NotificationMessageBody";
import { ResourceContext } from "../../contexts/resourceContext";
import useSlackApi from "../../api/slackApi";
import useAuth from "../../hooks/useAuth";
import SlackAuth from "./SlackAuth";

// TODO: figure out how to localize these
const jobMessageBody = `Job run for job {{job_name}} completed with the "{{job_run_state}}" state in {{job_run_duration}}

For more information, see {{job_run_url}}`;

const workflowMessageBody = `Workflow {{workflow_name}} completed with the "{{workflow_run_state}}" state in {{workflow_run_duration}}

For more information, see {{workflow_run_url}}
`;

export default function SlackNotificationConfig({
  config,
  setConfig,
}: {
  config: ISlackConfig | null;
  setConfig: (criteria: ISlackConfig) => void;
}) {
  const { t } = useTranslation("Notification Form");
  const { resourceType } = useContext(ResourceContext);
  const defaultMessageBody =
    resourceType === "workflow" ? workflowMessageBody : jobMessageBody;

  const [selectedChannel, setSelectedChannel] = useState<ILink | null>(
    config ? { id: config.channelId, name: config.channelName } : null
  );
  const [messageBody, setMessageBody] = useState(
    config?.messageBody || defaultMessageBody
  );

  const { fetchChannels } = useSlackApi();
  const {
    authState: { parsonsUiUser },
  } = useAuth();

  useEffect(() => {
    if (selectedChannel) {
      setConfig({
        channelId: selectedChannel.id,
        channelName: selectedChannel.name,
        messageBody,
      });
    }
  }, [setConfig, selectedChannel, messageBody]);

  return parsonsUiUser?.slackEnabled ? (
    <>
      <Box mb={3}>
        <AsyncAutocomplete
          id="slack-channel-select"
          label={t("Select Slack channel to post to")}
          value={{
            label: config?.channelName || "",
            data: config,
          }}
          fetch={async () => {
            // TODO: possibly pre-fetch or cache channels for workspaces with large # of channels, like TMC
            const results = await fetchChannels();
            return results.map((result) => {
              return { label: result.name, data: result };
            });
          }}
          onChange={(_, channel) => {
            if (channel) setSelectedChannel(channel);
          }}
        />
      </Box>
      <Box mb={3}>
        <NotificationMessageBody
          messageBody={messageBody}
          setMessageBody={setMessageBody}
        />
      </Box>
    </>
  ) : (
    <Box style={{ textAlign: "center" }}>
      <SlackAuth />
    </Box>
  );
}
