import { TStatusCodes } from "./types";

type TStatusCodeMap = {
  [K in TStatusCodes]: { message: string; variant: string };
};

export const statusCodeMap: TStatusCodeMap = {
  0: { message: "Waiting...", variant: "secondary" },
  1: { message: "Running...", variant: "primary" },
  2: { message: "Success", variant: "success" },
  3: { message: "Failed", variant: "danger" },
  4: { message: "Server Error", variant: "danger" },
  5: { message: "Cancelled", variant: "outline" },
};

export const DEFAULT_POLL_INTERVAL = 5000;
