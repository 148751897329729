import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime, formatDuration } from "../../time";
import { Button, Span, Pagination, Row, Table } from "../common";
import { IJobRun } from "../../types";
import Status from "./JobRunStatus";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import useJob from "../../hooks/useJob";

function JobRunRow({
  run,
  handleLogClick,
  cancel,
  isCanceling,
}: {
  run: IJobRun;
  handleLogClick: (id: string) => void;
  cancel: (runId?: string) => Promise<void>;
  isCanceling: boolean;
}) {
  const { id, createdAt, statusCode, duration } = run;
  const { t } = useTranslation("Job Page");

  return (
    <tr id={run.id}>
      <td>{formatDateTime(createdAt)}</td>
      <td>{duration ? formatDuration(duration) : t("common:Unavailable")}</td>
      <td>
        <Status run={run} />
      </td>
      <td>
        {statusCode === 5 || (statusCode >= 1 && !isCanceling) ? (
          <Button variant="link" onClick={() => handleLogClick(id)}>
            {t("View Logs")}
          </Button>
        ) : null}
        <Span ml={2}>
          {statusCode === 2 && run.exportUrl && (
            <a href={run.exportUrl}>
              <CloudDownloadIcon /> {t("Download CSV")}
            </a>
          )}
          {statusCode < 2 && (
            <Button
              variant="outline-danger"
              isLoading={isCanceling}
              loadingMessage={t("Canceling...")}
              onClick={() => cancel(id)}
            >
              {t("Cancel Run")}
            </Button>
          )}
        </Span>
      </td>
    </tr>
  );
}

export default function JobRunsTable({
  handleLogClick,
  cancel,
  isCanceling,
}: {
  handleLogClick: (id: string) => void;
  cancel: (runId?: string) => Promise<void>;
  isCanceling: boolean;
}) {
  const { job, runsPage, setRunsPage } = useJob();
  const { t } = useTranslation("Job Page");

  if (!job) return null;
  const totalPages = job.runsPages || 0;

  return (
    <>
      <h4>{t("Runs")}</h4>
      <Table>
        <tbody>
          <tr>
            <th>{t("When")}</th>
            <th>{t("Run Time")}</th>
            <th>{t("Status")}</th>
            <th>{t("Results")}</th>
            <th></th>
          </tr>
          {job.runs?.map((run: IJobRun) => (
            <JobRunRow
              run={run}
              handleLogClick={handleLogClick}
              key={run.id}
              cancel={cancel}
              isCanceling={isCanceling}
            />
          ))}
        </tbody>
      </Table>
      <Row direction="row-reverse">
        <Pagination count={totalPages} page={runsPage} setPage={setRunsPage} />
      </Row>
    </>
  );
}
