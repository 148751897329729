import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import {
  SaveButton,
  CancelButton,
  Row,
  Col,
  Span,
  TextField,
  EditButton,
  ClearButton,
  Chip,
  Autocomplete,
} from "../common";
import { DenseCol } from "../common/Grid";
import { IOptionParameter } from "../../types";
import useAlert from "../../hooks/useAlert";
import useParametersApi from "../../api/parametersApi";
import useParamDefApi from "../../api/paramDefApi";
import { IOptionParameterDefinition } from "../../types/ParameterDefinitions";

export default function EditOptionParam({
  parameter,
  onUpdate,
  canEditKey,
  onRemove,
  postUpdate,
}: {
  parameter: IOptionParameter;
  onUpdate: (v: IOptionParameter) => void;
  canEditKey: boolean;
  onRemove?: (id: string) => void;
  postUpdate: boolean;
}) {
  const [updatedKey, setUpdatedKey] = useState(parameter.name);
  const [updatedValue, setUpdatedValue] = useState(parameter.value);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paramDef, setParamDef] = useState<IOptionParameterDefinition | null>(
    null
  );
  const { updateParameter } = useParametersApi();
  const { fetchParamDef } = useParamDefApi();
  const { t } = useTranslation("Credentials Page");
  const { showSuccess, showError } = useAlert();

  const colStyles = { overflow: "hidden", textOverflow: "ellipsis" };

  const getOptions = async () => {
    if (parameter.parameterDefinitionId) {
      try {
        const res = await fetchParamDef(parameter.parameterDefinitionId);
        setParamDef(res as IOptionParameterDefinition);
      } catch {
        showError(t("Error fetching options!"));
      }
    }
  };
  useEffect(() => {
    getOptions();
  }, []);

  const handleEdit = async () => {
    setIsSubmitting(true);
    try {
      const update: IOptionParameter = {
        ...parameter,
        name: canEditKey ? updatedKey : parameter.name,
        value: updatedValue,
      };
      const updatedVar = postUpdate
        ? await updateParameter(parameter.id, update)
        : { ...update, id: parameter.id };
      onUpdate(updatedVar as IOptionParameter);
      setIsEditing(false);
      showSuccess(t("Updated parameter!"));
    } catch {
      showError(t("Error updating parameter"));
    }
    setIsSubmitting(false);
  };

  return isEditing ? (
    <Row>
      <Col xs={5} style={{ display: "flex" }}>
        <Span mr={3}>
          <Chip icon={<TextFieldsIcon />} label={t("common:text")} />
        </Span>
        {canEditKey ? (
          <TextField
            id="env-key-input"
            label={t("Key")}
            defaultValue={parameter.name}
            onChange={setUpdatedKey}
            fullWidth
          />
        ) : (
          parameter.name
        )}
      </Col>
      <Col xs={4}>
        <Autocomplete
          id="parameter-option-selectbox"
          label={t("Select value")}
          defaultValue={{ label: parameter.value }}
          required
          options={paramDef?.options.map((option) => {
            return {
              label: option,
              data: option,
            };
          })}
          onChange={(_, selected) => {
            setUpdatedValue(selected);
          }}
        />
      </Col>
      <Col xs={3}>
        <CancelButton onClick={() => setIsEditing(false)} />{" "}
        <SaveButton onClick={handleEdit} />
      </Col>
    </Row>
  ) : (
    <Row>
      <DenseCol xs={6} style={{ ...colStyles, display: "flex" }}>
        <Span mr={3}>
          <Chip icon={<TextFieldsIcon />} label={t("common:text")} />
        </Span>
        {parameter.name}
      </DenseCol>
      <DenseCol xs={3} style={colStyles}>
        <Span color="outline">{parameter.value}</Span>
      </DenseCol>
      <DenseCol xs={2} style={{ textAlign: "right" }}>
        <EditButton size="small" onClick={() => setIsEditing(true)} />
        {onRemove && (
          <ClearButton
            size="small"
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              onRemove(parameter.id);
            }}
          />
        )}
      </DenseCol>
    </Row>
  );
}
