import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Table,
  Row,
  Col,
  Loading,
  EditButton,
  Link,
  RemoveButton,
  TableIconCell,
  SaveButton,
  CancelButton,
  Form,
} from "../common";

import useTagsApi, { UpsertTagProps } from "../../api/tagsApi";
import { ITag } from "../../types";
import useAlert from "../../hooks/useAlert";
import TagModalForm from "./TagModalForm";
import Modal, { ModalBody, ModalFooter } from "../common/Modal";

export default function TagPage() {
  const [tags, setTags] = useState<ITag[] | null>(null);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [removingTagId, setRemovingTagId] = useState<string | null>(null);
  const { createTag, updateTag, fetchTags, removeTag } = useTagsApi();
  const { showSuccess, showError, showWarning } = useAlert();
  const { t } = useTranslation("Tags Page");

  const refresh = async () => {
    try {
      const updated = await fetchTags();
      setTags(updated);
    } catch {
      showError(t("Error fetching tags"));
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleRemove = async (id: string | null) => {
    if (id) {
      try {
        await removeTag(id);
        refresh();
        showWarning(t("Removing tag"));
        setRemovingTagId(null);
      } catch {
        showError(t("Error removing tag"));
      }
    }
  };

  const handleSubmit = async (data: UpsertTagProps) => {
    const post = selectedTag
      ? () => updateTag(selectedTag.id, data)
      : () => createTag(data);
    try {
      await post();
      refresh();
      showSuccess(selectedTag ? t("Updated tag") : t("Created tag"));
      setShowModal(false);
    } catch {
      showError(
        selectedTag ? t("Error creating tag") : t("Error updating tag")
      );
    }
  };

  return (
    <>
      <Row centerVertically>
        <Col>
          <h1>{t("Tags")}</h1>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            size="lg"
            onClick={() => {
              setSelectedTag(null);
              setShowModal(true);
            }}
          >
            {t("Add Tag")}
          </Button>
        </Col>
      </Row>

      {tags ? (
        <Table>
          <thead>
            <tr>
              <th>{t("Tag")}</th>
              <th>{t("common:Created By")}</th>
              <th>{t("Tagged jobs")}</th>
              <th style={{ textAlign: "center" }}>{t("common:actions")}</th>
            </tr>
          </thead>
          <tbody>
            {tags &&
              tags.map((tag) => (
                <tr key={tag.id}>
                  <td>{tag.name}</td>
                  <td>{tag.author.name}</td>
                  <td>
                    {tag.jobs.map((job, index) => (
                      <Link key={job.id} to={`/app/jobs/${job.id}`}>
                        {job.name}
                        {index < tag.jobs.length - 1 && ", "}
                      </Link>
                    ))}
                  </td>
                  <TableIconCell>
                    <EditButton
                      onClick={() => {
                        setSelectedTag(tag);
                        setShowModal(true);
                      }}
                    />
                    <RemoveButton
                      onClick={() => {
                        if (tag.jobs.length > 0)
                          return setRemovingTagId(tag.id);
                        handleRemove(tag.id);
                      }}
                    />
                  </TableIconCell>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <Loading />
      )}

      <TagModalForm
        show={showModal}
        tag={selectedTag}
        onSave={handleSubmit}
        onHide={() => setShowModal(false)}
      />

      <Modal show={Boolean(removingTagId)}>
        <Form onSubmit={() => handleRemove(removingTagId)}>
          <ModalBody>{t("Remove Tag Warning")}</ModalBody>
          <ModalFooter>
            <SaveButton submitForm label={t("common:delete")} />
            <CancelButton onClick={() => setRemovingTagId(null)} />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
