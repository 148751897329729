import React from "react";
import { RouteComponentProps } from "react-router";
import LogDetail from "./LogDetail";

export default function LogPage({
  match,
}: RouteComponentProps<{ runId: string }>) {
  const { runId } = match.params;

  return <LogDetail runId={runId} />;
}
