import { useContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import axios, { AxiosRequestConfig } from "axios";

import AuthContext from "../contexts/authContext";
import { logout } from "../hooks/useAuth";

export default function useRequest() {
  const { token, expirationTime, updateToken } = useContext(AuthContext);

  const getAuthHeader = async () => {
    const { currentUser } = firebase.auth();
    if (!currentUser) return await logout();
    const latest = await currentUser.getIdTokenResult(true);
    const { org_id } = latest.claims;
    updateToken(latest.token, latest.expirationTime, { org_id });
    return `Bearer ${latest.token}`;
  };

  const getHeaders = async () => {
    return {
      Authorization:
        token && expirationTime && new Date() < new Date(expirationTime)
          ? `Bearer ${token}`
          : await getAuthHeader(),
    };
  };

  return {
    get: async <T>(url: string, config: AxiosRequestConfig = {}) => {
      return axios.get<T>(url, { ...config, headers: await getHeaders() });
    },
    post: async <T>(url: string, data: object = {}) => {
      return axios.post<T>(url, data, { headers: await getHeaders() });
    },
    remove: async <T>(url: string, config: AxiosRequestConfig = {}) => {
      return axios.delete<T>(url, { ...config, headers: await getHeaders() });
    },
  };
}
