import React from "react";
import { useTranslation } from "react-i18next";
import { IOptionParameterDefinition } from "../../types/ParameterDefinitions";
import { IUpsertOptionParam } from "../../types/Parameters";
import ParamDefListItemLabel from "../apps/parameterDefinitions/ParamDefListItemLabel";
import { Autocomplete, Col, Divider, Row } from "../common";

export default function ({
  parameter,
  paramDef,
  onChange,
}: {
  parameter: IUpsertOptionParam;
  paramDef: IOptionParameterDefinition;
  onChange: (v: string) => void;
}) {
  const { t } = useTranslation("Job Page");
  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />
        <Col>
          <Autocomplete
            id="parameter-option-selectbox"
            label={t("Select value")}
            defaultValue={{
              label: parameter.value,
              data: parameter.value,
            }}
            options={paramDef.options.map((option) => {
              return {
                label: option,
                data: option,
              };
            })}
            onChange={(_, selected) => {
              onChange(selected);
            }}
          />
        </Col>
      </Row>
      <Divider mt={1} mb={1} />
    </>
  );
}
