import React from "react";
import Link from "../common/Link";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import styled from "styled-components";

import { IUser } from "../../types";
import UserMenu from "./UserMenu";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

// TODO: use context to get these properties
interface IProps {
  logoutUser: () => void;
  user: IUser;
  onToggleSecondaryNav: () => void;
}

const NavBar = styled(AppBar)`
  background-color: white;
  z-index: 1201;
`;

const Brand = styled(Link as any)`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.title};
`;

export default function ParsonsNavBar({
  logoutUser,
  user,
  onToggleSecondaryNav,
}: IProps) {
  return (
    <>
      <NavBar position="fixed">
        <Toolbar>
          <IconButton onClick={onToggleSecondaryNav}>
            <MenuIcon />
          </IconButton>
          <Brand color="navText" to="/">
            <img
              alt="The Movement Cooperative Logo"
              src="/static/react/tmc-logo.png"
              width="30px"
              style={{ verticalAlign: "top" }}
            />{" "}
            {`Parsons Orchestrator - ${user.orgName}`}
          </Brand>
          <UserMenu logoutUser={logoutUser} user={user} />
        </Toolbar>
      </NavBar>
    </>
  );
}
