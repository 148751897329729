import React, { useRef, useState, useEffect } from "react";
import { formatDateTime } from "../../time";
import LoadingScreen from "../common/LoadingScreen";
import Pre from "../common/Pre";
import Box from "../common/Box";
import Span from "../common/Span";
import Status from "../jobs/JobRunStatus";
import { IRunLog } from "../../types";
import useApi from "../../api/jobRunApi";

export default function LogDetail({ runId }: { runId: string }) {
  const [log, setLog] = useState<IRunLog | null>(null);
  const { fetchLog } = useApi();

  const isMounted = useRef(true);
  const fetchesRunning = useRef(0);
  const poll = useRef(0);

  const getData = () => {
    fetchesRunning.current += 1;
    if (fetchesRunning.current === 1 && isMounted.current) {
      fetchLog(runId)
        .then((response) => {
          fetchesRunning.current = 0;
          if (isMounted.current) {
            setLog(response);
            if (response.finishedAt) {
              clearInterval(poll.current);
              poll.current = 0;
            }
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const fetch = () => {
    poll.current = setInterval(() => getData(), 4000);
    getData();

    return () => {
      isMounted.current = false;
      clearInterval(poll.current);
    };
  };

  useEffect(fetch, []);

  if (!log) return <LoadingScreen />;

  return (
    <>
      <Box>
        <strong>Started at:</strong> {formatDateTime(log.createdAt)}
      </Box>

      <Box>
        {log.finishedAt ? (
          <>
            <strong>Finished at:</strong>{" "}
            <Span mr={2}>{formatDateTime(log.finishedAt)}</Span>
          </>
        ) : null}
        <Status run={log} />
      </Box>

      <Pre mt={2}>
        <code>{log.output}</code>
      </Pre>
    </>
  );
}
