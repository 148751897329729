import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "./useAlert";

// a simple hook to display slack oauth status when being redirected back to Parsons UI from Slack. Just displays alerts showing the status of the connection
export default function useSlackAuth() {
  const slackAuth = new URLSearchParams(useLocation().search).get("slackAuth");
  const { showSuccess, showError } = useAlert();
  const { t } = useTranslation("Slack");

  const handleSlackAuth = () => {
    if (slackAuth) {
      if (slackAuth === "success") showSuccess(t("Slack connected!"));
      if (slackAuth === "error") showError(t("Error connecting Slack!"));
    }
  };
  useEffect(handleSlackAuth, []);
}
