import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Row,
  Col,
  ExpandButton,
  EditButton,
  RemoveButton,
  List,
  ListItem,
  Span,
  Checkbox,
  TableIconCell,
} from "../common";
import { ICredential } from "../../types";

import Collapse from "@material-ui/core/Collapse";
import { UpsertCredentialProps } from "../../api/credentialsApi";

export default function CredentialRow({
  credential,
  onEdit,
  onRemove,
  onSave,
}: {
  credential: ICredential;
  onEdit: () => void;
  onRemove: () => void;
  onSave: (v: UpsertCredentialProps) => void;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("Credentials Page");

  return (
    <>
      <tr key={credential.id}>
        <td style={{ maxWidth: "1em" }}>
          <ExpandButton open={open} onToggle={setOpen} />
        </td>
        <td>{credential.name}</td>
        <td>{credential.author.name}</td>
        <td>
          <Checkbox
            checked={credential.shared}
            disabled={!credential.isAuthor}
            onChange={(shared) =>
              onSave({
                credential,
                name: credential.name,
                description: credential.description,
                shared,
              })
            }
          />
        </td>
        <TableIconCell>
          <EditButton onClick={onEdit} />
          <RemoveButton disabled={!credential.isAuthor} onClick={onRemove} />
        </TableIconCell>
      </tr>
      <tr>
        <td
          style={{ borderTop: "none", paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
        >
          <Collapse in={open}>
            <Row pl={3} pr={3}>
              <Col style={{}}>
                <Box>
                  <Span color="navText" fontWeight="bold">
                    {t("Description")}
                  </Span>
                </Box>
                <Box pr={3}>{credential.description}</Box>
              </Col>
              <Col>
                <Box>
                  <Span color="navText" fontWeight="bold">
                    {t("Parameters")}
                  </Span>
                </Box>
                <List style={{ width: "50%" }}>
                  {credential.parameters.map((v) => (
                    <ListItem key={v.id} label={<strong>{v.name}</strong>} />
                  ))}
                </List>
              </Col>
            </Row>
          </Collapse>
        </td>
      </tr>
    </>
  );
}
