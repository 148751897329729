import React from "react";
import styled from "styled-components";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export function TMCToggleButtonGroup<T>({
  value,
  onChange,
  children,
}: {
  value: T[];
  onChange: (newValues: T[]) => void;
  children: React.ReactNode | React.ReactNodeArray;
}) {
  const handleChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    newValues: T[]
  ) => onChange(newValues);
  return (
    <ToggleButtonGroup value={value} onChange={handleChange}>
      {children}
    </ToggleButtonGroup>
  );
}

export const TMCToggleButton = styled(ToggleButton)`
  font-weight: 600;
  &.Mui-selected {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }
`;
