import {
  ICredential,
  IParameter,
  ICredentialTemplate,
  TUpsertCredParameter,
  IPasswordParameter,
  ITextParameter,
} from "../types";
import useRequest from "../hooks/useRequest";

export interface CreateCredentialProps {
  name: string;
  description: string;
  shared: boolean;
  parameters?: TUpsertCredParameter[];
  templateId?: string;
}

export interface UpsertCredentialProps {
  credential: ICredential | null;
  name: string;
  description: string;
  shared: boolean;
  parameters?: IParameter[];
}

export default function useCredentialsApi() {
  const { get, post, remove } = useRequest();
  return {
    fetchMyCredentials: async (credentialTemplateId?: string) => {
      const { data } = await get<ICredential[]>("/credentials", {
        params: { credentialTemplateId },
      });
      return data;
    },

    fetchDatabaseConnections: async () => {
      const { data } = await get<ICredential[]>(
        "/credentials/database_connections"
      );
      return data;
    },

    fetchCredential: async (id: string) => {
      const { data } = await get<ICredential>(`/credentials/${id}`);
      return data;
    },

    createCredential: async (credential: CreateCredentialProps) => {
      const { data } = await post<ICredential>("/credentials", credential);
      return data;
    },

    updateCredential: async ({
      credential,
      name,
      description,
      shared,
    }: UpsertCredentialProps) => {
      if (!credential) throw Error(`Can't update null credential`);
      const { data } = await post<ICredential>(
        `/credentials/${credential.id}`,
        {
          name,
          description,
          shared,
        }
      );
      return data;
    },

    addCredentialParameter: async (
      credId: string,
      param: ITextParameter | IPasswordParameter
    ) => {
      const { data } = await post<(ITextParameter | IPasswordParameter)[]>(
        `/credentials/${credId}/parameters`,
        param
      );
      return data;
    },

    removeCredentialParameter: async (credId: string, varId: string) => {
      // At the moment, the only parameter type you can use when directly attaching parameters
      // to credentials is the "text" type
      const { data } = await remove<TUpsertCredParameter[]>(
        `/credentials/${credId}/parameters/${varId}`
      );

      return data;
    },

    removeCredential: (id: string) => {
      return remove(`/credentials/${id}`);
    },

    fetchCredentialTemplates: async (options?: { systemId?: string }) => {
      const { data } = await get<ICredentialTemplate[]>(
        "/credentials/templates",
        { params: { ...options } }
      );
      return data;
    },
  };
}
