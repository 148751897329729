import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  position,
  flex,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
  PositionProps,
  FlexboxProps,
} from "styled-system";

interface IProps
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    PositionProps,
    FlexboxProps {}
const Box = styled.div<IProps>`
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${flex}
`;

Box.defaultProps = {
  mt: 1,
  mb: 1,
};

export default Box;
