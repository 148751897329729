import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IEmailNotification,
  INotificationConfig,
  INotificationCriteria,
  ISlackConfig,
  ISlackNotification,
} from "../../types";
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "../common/Modal";
import { CancelButton, Form, Box, SaveButton, Select, Span } from "../common";
import NotificationConfig from "./NotificationConfig";
import NotificationCriteria from "./NotificationCriteria";
import { UpsertNotificationProps } from "../../api/notificationsApi";
import SlackNotificationConfig from "./SlackNotificationConfig";

interface IProps {
  notification: ISlackNotification | IEmailNotification | null;
  show: boolean;
  onHide: () => void;
  onSave: (v: UpsertNotificationProps) => void;
}

export default function NotificationModalForm({
  notification,
  show,
  onHide,
  onSave,
}: IProps) {
  const hide = () => onHide();
  const { t } = useTranslation("Notification Form");
  const [notificationType, setNotificationType] = useState<"email" | "slack">(
    notification ? notification.notificationType : "email"
  );
  // We track state on this element at the config / criteria level to have good separation of
  // concerns -- the child elements can figure out the best way to manage UI / state of the
  // individual fields
  const [
    notificationConfig,
    setNotificationConfig,
  ] = useState<INotificationConfig | null>(
    notification && notification.notificationType === "email"
      ? notification.config
      : null
  );
  const [slackConfig, setSlackConfig] = useState<ISlackConfig | null>(
    notification && notification.notificationType === "slack"
      ? notification.config
      : null
  );
  const [
    notificationCriteria,
    setNotificationCriteria,
  ] = useState<INotificationCriteria | null>(
    notification ? notification.criteria : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  // We shouldn't save until the config and criteria have been initialized by the corresponding
  // child elements
  const canSave = (notificationConfig || slackConfig) && notificationCriteria;
  // Save the notification to the server.
  const handleSubmit = () => {
    if (notificationCriteria) {
      if (notificationType === "email" && notificationConfig) {
        setIsSubmitting(true);
        onSave({
          id: notification?.id,
          notificationType,
          criteria: notificationCriteria,
          config: notificationConfig,
        });
      } else if (notificationType === "slack" && slackConfig) {
        setIsSubmitting(true);
        onSave({
          id: notification?.id,
          notificationType,
          criteria: notificationCriteria,
          config: slackConfig,
        });
      }
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>
          <ModalTitle>{t("Notification")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <NotificationCriteria
            criteria={notificationCriteria}
            setCriteria={setNotificationCriteria}
          />
          <Box mb={3}>
            <strong>{t("Notification Type")}:</strong>
            <Span ml={2}>
              <Select
                id="notification-type-select"
                name="notificationType"
                onChange={(v) => {
                  if (v === "email" || v === "slack") setNotificationType(v);
                }}
                value={notificationType}
              >
                <option value="email">{t("Email")}</option>
                <option value="slack">{t("slack")}</option>
              </Select>
            </Span>
          </Box>
          {notificationType === "email" ? (
            <NotificationConfig
              config={notificationConfig}
              setConfig={setNotificationConfig}
            />
          ) : (
            <SlackNotificationConfig
              config={slackConfig}
              setConfig={setSlackConfig}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <SaveButton disabled={isSubmitting || !canSave} submitForm />
          <CancelButton onClick={hide} />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
