import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import useTagsApi from "../../api/tagsApi";
import useWorkflowApi from "../../api/workflowApi";
import useAlert from "../../hooks/useAlert";
import { useInterval } from "../../hooks/useInterval";
import { formatDuration, timeAgo } from "../../time";
import { ITag, IWorkflow, IWorkflowsPaginatedList } from "../../types";
import {
  Loading,
  Box,
  Button,
  Link,
  Row,
  Table,
  Chip,
  Pagination,
  AsyncAutocomplete,
  Span,
} from "../common";
import Status from "../jobs/JobRunStatus";

export default function WorkflowList() {
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [tagFilters, setTagFilters] = useState<ITag[]>([]);
  const { fetchWorkflows } = useWorkflowApi();
  const { fetchTags } = useTagsApi();
  const { t } = useTranslation("Workflow List");
  const { showError } = useAlert();
  const pageRef = useRef(page);

  useInterval<IWorkflowsPaginatedList>({
    fetch: () =>
      fetchWorkflows({
        tag_filters: tagFilters.map((tag) => tag.id),
        page: pageRef.current,
      }),
    handleResponse: (res) => {
      setWorkflows(res.workflows);
      setPageCount(res.pageCount);
    },
    handleError: () => showError(t("Error fetching workflows")),
    dependencies: [tagFilters, page],
  });

  return (
    <Box>
      <Row justify="space-between">
        <Row style={{ width: "50%" }} alignItems="center">
          <AsyncAutocomplete
            id="tag-filter"
            style={{ width: "50%" }}
            label={t("Filter by tag")}
            multiselect
            fetch={async () => {
              const tags = await fetchTags();
              return tags.map((tag) => {
                return { label: tag.name, data: tag };
              });
            }}
            onChange={(inputValue, selected) => {
              if (selected) {
                setTagFilters(selected);
                setWorkflows([]);
              }
            }}
          />
          <Span ml={3}>
            <Link to="/app/jobs">{t("View jobs")} →</Link>
          </Span>
        </Row>
        <Box style={{ textAlign: "right" }}>
          <Link to="/app/addWorkflow">
            <Button size="lg">{t("Add Workflow")}</Button>
          </Link>
        </Box>
      </Row>
      <Box mt={4}>
        {workflows ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>{t("common:name")}</th>
                  <th>{t("common:Created By")}</th>
                  <th>{t("Last Run")}</th>
                  <th>{t("Duration")}</th>
                  <th>{t("common:tags")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {workflows.map((wf) => (
                  <tr key={wf.id}>
                    <td>
                      <Link to={`/app/workflows/${wf.id}`}>{wf.name}</Link>
                    </td>
                    <td>{wf.author.name}</td>
                    <td>
                      {wf.latestRun
                        ? timeAgo(wf.latestRun.createdAt)
                        : t("Unavailable")}
                    </td>
                    <td>
                      {wf.latestRun && wf.latestRun.duration
                        ? formatDuration(wf.latestRun.duration)
                        : t("Unavailable")}
                    </td>
                    <td>
                      {wf.tags.map((tag) => (
                        <Chip key={tag.id} label={tag.name} />
                      ))}
                    </td>
                    <td>
                      {wf.latestRun ? (
                        <Status run={wf.latestRun} />
                      ) : (
                        t("No Runs Yet")
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row direction="row-reverse">
              <Pagination
                count={pageCount}
                page={page}
                setPage={(page) => {
                  pageRef.current = page;
                  setPage(page);
                }}
              />
            </Row>
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}
