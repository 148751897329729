import { createContext } from "react";
import { IUser } from "../types";

const AuthContext = createContext<{
  user: IUser | null;
  orgId: string | null;
  token: string | null;
  expirationTime: string | null;
  updateToken: (
    token: string,
    expiry: string,
    claims: { org_id: string }
  ) => void;
}>({
  user: null,
  orgId: null,
  token: null,
  expirationTime: null,
  updateToken: () => null,
});

export default AuthContext;
