import styled from "styled-components";
import {
  space,
  color,
  typography,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from "styled-system";

interface IProps extends SpaceProps, ColorProps, TypographyProps {}

const StyledSpan = styled.span<IProps>`
  ${space}
  ${color}
  ${typography}
`;

export default StyledSpan;
