import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  SaveButton,
  CancelButton,
  ClearButton,
  Form,
  List,
  ListItem,
  AsyncAutocomplete,
} from "../common";

import useJobsApi from "../../api/jobApi";
import useCredentialsApi from "../../api/credentialsApi";
import { ICredential } from "../../types";
import useAlert from "../../hooks/useAlert";

function CredentialListItem({
  credential,
  onRemove,
}: {
  credential: ICredential;
  onRemove: (id: string) => Promise<ICredential[] | null>;
}) {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <ListItem
      showDivider
      label={credential.name}
      subText={credential.parameters.map((v) => v.name).join(", ")}
      actions={
        <ClearButton
          disabled={isRemoving}
          onClick={async () => {
            setIsRemoving(true);
            await onRemove(credential.id);
            setIsRemoving(false);
          }}
        />
      }
    />
  );
}

export default function CredentialForm({
  jobId,
  credentials,
}: {
  jobId: string;
  credentials: ICredential[];
}) {
  const [updatedCreds, setUpdatedCreds] = useState(credentials);
  const [isAdding, setIsAdding] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCredId, setSelectedCredId] = useState<string | null>(null);
  const { addJobCredential, removeJobCredential } = useJobsApi();
  const { fetchMyCredentials } = useCredentialsApi();
  const { t } = useTranslation("Job Page");
  const { showError, showSuccess, showWarning } = useAlert();
  const history = useHistory();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!selectedCredId) return showError(t("Select a credential first"));
    try {
      const updated = await addJobCredential(jobId, selectedCredId);
      setUpdatedCreds(updated);
      setSelectedCredId(null);
      setIsAdding(false);
      showSuccess(t("Adding credential"));
    } catch {
      showError(t("Error adding credential"));
    }
    setIsSubmitting(false);
  };

  const handleRemove = async (credId: string) => {
    let updated = null;
    try {
      updated = await removeJobCredential(jobId, credId);
      setUpdatedCreds(updated);
      showWarning(t("Removed credential"));
    } catch {
      showError(t("Error removing credential"));
    }
    return updated;
  };

  const manageCredentials = () => {
    history.push("/app/credentials");
  };

  return (
    <>
      <h3>{t("Credentials")}</h3>
      <Box mb={3}>{t("Attach a shared credential")}</Box>

      {updatedCreds.length > 0 && (
        <List>
          {updatedCreds.map((credential) => (
            <CredentialListItem
              key={credential.id}
              credential={credential}
              onRemove={handleRemove}
            />
          ))}
        </List>
      )}

      {isAdding ? (
        <Form onSubmit={handleSubmit}>
          <Box mt={3} mb={3} style={{ width: "75%" }}>
            <AsyncAutocomplete
              id="credential-select"
              label={t("Select a shared credential")}
              fetch={async () => {
                const attachedCreds = updatedCreds.map((cred) => cred.name);
                const creds = await fetchMyCredentials();
                return creds
                  .filter((cred) => !attachedCreds.includes(cred.name))
                  .map((cred) => {
                    return { label: cred.name, data: cred };
                  });
              }}
              onChange={(_, selected: ICredential) =>
                setSelectedCredId(selected.id)
              }
            />
          </Box>
          <Box>
            <SaveButton
              submitForm
              disabled={!selectedCredId}
              isLoading={isSubmitting}
            />
            <CancelButton onClick={() => setIsAdding(false)} />
          </Box>
          <Box>
            <Button mb={3} ml={2} variant="link" onClick={manageCredentials}>
              {t("Manage credentials")}
            </Button>
          </Box>
        </Form>
      ) : (
        <Box textAlign="center">
          <Button variant="outline" onClick={() => setIsAdding(true)}>
            Add Credential
          </Button>
        </Box>
      )}
    </>
  );
}
