import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "styled-system";
import { lighten } from "polished";

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.main};
  &:hover {
    color: ${({ theme, color }) => {
      const c = color || "link";
      return lighten(0.2, theme.colors[c]);
    }};
  }
  ${color}
`;
StyledLink.defaultProps = {
  color: "link",
};

export default StyledLink;

export const NavLink = styled(StyledLink as any)`
  &:hover {
    text-decoration: none;
  }
`;
NavLink.defaultProps = {
  color: "navLink",
};
