import React from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { Button } from "../common";

export default function SlackAuth() {
  const {
    authState: { parsonsUiUser },
  } = useAuth();
  const scopes = "channels:read,chat:write,groups:read";
  const clientId = "249541362435.1562549172567";
  const { origin } = window.location;
  const redirectUri = `${origin}/slack/install`;
  const redirectState = JSON.stringify({
    user: parsonsUiUser?.id,
    origin,
    path: window.location.hash.replace("#", ""),
  });
  const { t } = useTranslation("Notification Form");

  return (
    <a
      href={`https://slack.com/oauth/v2/authorize?scope=${scopes}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${redirectState}`}
    >
      <Button size="lg">{t("Connect Slack")}</Button>
    </a>
  );
}
