import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const StyledContainer = styled(Container)<SpaceProps>`
  padding: 2em;
  background-color: white;
  max-width: 1140px;
  top: 96px;
  position: relative;
  ${space}
`;

export default StyledContainer;

export const SidebarContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 2em;
  top: 64px;
  box-sizing: border-box;
  marginleft: auto;
  marginright: auto;
  position: relative;
`;
