import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { formatDateTime, formatDuration } from "../../time";
import { Box, ExpandButton, Button, Table, Link } from "../common";
import { IWorkflowRun } from "../../types";
import Status from "../jobs/JobRunStatus";
import Collapse from "@material-ui/core/Collapse";
import useAlert from "../../hooks/useAlert";
import useWorkflowRunsApi from "../../api/workflowRunsApi";

export default function WorkflowRunRow({
  run,
  onCancel,
}: {
  run: IWorkflowRun;
  onCancel: (runId: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const { retryWorkflowRun } = useWorkflowRunsApi();
  const { showError } = useAlert();
  const { t } = useTranslation("Workflow Page");

  useEffect(() => {
    if (run.statusCode === 1) {
      setIsRetrying(false);
    }
  }, [run]);

  return (
    <>
      <tr>
        <td style={{ maxWidth: "1em" }}>
          {run.jobRuns.length > 0 && (
            <ExpandButton open={open} onToggle={setOpen} />
          )}
        </td>
        <td>{formatDateTime(run.createdAt)}</td>
        <td>
          {run.duration
            ? formatDuration(run.duration)
            : t("common:Unavailable")}
        </td>
        <td>
          <Status run={run} />
        </td>
        <td>
          {run.statusCode < 2 && (
            <Button variant="outline-danger" onClick={() => onCancel(run.id)}>
              {t("Cancel Run")}
            </Button>
          )}
          {[3, 4].includes(run.statusCode) && (
            <Button
              variant="outline"
              isLoading={isRetrying}
              onClick={async () => {
                setIsRetrying(true);
                try {
                  await retryWorkflowRun(run.id);
                } catch {
                  showError("Error retrying workflow run");
                  setIsRetrying(false);
                }
              }}
            >
              {t("Retry")}
            </Button>
          )}
        </td>
      </tr>
      {run.jobRuns.length > 0 && (
        <tr>
          <td
            style={{ borderTop: "none", paddingBottom: 0, paddingTop: 0 }}
            colSpan={4}
          >
            <Collapse in={open}>
              <Box pl={3} pr={3}>
                <Table dense>
                  <thead>
                    <tr>
                      <th>Job</th>
                      <th>When</th>
                      <th>Run Time</th>
                      <th>Status</th>
                      <th>Results</th>
                    </tr>
                  </thead>
                  <tbody>
                    {run.jobRuns.map((jobRun) => (
                      <tr key={uuidv4()} id={run.id}>
                        <td>
                          <Link to={`/app/jobs/${jobRun.job.id}`}>
                            {jobRun.job.name}
                          </Link>
                        </td>
                        <td>{formatDateTime(jobRun.createdAt)}</td>
                        <td>
                          {jobRun.duration
                            ? formatDuration(jobRun.duration)
                            : t("common:Unavailable")}
                        </td>
                        <td>
                          <Status run={jobRun} />
                        </td>
                        <td>
                          <Link to={`/app/logs/${jobRun.id}`} target="_blank">
                            {t("View logs")}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
}
