import formatDistanceStrict from "date-fns/formatDistanceStrict";
import addSeconds from "date-fns/addSeconds";

import {
  IJob,
  IParameter,
  ICredential,
  ITag,
  IJobsPaginatedList,
  TUpsertParameter,
  ITextParameter,
  IPasswordParameter,
} from "../types";
import useRequest from "../hooks/useRequest";

interface IRunStatsResponse {
  count: string;
  completion_counts?: {
    "0": string;
    "1": string;
    "2": string;
    "3": string;
    "4": string;
    "5": string;
  };
  averages?: {
    duration?: string;
  };
}

function toRunStats(data: IRunStatsResponse) {
  const outputStatusCount = (key: "0" | "1" | "2" | "3" | "4" | "5") =>
    data.completion_counts && data.completion_counts[key]
      ? parseInt(data.completion_counts[key])
      : 0;
  const stats = {
    count: parseInt(data.count),
    success: outputStatusCount("2"),
    waiting: outputStatusCount("0"),
    running: outputStatusCount("1"),
    failed: outputStatusCount("3"),
    canceled: outputStatusCount("5"),
    serverError: outputStatusCount("4"),
    averageDuration: "",
  };
  if (data.averages && data.averages.duration) {
    const refDate = new Date();
    const comparisonDate = addSeconds(
      refDate,
      parseFloat(data.averages.duration)
    );
    stats.averageDuration = formatDistanceStrict(refDate, comparisonDate);
  }
  return stats;
}

export default function useJobsApi() {
  const { get, post, remove } = useRequest();

  return {
    createJob: async (newJob: IJob) => {
      const { data } = await post<IJob>("/jobs", newJob);
      return data;
    },

    updateJob: async (jobId: string, update: IJob) => {
      const { data } = await post<IJob>(`/jobs/${jobId}`, update);
      return data;
    },

    updateJobSchedule: async (
      jobId: string,
      cronLine: string,
      expiration?: string,
      durationLimit?: number
    ) => {
      const { data } = await post<IJob>(`/jobs/${jobId}/schedule`, {
        cronSchedule: cronLine,
        expiration,
        durationLimit,
      });
      return data;
    },

    fetchJobs: async (options?: { tag_filters?: string[]; page?: number }) => {
      const { data } = await get<IJobsPaginatedList>("/jobs", {
        params: { ...options },
      });
      return data;
    },

    fetchJob: async (jobId: string, options?: { runsPage?: number }) => {
      const { data } = await get<IJob>(`/jobs/${jobId}`, {
        params: { ...options },
      });
      return data;
    },

    fetchJobRunStats: async (jobId: string) => {
      const { data } = await get<IRunStatsResponse>(`/jobs/${jobId}/stats`);
      return toRunStats(data);
    },

    removeJob: (jobId: string) => {
      return remove(`/jobs/${jobId}`);
    },

    addJobParameter: async (
      jobId: string,
      parameter: ITextParameter | IPasswordParameter
    ) => {
      const { data } = await post<IParameter[]>(
        `/jobs/${jobId}/parameters`,
        parameter
      );
      return data;
    },

    removeJobParameter: async (jobId: string, varId: string) => {
      const { data } = await remove<IParameter[]>(
        `/jobs/${jobId}/parameters/${varId}`
      );
      return data;
    },

    setJobAppParameters: async (
      jobId: string,
      parameters: TUpsertParameter[]
    ) => {
      const { data } = await post<IParameter[]>(
        `/jobs/${jobId}/app/parameters`,
        { parameters }
      );
      return data;
    },

    addJobCredential: async (jobId: string, credId: string) => {
      const { data } = await post<ICredential[]>(
        `/jobs/${jobId}/credentials/${credId}`
      );
      return data;
    },

    removeJobCredential: async (jobId: string, credId: string) => {
      const { data } = await remove<ICredential[]>(
        `/jobs/${jobId}/credentials/${credId}`
      );
      return data;
    },

    addJobTag: async (jobId: string, tagId: string) => {
      const { data } = await post<ITag[]>(`/jobs/${jobId}/tags/${tagId}`);
      return data;
    },

    removeJobTag: async (
      jobId: string,
      tagId: string,
      options?: { deleteTag: boolean }
    ) => {
      const { data } = await remove<ITag[]>(`/jobs/${jobId}/tags/${tagId}`, {
        data: { delete_tag: options && options.deleteTag },
      });
      return data;
    },
  };
}
