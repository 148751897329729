import React from "react";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import { space, variant } from "styled-system";

import LinearProgress from "@material-ui/core/LinearProgress";

const colorVariants = variant({
  variants: {
    primary: {
      bg: "primary",
    },
    warning: {
      bg: "warning",
    },
    success: {
      bg: "success",
    },
    danger: {
      bg: "danger",
    },
  },
});

const sizeVariants = variant({
  prop: "size",
  variants: {
    normal: {
      height: 1,
    },
    tall: {
      height: 6,
    },
  },
});

export const TMCDivider = styled(({ variant, size, space, ...rest }) => (
  <Divider variant="fullWidth" {...rest} />
))`
  margin-top: 16px;
  margin-bottom: 16px;
  ${space}
  ${colorVariants}
  ${sizeVariants}
`;

export const TMCLinearProgress = styled(({ size, space, ...rest }) => (
  <LinearProgress {...rest} />
))`
  margin-top: 16px;
  margin-bottom: 16px;
  ${space}
`;
