import React, { useState, useEffect, useContext } from "react";
import { INotificationConfig } from "../../types";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "../common";
import NotificationMessageBody from "./NotificationMessageBody";
import AuthContext from "../../contexts/authContext";
import { ResourceContext } from "../../contexts/resourceContext";

// TODO: figure out how to localize these
const jobMessageBody = `Job run {{job_run_id}} for job {{job_id}} completed with the "{{job_run_state}}" state in {{job_run_duration}}

For more information, see {{job_run_url}}`;

const jobMessageSubject = 'Job "{{job_name}}": {{job_run_state}}';

const workflowMessageBody = `Workflow run {{workflow_run_id}} for workflow {{workflow_id}} completed with the "{{workflow_run_state}}" state in {{workflow_run_duration}}

For more information, see {{workflow_run_url}}
`;
const workflowMessageSubject =
  'Workflow "{{workflow_name}}": {{workflow_run_state}}';

export default function NotificationConfig({
  config,
  setConfig,
}: {
  config: INotificationConfig | null;
  setConfig: (criteria: INotificationConfig) => void;
}) {
  const { t } = useTranslation("Notification Form");
  // Grab the default email for our recipients list from the currently logged in user
  const authContext = useContext(AuthContext);
  const defaultEmail = authContext.user ? authContext.user.email : "";
  const [recipients, setRecipients] = useState(
    config ? config.recipients.join(",") : defaultEmail
  );
  const { resourceType } = useContext(ResourceContext);
  const defaultMessageBody =
    resourceType === "workflow" ? workflowMessageBody : jobMessageBody;
  const [messageBody, setMessageBody] = useState(
    config && config.messageBody ? config.messageBody : defaultMessageBody
  );
  const defaultMessageSubject =
    resourceType === "workflow" ? workflowMessageSubject : jobMessageSubject;
  const [messageSubject, setMessageSubject] = useState(
    config?.subject ? config.subject : defaultMessageSubject
  );
  // Whenever recipients, messageBody, or messageSubject change, we need
  // to call setConfig to update the config at the parent level.
  useEffect(
    () =>
      setConfig({
        recipients: recipients.split(","),
        messageBody,
        subject: messageSubject,
      }),
    [setConfig, recipients, messageBody, messageSubject]
  );
  return (
    <>
      <Box mb={3}>
        <TextField
          id="notification-email-recipients"
          placeholder={t("Add a comma separated list of recipients")}
          label={t("Recipients")}
          required
          style={{ width: "80%" }}
          onChange={setRecipients}
          value={recipients}
        />
      </Box>
      <Box mb={3}>
        <TextField
          id="notification-email-recipients"
          placeholder={t("Specify the subject of the message")}
          label={t("Subject")}
          required
          style={{ width: "80%" }}
          onChange={setMessageSubject}
          value={messageSubject}
        />
      </Box>
      <Box mb={3}>
        <NotificationMessageBody
          messageBody={messageBody}
          setMessageBody={setMessageBody}
        />
      </Box>
    </>
  );
}
