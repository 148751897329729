import { darken } from "polished";

const COLORS = {
  BLUE: "#1a7dd7",
  DARKBLUE: "#03275a",
  TEAL: "#7bd6af",
  RED: "#ff3300",
  ORANGE: "#f37402",
  YELLOW: "#f2b809",
};

export default {
  fonts: {
    main: "Lato, sans-serif",
    title: "Josefin Sans, sans-serif",
  },
  shadows: {
    drop:
      "0 .46875rem 2.1875rem rgba(8,10,37,.03),0 .9375rem 1.40625rem rgba(8,10,37,.03),0 .25rem .53125rem rgba(8,10,37,.05),0 .125rem .1875rem rgba(8,10,37,.03)",
  },
  colors: {
    text: "black",
    link: darken(0.2, COLORS.TEAL),
    navText: COLORS.ORANGE,
    navBackground: COLORS.DARKBLUE,
    background: "#f1f4f6",
    primary: COLORS.BLUE,
    warning: COLORS.ORANGE,
    success: COLORS.TEAL,
    danger: COLORS.RED,
    outline: "gray",
    code: "#7ec699",
    codeBackground: "#272822",
  },
};
