import React from "react";
import { useTranslation } from "react-i18next";
import {
  TUpsertParameter,
  IParameterDefinition,
  IUpsertTextParam,
  IUpsertPasswordParam,
} from "../../types";
import ParamDefListItemLabel from "../apps/parameterDefinitions/ParamDefListItemLabel";
import { Col, Divider, Row, TextField } from "../common";

export function AppJobTextParamEditor({
  parameter,
  paramDef,
  onChange,
}: {
  parameter: IUpsertTextParam | IUpsertPasswordParam;
  paramDef: IParameterDefinition;
  onChange: (v: string) => void;
}) {
  const { t } = useTranslation("Job Page");
  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />
        <Col>
          <TextField
            fullWidth
            id={`${paramDef.id}-input`}
            onChange={onChange}
            helperText={
              parameter.type === "password"
                ? t(
                    "Password values are hidden after saving for security purposes."
                  )
                : null
            }
            label={t("Value")}
            defaultValue={parameter.value}
          />
        </Col>
      </Row>
      <Divider mt={1} mb={1} />
    </>
  );
}

export default function ({
  parameter,
  paramDef,
}: {
  parameter: TUpsertParameter;
  paramDef: IParameterDefinition;
}) {
  const renderValue = () => {
    switch (parameter.type) {
      case "text":
        return parameter.value;
      case "password":
        return "● ● ● ● ●";
      case "credential":
        return parameter.credential?.name;
      case "db_query":
        return parameter.queryString;
      case "json":
        return parameter.value;
      case "option":
        return parameter.value;
      default:
        return null;
    }
  };
  return (
    <>
      <Row>
        <ParamDefListItemLabel paramDef={paramDef} />
        <Col truncate>{renderValue()}</Col>
      </Row>
      <Divider mt={1} mb={1} />
    </>
  );
}
