import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, SaveButton, CancelButton, Checkbox } from "../common";

import useAlert from "../../hooks/useAlert";
import { IUser } from "../../types";
import useApi from "../../api/userApi";

interface IProps {
  userData: IUser;
}

export default function UserTableRow({ userData }: IProps) {
  const [user, setUser] = useState<IUser | null>(userData);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const { updateUser, removeUser } = useApi();
  const { showError } = useAlert();
  const { t } = useTranslation();

  const updateField = (fieldName: keyof IUser, value: string | boolean) => {
    setCurrentUser({
      ...(currentUser as IUser),
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (currentUser === null || user === null) return;
    updateUser(user.id, currentUser)
      .then((data) => {
        setCurrentUser(null);
        setUser(data);
      })
      .catch((error) => showError(error.message));
  };

  const handleDelete = () => {
    if (user) {
      removeUser(user.id)
        .then(() => setUser(null))
        .catch((error) => showError(error.message));
    }
  };

  if (user === null) return null;

  return currentUser !== null && user.id === currentUser.id ? (
    <tr>
      <td className="form-group">
        <input
          type="text"
          className="form-control"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            updateField("name", evt.target.value)
          }
          value={currentUser.name}
        />
      </td>
      <td>
        <input
          type="checkbox"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            updateField("isAdmin", evt.target.checked)
          }
          checked={currentUser.isAdmin}
        />
      </td>
      <td>
        <input
          type="email"
          className="form-control"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            updateField("email", evt.target.value)
          }
          value={currentUser.email}
        />
      </td>
      <td>{currentUser.uid}</td>
      <td>
        <div>
          <SaveButton onClick={handleSave} />
          <CancelButton onClick={() => setCurrentUser(null)} />
          <Button onClick={handleDelete} variant="danger">
            Delete
          </Button>
        </div>
      </td>
    </tr>
  ) : (
    <tr onClick={() => setCurrentUser(user)}>
      <td>{user.name}</td>
      <td>
        <Checkbox disabled checked={user.isAdmin} />
      </td>
      <td>{user.email}</td>
      <td>
        <Button variant="primary" onClick={() => setCurrentUser(user)}>
          {t("common:edit")}
        </Button>
      </td>
    </tr>
  );
}
