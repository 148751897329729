import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Button, Link } from "../common";
import styled from "styled-components";

import { IUser } from "../../types";

interface IProps {
  logoutUser: () => void;
  user: IUser;
}

const NavLink = styled(Link as any)`
  &:hover {
    text-decoration: none;
  }
`;
NavLink.defaultProps = {
  color: "navLink",
};

export default function UserMenu({ logoutUser, user }: IProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation("UserMenu");

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Button
        variant="navLink"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
      >
        {t("hello", { name: user.name })}
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 0,
          horizontal: 150,
        }}
        open={open}
        onClose={handleClose}
      >
        {user.isAdmin && (
          <div>
            <MenuItem onClick={handleClose}>
              <NavLink color="navText" to="/app/users">
                {t("Manage users")}
              </NavLink>
            </MenuItem>
            <hr />
          </div>
        )}
        <MenuItem onClick={handleClose}>
          <Button onClick={logoutUser} variant="navLink">
            {t("Logout")}
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
