import React from "react";

import AppRouter from "./AppRouter";
import LoginPage from "./login/LoginPage";
import LoadingScreen from "./common/LoadingScreen";
import useAuth, { logout } from "../hooks/useAuth";
import AuthContext from "../contexts/authContext";

export default function AuthContainer() {
  const { authState, authActions } = useAuth();
  const {
    parsonsUiUser,
    firebaseUser,
    isLoading,
    token,
    expirationTime,
    claims,
  } = authState;

  if (isLoading) return <LoadingScreen />;

  if (!parsonsUiUser)
    return <LoginPage firebaseUser={firebaseUser} logoutUser={logout} />;

  return (
    <AuthContext.Provider
      value={{
        user: parsonsUiUser,
        token,
        expirationTime,
        orgId: claims.org_id,
        updateToken: authActions.updateToken,
      }}
    >
      <AppRouter logoutUser={logout} user={parsonsUiUser} />
    </AuthContext.Provider>
  );
}
