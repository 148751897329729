import React from "react";
import styled from "styled-components";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { typography, TypographyProps } from "styled-system";

const StyledInput = styled(OutlinedInput)`
  font-family: ${({ theme }) => theme.fonts.main};
`;

interface IPropsInput {
  name?: string;
  type: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export function TMCInput({
  name,
  type,
  label,
  placeholder,
  required,
  onChange,
  value,
}: IPropsInput) {
  return (
    <StyledInput
      type={type}
      name={name}
      value={value}
      label={label}
      fullWidth
      placeholder={placeholder}
      required={required}
      onChange={onChange}
    />
  );
}

interface IFormProps {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  onSubmit: () => void;
}
export default function TMCForm({ children, className, onSubmit }: IFormProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

// TODO deprecate use of FormControl, Input, InputLabel, etc in favor of TextField
export const StyledTextField = styled(TextField)<TextFieldProps>`
  .MuiInputBase-root {
    ${typography}
  }

  .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.main};
  }

  .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  input {
    font-family: ${({ theme }) => theme.fonts.main};
  }
`;

interface TFProps
  extends Omit<TextFieldProps, "onChange" | "variant">,
    TypographyProps {
  id: string;
  onChange?: (v: string) => void;
  label: string;
}

export function TMCTextField({ id, onChange, label, ...rest }: TFProps) {
  return (
    <StyledTextField
      id={id}
      size="small"
      variant="outlined"
      label={label}
      {...rest}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(event.target.value);
      }}
    />
  );
}

export function TMCTextArea({ ...rest }: TFProps) {
  return <TMCTextField multiline {...rest} />;
}
