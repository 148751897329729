import React from "react";
import styled from "styled-components";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import { ClearButton } from "./Button";

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;
const StyledDrawer = styled(Drawer)`
  font-family: ${({ theme }) => theme.fonts.main};

  .MuiDrawer-paper {
    padding: 16px 32px;
  }
`;

interface IProps extends DrawerProps {
  onClose?: () => void;
  showCloseButton?: boolean;
}

export default function TMCDrawer({
  onClose,
  showCloseButton,
  children,
  ...rest
}: IProps) {
  return (
    <StyledDrawer onClose={onClose} {...rest}>
      {showCloseButton && (
        <CloseButtonContainer>
          <ClearButton onClick={onClose} />
        </CloseButtonContainer>
      )}
      {children}
    </StyledDrawer>
  );
}

export const PinnedSidebar = styled(Drawer)`
  flex-shrink: 0;
  width: 23rem;
  .MuiDrawer-paper {
    width: 23rem;
    padding: 16px 32px;
    top: 64px;
  }
`;
PinnedSidebar.defaultProps = {
  variant: "permanent",
};
