import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../common/Modal";
import { ICredentialTemplate, TUpsertCredParameter } from "../../types";
import { CreateCredentialProps } from "../../api/credentialsApi";
import NewCredentialDetailsForm from "./NewCredentialDetailsForm";
import NewCredentialTemplateForm from "./NewCredentialTemplateForm";

const defaultCredential = {
  name: "",
  description: "",
  shared: false,
};

const defaultStep = "details";

export default function NewCredentialTemplateModal({
  selectedTemplate,
  show,
  onHide,
  onSave,
}: {
  selectedTemplate: ICredentialTemplate;
  show: boolean;
  onHide: () => void;
  onSave: (v: CreateCredentialProps) => void;
}) {
  const [newCredential, setNewCredential] = useState<CreateCredentialProps>(
    defaultCredential
  );
  const [step, setStep] = useState(defaultStep);
  const { t } = useTranslation("Credentials Page");

  useEffect(() => {
    if (show) {
      setNewCredential(defaultCredential);
      setStep(defaultStep);
    }
  }, [show]);

  const handleSteps = () => {
    switch (step) {
      case "details":
        return (
          <NewCredentialDetailsForm
            newCredential={newCredential}
            onHide={onHide}
            onSave={(name: string, description: string, shared: boolean) => {
              setNewCredential({
                ...newCredential,
                name,
                description,
                shared,
              });
              setStep("template");
            }}
          />
        );
      case "template":
        return (
          <NewCredentialTemplateForm
            template={selectedTemplate}
            stepBack={() => setStep("details")}
            stepBackLabel={t("Back to details")}
            onHide={onHide}
            onFinish={async (parameters: TUpsertCredParameter[]) => {
              await onSave({
                ...newCredential,
                templateId: selectedTemplate.id,
                parameters,
              });
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      {handleSteps()}
    </Modal>
  );
}
