import useRequest from "../hooks/useRequest";
import { IWorkflow, IWorkflowsPaginatedList } from "../types";

interface StepProps {
  prev: string | null;
  statusIn: number[];
  next: string;
}

export default function useWorkflowApi() {
  const { get, post, remove } = useRequest();

  return {
    createWorkflow: async (name: string, steps: StepProps[]) => {
      const { data } = await post<IWorkflow>(`/workflows`, { name, steps });
      return data;
    },

    updateWorkflow: async (workflow: IWorkflow) => {
      const { data } = await post<IWorkflow>(
        `/workflows/${workflow.id}`,
        workflow
      );
      return data;
    },

    updateWorkflowSchedule: async (
      id: string,
      cronLine: string,
      expiration?: string
    ) => {
      const { data } = await post<IWorkflow>(`/workflows/${id}/schedule`, {
        cronSchedule: cronLine,
        expiration,
      });
      return data;
    },

    fetchWorkflows: async (options?: {
      tag_filters?: string[];
      page?: number;
    }) => {
      const { data } = await get<IWorkflowsPaginatedList>("/workflows", {
        params: { ...options },
      });
      return data;
    },

    fetchWorkflow: async (id: string, options?: { runsPage?: number }) => {
      const { data } = await get<IWorkflow>(`/workflows/${id}`, {
        params: { ...options },
      });
      return data;
    },

    removeWorkflow: async (id: string) => {
      const { data } = await remove<{ id: string }>(`/workflows/${id}`);
      return data;
    },

    removeWorkflowNode: async (workflowId: string, nodeId: string) => {
      const { data } = await remove<{ nodeId: string }>(
        `/workflows/${workflowId}/nodes/${nodeId}`
      );
      return data;
    },

    addWorkflowNode: async (workflowId: string, nodeData: StepProps) => {
      const { data } = await post<{ nodeId: string }>(
        `/workflows/${workflowId}/nodes`,
        nodeData
      );
      return data;
    },

    addWorkflowTag: async (workflowId: string, tagId: string) => {
      const { data } = await post<IWorkflow>(
        `/workflows/${workflowId}/tags/${tagId}`
      );
      return data;
    },

    removeWorkflowTag: async (
      workflowId: string,
      tagId: string,
      options?: { deleteTag: boolean }
    ) => {
      const { data } = await remove<IWorkflow>(
        `/workflows/${workflowId}/tags/${tagId}`,
        {
          data: { delete_tag: options?.deleteTag },
        }
      );
      return data;
    },
  };
}
